import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../styles/definedStyle';

const StyleMenuDashboard = styled.div`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  ul {
    width: 203px !important;
    ${tw`bg-transparent border-none`}
    li {
      ${tw`flex items-center text-white my-0! font-Poppins-Semi-Bold hover:text-white bg-orange`}
      font-size: 16px;
      line-height: 25px;
      height: 62px !important;
      border-radius: 15px;

      &:not(:first-of-type) {
        margin-top: 10px !important;
      }
      &.ant-menu-item-selected {
        ${tw`text-blueDark hover:text-blueDark bg-white!`};
        &::after {
          ${tw`hidden`}
        }
      }
      .btn-logout {
        background: transparent;
        border: none;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          border: none !important;
          background: transparent !important;
          outline: none;
        }
      }
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-left: 11px;
        ${tw`bg-white`}
      }
    }
  }
`;

const StyleDashBoard = styled.div`
  ${tw`h-full flex flex-col`}
  .user-info {
    ${tw`flex flex-col text-white items-center font-Poppins-Semi-Bold`}
    font-size: 14px;
    .username {
      ${tw`text-orange`}
    }
  }
  .layout {
    ${tw`flex-auto overflow-auto`}
    padding: 80px 0px;
    .content {
      width: 750px;
      z-index: 10;
      ${tw`relative mx-auto`}
      border-radius: 15px;
      z-index: 200;
      width: 750px;
      padding: 40px 38px 39px 42px;
      background-color: white;
      .page-layout {
      }
    }
  }
  .menu-dashboard {
    position: fixed;
    top: 205px;
    left: calc((100% - 750px) / 2 - 182px);
    z-index: 1;
  }
  @media only screen and (max-width: 1200px) {
    .menu-dashboard {
      z-index: 300;
      top: 0;
      left: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    .menu-dashboard {
      top: 20px;
      left: 0;
    }
    .layout {
      .content {
        width: 100%;
        border-radius: 0;
        padding: 20px;
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .layout {
      padding: 0;
    }
  }
`;

const StyleHamburgerDashBoard = styled.div`
  display: none;
  @media only screen and (max-width: 1200px) {
    display: grid;
    .ant-dropdown-trigger {
      position: fixed;
      width: 50px;
      height: 50px;
      padding: 10px;
      font-size: 18px;
      top: 200px;
      right: 10px;
      background: ${colors.blueDark};
      color: white;
      border: none;
      svg {
        position: relative;
        top: 3px;
      }
    }
  }

  ul {
    width: 203px !important;
    ${tw`bg-transparent border-none`}
    li {
      ${tw`flex items-center text-white my-0! font-Poppins-Semi-Bold hover:text-white bg-blueDark`}
      font-size: 14px;
      line-height: 25px;
      height: 40px !important;
      &.ant-menu-item-selected {
        border: 1px solid ${colors.blueDark};
        ${tw`text-blueDark hover:text-blueDark bg-white!`};
        &::after {
          ${tw`hidden`}
        }
      }
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-left: 11px;
        ${tw`bg-white`}
      }
      @media only screen and (min-width: 1201px) {
        &.btn-return,
        &.btn-logout {
          display: none;
        }
      }
    }
  }
`;
export { StyleMenuDashboard, StyleDashBoard, StyleHamburgerDashBoard };
