import { api } from '@goldfishcode/modern-groom-api-sdk';
import { RouteComponentProps, navigate } from '@reach/router';
import { Empty, message } from 'antd';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusSuit } from '../../../enum';
import { RootState } from '../../../reducers';
import { setSuitDetail } from '../../../reducers/suit/action';
import { AppContext } from '../../contexts/AppContext';
import HeaderContainer from '../../ui/HeaderContainer';
import PayYourSuit from './Payment';
import { StyledYourSuit } from './Styles';
import YourSuitInformation from './SuitRental';

const YourSuit: FC<RouteComponentProps> = () => {
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const disableAction = !activeParty?.active;
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();

  const subTitle = useMemo(() => {
    if (suitDetail && (suitDetail.status === StatusSuit.NEED_TO_PAY || StatusSuit.PAYMENT_FAILED || !suitDetail.status)) {
      return `Below is your suit package breakdown. Please complete your payment by clicking the Complete Payment icon below.
      You will be notified once your suit package has been shipped.`;
    }
    if (suitDetail?.shipped_date) {
      return `Your selected suit has been shipped to you.
      Please upload photos of yourself wearing the suit for quality control and
      check this page for the status of the suits in your party.`;
    }
    return `Your selected suit has been paid. We are processing your order!
    Once you receive your suit, please try it on and look in the package for instructions on next steps!
    `;
  }, [suitDetail]);

  const getSuitForMe = async () => {
    appContext.setLoading(true);
    try {
      if (activeParty) {
        const result = await api.Suit.mySuit();
        dispatch(setSuitDetail(result));
      }
    } catch (error) {
      message.error((error as Error).message);
    }
    appContext.setLoading(false);
  };

  useEffect(() => {
    if (!suitDetail) return;
    if (!suitDetail.suit_name) {
      navigate('/');
    }
  }, [suitDetail]);

  return (
    <StyledYourSuit>
      {suitDetail && suitDetail.suit_name ? (
        <>
          <HeaderContainer title="Your Suit" subTitle={subTitle} />
          {suitDetail &&
          (!suitDetail.status || suitDetail.status === StatusSuit.NEED_TO_PAY || suitDetail.status === StatusSuit.PAYMENT_FAILED) ? (
            <PayYourSuit disableAction={disableAction} />
          ) : (
            <YourSuitInformation getSuitForMe={getSuitForMe} disableAction={disableAction} />
          )}
        </>
      ) : (
        <div className="no-data">
          <Empty description="Suit has not been selected yet." />
        </div>
      )}
    </StyledYourSuit>
  );
};

export default YourSuit;
