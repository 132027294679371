import { api } from '@goldfishcode/modern-groom-api-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, message } from 'antd';
import { isObject } from 'lodash';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { navigate } from '@reach/router';
import { RootState } from '../../../../reducers';
import { setUser } from '../../../../reducers/user/action';
import { AppContext } from '../../../contexts/AppContext';
import BaseInput from '../../../ui/Input';
import { StyleButtonOrange } from '../../../ui/Styled';
import { StyleGroomsmanInformation } from './StyleGroomsmanInfo';
import { formatPhone } from '../../../../util';
import { RegPhoneNumber } from '../../../../constants';

interface UserInfoStep1Props {
  className?: string;
  actionName?: string;
  onChangeSuccess?(): void;
  disableChange?: boolean;
}
interface ValidateProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}
const schema = yup.object().shape({
  firstName: yup
    .string()
    .strict(false)
    .trim()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.')
    .test('len', 'This field must be less than 30 characters', (v) => !!(v && v.length <= 30)),
  lastName: yup
    .string()
    .strict(false)
    .trim()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.')
    .test('len', 'This field must be less than 30 characters', (v) => !!(v && v.length <= 30)),
  phoneNumber: yup
    .string()
    .strict(false)
    .trim()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.')
    .test('phone', 'Your phone is invalid', (v) => !!v && RegPhoneNumber.test(v)),
});
const GroomsmanInformation: React.FC<UserInfoStep1Props> = ({ actionName = 'Continue', onChangeSuccess, className, disableChange }) => {
  const appContext = useContext(AppContext);
  const userProfile = useSelector((state: RootState) => state.userReducer.userProfile);
  const dispatch = useDispatch();
  const { control, handleSubmit, formState, trigger } = useForm<ValidateProps>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const onSubmit = handleSubmit(async (data) => {
    try {
      appContext.setLoading(true);
      const params = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone: data.phoneNumber.replace(/([^0-9])/g, ''),
      };
      const userInfo = await api.User.updateProfile(params);
      if (userInfo) {
        dispatch(setUser(userInfo));
        if (typeof onChangeSuccess === 'function') {
          onChangeSuccess();
        } else {
          navigate('/wedding-party/register/step2');
        }
      }
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setLoading(false);
    }
  });
  return (
    <StyleGroomsmanInformation className={className}>
      <section className="info">
        <Form layout="vertical">
          <div className="info-row your-name">
            <p className="row-title">Your Name</p>
            <div className="first-name">
              <Form.Item validateStatus={formState.errors.firstName ? 'error' : ''} help={formState.errors.firstName?.message}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={(isObject(userProfile) && userProfile?.first_name) || null}
                  render={({ onChange, value }) => (
                    <BaseInput
                      onChange={(e) => onChange(e.target.value.trim())}
                      value={value}
                      placeholder="First Name"
                      disabled={disableChange}
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="last-name">
              <Form.Item
                className="enter"
                validateStatus={formState.errors.lastName ? 'error' : ''}
                help={formState.errors.lastName?.message}
              >
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={(isObject(userProfile) && userProfile?.last_name) || null}
                  render={({ onChange, value }) => (
                    <BaseInput
                      onChange={(e) => onChange(e.target.value.trim())}
                      value={value}
                      placeholder="Last Name"
                      disabled={disableChange}
                    />
                  )}
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <div className="info-row your-phone">
              <p className="row-title">Your Phone Number</p>
              <div className="first-name">
                <Form.Item validateStatus={formState.errors.phoneNumber ? 'error' : ''} help={formState.errors.phoneNumber?.message}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue={(isObject(userProfile) && formatPhone(userProfile?.phone)) || null}
                    render={({ onChange, value }) => (
                      <BaseInput
                        onChange={(e) => {
                          const formattedPhone = formatPhone(e.target.value);
                          if (formattedPhone !== null) {
                            onChange(formattedPhone);
                            trigger('phoneNumber');
                          }
                        }}
                        onBlur={() => {
                          trigger('phoneNumber');
                        }}
                        value={value}
                        placeholder="Phone Number"
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </section>
      <section className="action">
        <StyleButtonOrange width={135} onClick={onSubmit} disabled={disableChange}>
          {actionName || 'Continue'}
        </StyleButtonOrange>
      </section>
    </StyleGroomsmanInformation>
  );
};

export default GroomsmanInformation;
