export const colors = {
  white: '#ffffff',
  orange: '#CA6D4F',
  blueDark: '#304251',
  darkLight: '#222222',
  black: '#304251',
  greyback: '#8E9C9C',
  gray: '#959595',
  error: 'red',
};

export const fontFamily = {
  PoppinsSemiBold: 'Poppins Semi Bold',
  SourceSansProSemiBold: 'Source Sans Pro Semi Bold',
  SourceSansProRegular: 'Source Sans Pro Regular',
  SourceSansProLight: 'Source Sans Pro Light',
  SourceSansProItalic: 'Source Sans Pro Italic',
  SourceSansProBold: 'Source Sans Pro Bold',
  PoppinsRegular: 'Poppins Regular',
};

export const shadow = {
  default: '0 3px 6px rgba(0, 0, 0, 0.16)',
};
