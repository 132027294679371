import { RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { KeyLocalStorage } from 'enum';
import HeaderContainer from '../../ui/HeaderContainer';
import GroomsmanDetail from '../../wedding-party/register-groomsman/step2/GroomsmanDetail';
import ChangeEmailForm from '../change-email/Form';
import UpdateUserInfoDetail from '../groom/step-2/Step2';
import ProfileUpdated from '../modal/ProfileUpdated';
import { StyleYourProfileGroomsman } from './StyleYourProfileGroomsman';
import GroomsmanInformation from '../../wedding-party/register-groomsman/step1/GroomsmanInformation';
import { RootState } from '../../../reducers';

const YourProfileGroomsman: FC<RouteComponentProps> = () => {
  const [showModal, setShowModal] = useState(false);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const listSkip: Array<string> = JSON.parse(localStorage.getItem(KeyLocalStorage.Skip) || '[]');
  const [isSkipped, setIsSkipped] = useState(activeParty?.id ? listSkip.includes(activeParty?.id) : false);

  const onChangeSuccess = (isChangeMeasurement: boolean) => {
    if (isSkipped && isChangeMeasurement) {
      setIsSkipped(false);
      localStorage.removeItem(KeyLocalStorage.Skip);
    }
    setShowModal(true);
  };

  return (
    <StyleYourProfileGroomsman activeParty={activeParty}>
      <HeaderContainer
        title="Your Profile"
        subTitle="Please take your time when creating your profile. Should changes need to be made after submission, please reach out to us by using the Contact Us icon below."
      />
      <ChangeEmailForm disableChange={!activeParty?.active} />
      <div className="wedding-info">
        <GroomsmanDetail />
      </div>
      <div className="your-info">
        <GroomsmanInformation
          onChangeSuccess={() => onChangeSuccess(false)}
          actionName="Save Changes"
          disableChange={!activeParty?.active}
        />
      </div>
      {!activeParty?.opt_out_measurement && (
        <div className="user-step-3">
          <UpdateUserInfoDetail onChangeSuccess={() => onChangeSuccess(true)} disableChange={!isSkipped} isSkipped={isSkipped} />
        </div>
      )}

      <ProfileUpdated
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </StyleYourProfileGroomsman>
  );
};

export default YourProfileGroomsman;
