import { RadioGroupProps } from 'antd/lib/radio';
import React, { FC } from 'react';
import { StyledRadio } from './Styled';

interface Props extends RadioGroupProps {
  vertical?: boolean;
}
const BaseRadio: FC<Props> = (props) => {
  const { vertical = false } = props;
  return <StyledRadio {...props} vertical={vertical} />;
};

export default BaseRadio;
