import moment from 'moment';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { StyleGroomsmanDetail } from '../StyleRegisterGroomsman';

const GroomsmanDetail: FC = () => {
  const userProfile = useSelector((state: RootState) => state.userReducer.userProfile);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);

  const partyName = `${activeParty?.party_name}`;
  const profileName = `${userProfile?.first_name} ${userProfile?.last_name}`;
  return (
    <StyleGroomsmanDetail>
      <section className="info">
        <div className="item">
          <h3 className="head">Wedding Party Name</h3>
          <span className="text">{partyName.trim() !== '' ? partyName : ' None '}</span>
        </div>
        <div className="item">
          <h3 className="head">Your Name</h3>
          <span className="text">{profileName.trim() !== '' ? profileName : 'None'}</span>
        </div>
        <div className="item">
          <h3 className="head">Wedding Date</h3>
          <span className="text">{(activeParty?.wedding_date && moment(activeParty.wedding_date).format('MMM DD, yyyy')) || 'None'}</span>
        </div>
      </section>
    </StyleGroomsmanDetail>
  );
};

export default GroomsmanDetail;
