import React, { FC } from 'react';
import { Button } from 'antd';
import { StyleResetPasswordStep, StyleFooterModal } from './StyleResetPassword';

interface ConfirmEmailProps {
  emailUser: string;
  handleCancel: () => void;
}
const ConfirmEmail: FC<ConfirmEmailProps> = (props) => {
  const { emailUser, handleCancel } = props;
  return (
    <>
      <StyleResetPasswordStep>
        <p className="reset">Reset Password</p>
        <p className="title">A link to reset your password was sent to:</p>
        <span>{emailUser}</span>
      </StyleResetPasswordStep>
      <StyleFooterModal className="footer" step={2}>
        <Button key="submit" type="primary" onClick={() => handleCancel()}>
          Okay
        </Button>
      </StyleFooterModal>
    </>
  );
};

export default ConfirmEmail;
