import { RouteComponentProps, Router } from '@reach/router';
import { Steps } from 'antd';
import React, { FC, useState } from 'react';
import Header from '../../Header';
import DotStep from '../../ui/DotStep';
import RegisterGroomStep1 from './step1/RegisterGroomStep1';
import RegisterGroomStep2 from './step2/RegisterGroomStep2';
import RegisterGroomStep3 from './step3/RegisterGroomStep3';
import { StyleRegisterGroom } from './StyleRegisterGroom';

const RegisterGroom: FC<RouteComponentProps> = () => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <StyleRegisterGroom>
      <Header className="register">
        <div className="header-step">
          <span className="text">Your Progress</span>
          <Steps className="steps" direction="horizontal" responsive={false}>
            <Steps.Step title="&ensp;" icon={<DotStep active={activeStep === 0} />} />
            <Steps.Step title="&ensp;" icon={<DotStep active={activeStep === 1} />} />
            <Steps.Step title="&ensp;" icon={<DotStep active={activeStep === 2} />} />
          </Steps>
        </div>
      </Header>
      <div className="container">
        <Router basepath="/wedding-party/register">
          <RegisterGroomStep1 activeStep={setActiveStep} path="/step1" />
          <RegisterGroomStep2 activeStep={setActiveStep} path="/step2" />
          <RegisterGroomStep3 activeStep={setActiveStep} path="/step3" />
        </Router>
      </div>
    </StyleRegisterGroom>
  );
};

export default RegisterGroom;
