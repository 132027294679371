import { api } from '@goldfishcode/modern-groom-api-sdk';
import { updateSuitParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit';
import { IPhysicals } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { message } from 'antd';
import { KeyLocalStorage } from 'enum';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setSuitDetail } from 'reducers/suit/action';
import iconShirt from '../../../../assets/images/iconShirt.png';
import BackgroundImageCreateWeddingSteps3 from '../../../../assets/images/wedding-party-register-step-2-background.jpg';
import { definedGroomsmanRouter } from '../../../../constants';
import { uploadMultiImage } from '../../../../controllers/file';
import { RootState } from '../../../../reducers';
import { setActiveParty, setUser } from '../../../../reducers/user/action';
import { AppContext } from '../../../contexts/AppContext';
import { StyleWeddingPartyRegisterStep3 } from '../StyleRegisterGroomsman';
import MeasuringForm, { DataSubmit } from './MeasuringForm';

interface WeddingPartyRegisterStep3Props extends RouteComponentProps {
  onBack(): void;
  activeStep(step: number): void;
}

const RegisterGroomsmanStep3: FC<WeddingPartyRegisterStep3Props> = ({ activeStep }) => {
  const appContext = useContext(AppContext);
  const [physicalSizes, setPhysicalSizes] = useState<IPhysicals | null>(null);
  const currentActiveParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);

  const disableChange = !currentActiveParty?.active;
  const dispatch = useDispatch();
  const getPhysicalSizes = async () => {
    appContext.setLoading(true);
    try {
      const response = await api.User.getPhysicalSizes();
      setPhysicalSizes(response);
    } catch (error) {
      message.error((error as Error).message);
    }
    appContext.setLoading(false);
  };

  const submitForm = async (dataSubmit: DataSubmit) => {
    const { id, data } = dataSubmit;
    appContext.setLoading(true);
    try {
      const faceImageIsFileType = data.facing_image && typeof data.facing_image === 'object';
      const userSuitImageIsFileType = data.user_suit_image && typeof data.user_suit_image === 'object';
      const files: File[] = [
        ...(faceImageIsFileType ? [data.facing_image] : []),
        ...(userSuitImageIsFileType ? [data.user_suit_image] : []),
      ];
      if (files.length > 0) {
        const res = await uploadMultiImage(files);
        if (res && res.length > 0) {
          if (faceImageIsFileType) {
            data.facing_image = res[0].url;
            if (userSuitImageIsFileType) {
              data.facing_image = res[1].url;
            }
          }
          if (userSuitImageIsFileType) {
            data.user_suit_image = res[0].url;
          }
        }
      }
      const suit = await api.Suit.updateUserSuit(id, data as updateSuitParams);
      if (suit) {
        dispatch(setSuitDetail(suit));
      }
      const profile = await api.User.updateProfile({ profile_completed: true });
      if (currentActiveParty) {
        const newPartyInformation = { ...currentActiveParty, profile_completed: true };
        dispatch(setActiveParty(newPartyInformation));
      }
      dispatch(setUser(profile));
      if (suitDetail?.suit_name) {
        navigate(definedGroomsmanRouter.yourSuit);
      } else {
        navigate(definedGroomsmanRouter.yourProfile);
      }
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setLoading(false);
    }
  };

  const onSkip = () => {
    const listSkip: Array<string> = JSON.parse(localStorage.getItem(KeyLocalStorage.Skip) || '[]');
    listSkip.push(currentActiveParty?.id || '');
    localStorage.setItem(KeyLocalStorage.Skip, JSON.stringify(listSkip));
    window.location.reload();
  };

  useEffect(() => {
    appContext.setBackground(BackgroundImageCreateWeddingSteps3);
    activeStep(3);
    getPhysicalSizes();
    if (!currentActiveParty) return;
    if (currentActiveParty.opt_out_measurement) {
      navigate(`/wedding-party/register/step1`);
    }
  }, [currentActiveParty]);

  return (
    physicalSizes && (
      <StyleWeddingPartyRegisterStep3>
        <section className="back">
          <Link to="/wedding-party/register/step2">
            <FaAngleLeft />
            GO BACK
          </Link>
        </section>
        <section className="icon">
          <img src={iconShirt} alt="shirt" />
        </section>
        <h3 className="title">Let’s Get Fitted</h3>
        <p className="help-text">
          Please complete the Sizing Questionnaire below. For further assistance, you can click the questionmark icon next to each
          measurement for helpful "how to measure" videos!
        </p>
        <MeasuringForm
          age={physicalSizes.AGE}
          chest={physicalSizes.CHEST_SIZE}
          shoeSize={physicalSizes.SHOE_SIZE}
          jeanWaistSize={physicalSizes.JEAN_WAIST_SIZE}
          neckSize={physicalSizes.NECK_SIZE}
          sleeve={physicalSizes.SLEEVE_SIZE}
          tShirtSize={physicalSizes.T_SHIRT_SIZE}
          waist={physicalSizes.WAIST}
          height={physicalSizes.HEIGHT}
          inches={physicalSizes.INCHES}
          suitSize={physicalSizes.SUIT_SIZE}
          dressShirtSize={physicalSizes.DRESS_SHIRT_SIZE}
          seatSize={physicalSizes && physicalSizes.SEAT}
          jacketWaistSize={physicalSizes && physicalSizes.JACKET_WAIST}
          onSubmitForm={submitForm}
          disableChange={disableChange}
          isRegisterStep
          onSkip={onSkip}
        />
      </StyleWeddingPartyRegisterStep3>
    )
  );
};

export default RegisterGroomsmanStep3;
