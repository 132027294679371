import { api } from '@goldfishcode/modern-groom-api-sdk';
import { ChangeEmailPasswordParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, message } from 'antd';
import useAuth from 'components/hooks/useAuth';
import { isEmpty } from 'lodash';
import React, { FC, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RegPassword } from '../../../constants';
import { AppContext } from '../../contexts/AppContext';
import BaseInput from '../../ui/Input';
import { StyleButtonOrange } from '../../ui/Styled';
import { StyleChangeEmail } from './StyleChangeEmail';
import BaseInputPassword from '../../ui/InputPassword';

interface ChangeEmailFormPropsType {
  disableChange?: boolean;
}
interface ChangeEmailSchema {
  email?: string;
  confirmEmail?: string;
  password?: string;
  confirmPassword?: string;
}
const changeEmailSchema = yup.object().shape<ChangeEmailSchema>({
  email: yup.string().email('Email is invalid'),
  confirmEmail: yup
    .string()
    .email('Email is invalid')
    .oneOf([yup.ref('email')], 'Email and confirm email do not match'),
  password: yup.string().matches(RegPassword, {
    excludeEmptyString: true,
    message: 'Password minimum eight characters, at least one letter, one number and one special character.',
  }),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Password and confirm password do not match'),
});

const ChangeEmailForm: FC<ChangeEmailFormPropsType> = ({ disableChange }) => {
  const appContext = useContext(AppContext);
  const { forceLogout } = useAuth();
  const { handleSubmit, control, formState, clearErrors, getValues } = useForm<ChangeEmailSchema>({
    resolver: yupResolver(changeEmailSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      confirmEmail: '',
      confirmPassword: '',
      password: '',
    },
  });
  const onFinish = handleSubmit(async (data) => {
    const params: ChangeEmailPasswordParams = {};
    if (data.email) {
      params.email = data.email;
    }
    if (data.password) {
      params.password = data.password;
    }

    if (isEmpty(params)) {
      return;
    }
    appContext.setLoading(true);
    try {
      await api.User.changeEmailPassword(params);
      forceLogout();
    } catch (error) {
      message.error((error as Error).message);
    }
    appContext.setLoading(false);
  });

  return (
    <StyleChangeEmail>
      <Form layout="vertical" onFinish={onFinish}>
        <div className="form-email">
          <div className="email">
            <Form.Item
              label={
                <p>
                  Change Email Address <span className="asterisk">*</span>
                </p>
              }
              validateStatus={formState.errors.email ? 'error' : ''}
              help={formState.errors.email?.message}
            >
              <Controller
                name="email"
                control={control}
                render={({ value, onChange }) => (
                  <BaseInput
                    value={value}
                    onChange={(v) => {
                      onChange(v.target.value.trim());
                      if (!v.target.value && !getValues('confirmEmail')) {
                        clearErrors('confirmEmail');
                      }
                    }}
                    placeholder="Enter New Email"
                    autoComplete="new-email"
                    disabled={disableChange}
                  />
                )}
              />
            </Form.Item>
          </div>
          <div className="confirm-email">
            <Form.Item validateStatus={formState.errors.confirmEmail ? 'error' : ''} help={formState.errors.confirmEmail?.message}>
              <Controller
                name="confirmEmail"
                control={control}
                render={({ value, onChange }) => (
                  <BaseInput
                    value={value}
                    onChange={(e) => onChange(e.target.value.trim())}
                    placeholder="Confirm New Email"
                    autoComplete="new-email"
                    disabled={disableChange}
                  />
                )}
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-password">
          <div className="password">
            <Form.Item
              label={
                <p>
                  Change Password <span className="asterisk">*</span>
                </p>
              }
              validateStatus={formState.errors.password ? 'error' : ''}
              help={formState.errors.password?.message}
            >
              <Controller
                name="password"
                control={control}
                render={({ value, onChange }) => (
                  <BaseInputPassword
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                      if (!v.target.value && !getValues('confirmPassword')) {
                        clearErrors('confirmPassword');
                      }
                    }}
                    type="password"
                    placeholder="Enter New Password"
                    autoComplete="new-password"
                    disabled={disableChange}
                  />
                )}
              />
            </Form.Item>
          </div>
          <div className="confirm-password">
            <Form.Item validateStatus={formState.errors.confirmPassword ? 'error' : ''} help={formState.errors.confirmPassword?.message}>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ value, onChange }) => (
                  <BaseInputPassword
                    value={value}
                    onChange={onChange}
                    type="password"
                    placeholder="Confirm New Password"
                    autoComplete="new-password"
                    disabled={disableChange}
                  />
                )}
              />
            </Form.Item>
          </div>
        </div>
        <div className="btn-save">
          <StyleButtonOrange width={150} htmlType="submit" disabled={disableChange}>
            Save Changes
          </StyleButtonOrange>
        </div>
      </Form>
    </StyleChangeEmail>
  );
};

export default ChangeEmailForm;
