import React, { FC, useState } from 'react';
import { StyleButtonOrange } from '../ui/Styled';
import Chat from './Chat';
import { StyleNeedHelp } from './StyleNeedHelp';

const NeedHelp: FC = () => {
  const [showChat, setShowChat] = useState(false);

  return (
    <StyleNeedHelp>
      <div>
        <Chat className="chat-layout" isShow={showChat} onHide={() => setShowChat(false)} />
        {showChat ? null : (
          <div className="info">
            <div className="text">Need Help?</div>
            <StyleButtonOrange onClick={() => setShowChat(true)} width={186}>
              Chat with Modern Groom
            </StyleButtonOrange>
          </div>
        )}
      </div>
    </StyleNeedHelp>
  );
};

export default NeedHelp;
