import { RouteComponentProps, Router } from '@reach/router';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { KeyLocalStorage } from 'enum';
import { getParamsUrl } from '../util';
import DashBoardGroomsmanContainer from '../components/dashboard/DashBoardGroomsmanContainer';
import YourSuit from '../components/suit/detail/YourSuit';
import RegisterGroomsman from '../components/wedding-party/register-groomsman/RegisterGroomsman';
import YourProfileGroomsman from '../components/your-profile/groomsman/YourProfileGroomsman';
import { RootState } from '../reducers';
import DefaultRouter from './DefaultRouter';

const RouterGroomsman: FC<RouteComponentProps> = () => {
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const queryParams = getParamsUrl<any>();
  const listSkip: Array<string> = JSON.parse(localStorage.getItem(KeyLocalStorage.Skip) || '[]');
  const isSkipped = activeParty?.id ? listSkip.includes(activeParty?.id) : false;

  return activeParty ? (
    <Router
      basepath="/"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {activeParty.profile_completed || isSkipped ? (
        <>
          <DashBoardGroomsmanContainer path="/groomsman">
            <YourProfileGroomsman path="/your-profile" />
            <YourSuit path="/your-suit" />
          </DashBoardGroomsmanContainer>
          <DefaultRouter default link="/groomsman/your-profile" />
        </>
      ) : (
        <>
          <RegisterGroomsman path="/wedding-party/register/*" />
          <DefaultRouter default link={`/wedding-party/register/step${queryParams?.user_id ? 3 : 1}`} />
        </>
      )}
    </Router>
  ) : null;
};

export default RouterGroomsman;
