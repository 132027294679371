import React, { FC } from 'react';
import { CgCheckO } from 'react-icons/cg';
import { StyleButtonOrange } from '../../ui/Styled';
import { StyleProfileUpdate } from './StyleProfileUpdate';

interface ProfileUpdateModalProps {
  visible: boolean;
  onClose(): void;
}
const ProfileUpdated: FC<ProfileUpdateModalProps> = ({ visible, onClose }) => {
  return (
    <StyleProfileUpdate footer={null} centered visible={visible} closable={false}>
      <CgCheckO color="#0DBF00" fontSize="50px" />
      <div className="title">Profile Updated</div>
      <div className="text">
        Your profile has been updated <br /> successfully.
      </div>
      <div className="action">
        <StyleButtonOrange onClick={onClose} width={135}>
          Done
        </StyleButtonOrange>
      </div>
    </StyleProfileUpdate>
  );
};

export default ProfileUpdated;
