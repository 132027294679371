import React, { FC } from 'react';
import { StyleTitleResetPassword } from './StyleResetPassword';

const ResetPasswordTitle: FC = () => {
  return (
    <StyleTitleResetPassword>
      <span className="reset">Reset Password</span>
      <span className="title">Enter your email below to reset your password:</span>
    </StyleTitleResetPassword>
  );
};

export default ResetPasswordTitle;
