import { RouteComponentProps } from '@reach/router';
import React, { FC, useContext, useEffect } from 'react';
import BackgroundImageHome from '../../assets/images/home-login.jpg';
import { AppContext } from '../contexts/AppContext';
import LoginIndex from './LoginIndex';
import { StyleLoginLayout } from './StyleLogin';

export interface InviteQueryParams {
  otp: string;
  email: string;
  role: string;
  resetPassword?: string;
}

const LayoutLogin: FC<RouteComponentProps> = () => {
  const appContext = useContext(AppContext);
  useEffect(() => {
    appContext.setBackground(BackgroundImageHome);
  }, []);

  return (
    <StyleLoginLayout>
      <LoginIndex />
    </StyleLoginLayout>
  );
};

export default LayoutLogin;
