import { IUser, IWeddingPartyMember } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { ActionBase } from '..';
import { IReadingStatus } from './model';

const BASE_ACTION = '@user';

const SET_USER = `${BASE_ACTION}/SET_USER`;
const SET_ACTIVE_WEDDING = `${BASE_ACTION}/SET_ACTIVE_WEDDING`;
const SET_LIST_PARTY = `${BASE_ACTION}/SET_LIST_PARTY`;
const SET_READING_STATUS = `${BASE_ACTION}/SET_READING_STATUS`;
const setUser = (payload: IUser | null): ActionBase => ({ type: SET_USER, payload });
const setActiveParty = (payload: IWeddingPartyMember): ActionBase => ({ type: SET_ACTIVE_WEDDING, payload });
const setListParty = (payload: Array<IWeddingPartyMember>): ActionBase => ({ type: SET_LIST_PARTY, payload });
const setReadingStatus = (payload: IReadingStatus): ActionBase => ({ type: SET_READING_STATUS, payload });
export { SET_USER, SET_ACTIVE_WEDDING, SET_LIST_PARTY, SET_READING_STATUS, setUser, setActiveParty, setListParty, setReadingStatus };
