import { ActionBase } from '..';
import { SET_AUTH } from './action';
import { AuthState } from './model';

const initialState: AuthState = {
  isAuthenticated: null,
  loadingType: '',
};

const authReducer = (state = initialState, action: ActionBase): AuthState => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default authReducer;
