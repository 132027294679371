import React from 'react';

import styled from '@emotion/styled';
import { Method } from '@goldfishcode/modern-groom-api-sdk/libs/api/payment/models';
// import { AiOutlineCheck } from 'react-icons/ai';
import { colors } from 'styles/definedStyle';
import IcAmex from '../../assets/img/icon/icon-amex.svg';
import IcDiscover from '../../assets/img/icon/icon-discover.svg';
import IcMastercard from '../../assets/img/icon/icon-mastercard.svg';
import IcVisa from '../../assets/img/icon/icon-visa.svg';

interface PropsType {
  method: Method;
  onDeletePaymentMethod: (id: string) => void;
  checked: boolean;
  onSelectMethod: (id: string) => void;
  onSetDefaultMethod: (id: string) => void;
}

const CreditCardStyles = styled.div<Partial<PropsType>>`
  transition: opacity 1s;
  :not(:last-child) {
    margin: 10px 0 15px 0;
  }
  &:hover {
    opacity: 1;
  }
  .method {
    padding: 13px;
    border-radius: 7px;
    letter-spacing: 1px;
    color: ${({ checked }) => (checked ? '#fff' : '#8e8f8e')};
    border: ${({ checked }) => (checked ? `3px solid ${colors.orange}` : '3px solid #E9E9E9')};
    marin: 0 auto;
    transition: all 0.5s;
    background: ${({ checked }) => (checked ? colors.orange : '#f8f8f8')};
    .card-number {
      font-size: 20px;
      letter-spacing: 6.5px;
    }
    .checkbox {
      width: 25px;
      height: 25px;
      display: none;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: white;
      border: 2px solid white;
      background: #73cd73;
      color: white;
      ${({ checked }) => checked && 'display: flex'};
    }
    .content-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      object {
        width: 40px;
        height: 40px;
      }
      p {
        font-size: 17px;
      }

      &.first {
        margin-bottom: 10px;
      }
    }

    &:hover {
      border: 3px solid transparent;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    @media only screen and (max-width: 480px) {
      .card-number {
        letter-spacing: 3.5px;
      }
      .content-row {
        font-size: 14px;
      }
    }
  }
  .method-actions {
    line-height: normal;
    margin-top: 5px;
    display: flex;
    gap: 5px;
    color: #fff;
    .action {
      text-decoration: underline;
      cursor: pointer;
      &.default-method {
        cursor: not-allowed;
      }
    }
  }
`;

const CreditCard = ({ method, onDeletePaymentMethod, checked, onSelectMethod, onSetDefaultMethod }: PropsType) => {
  let branchIcon;
  switch (method.brand.toLocaleLowerCase()) {
    case 'visa':
      branchIcon = (
        <object data={IcVisa} type="image/svg+xml">
          <span>SVG not supported</span>
        </object>
      );
      break;

    case 'mastercard':
      branchIcon = (
        <object data={IcMastercard} type="image/svg+xml">
          <span>SVG not supported</span>
        </object>
      );
      break;

    case 'amex':
      branchIcon = (
        <object data={IcAmex} type="image/svg+xml">
          <span>SVG not supported</span>
        </object>
      );
      break;
    case 'discover':
      branchIcon = (
        <object data={IcDiscover} type="image/svg+xml">
          <span>SVG not supported</span>
        </object>
      );
      break;
    default:
      branchIcon = <p style={{ textTransform: 'capitalize' }}>{method.brand}</p>;
  }
  return (
    <CreditCardStyles checked={checked}>
      <div className="method-wrapper">
        <div className={`method ${method.brand.toLowerCase()}`} onClick={() => onSelectMethod(method.id)}>
          <div className="content-row first">
            <p className="card-number">
              {method.brand.toLocaleLowerCase() === 'amex' ? `*** ****** *${method.last4}` : `**** **** **** ${method.last4}`}
            </p>
            {/* <div className="checkbox">
              <AiOutlineCheck />
            </div> */}
          </div>
          <div className="content-row second">
            <p>{`${method.exp_month.toString().length === 1 ? `0${method.exp_month}` : method.exp_month}/${method.exp_year}`}</p>
            {branchIcon}
          </div>
        </div>
        <div className="method-actions">
          {!method.default ? (
            <>
              <p
                className="action"
                onClick={() => {
                  onDeletePaymentMethod(method.id);
                }}
              >
                Delete
              </p>
              |
              <p
                className="action"
                onClick={() => {
                  onSetDefaultMethod(method.id);
                }}
              >
                Mark Default
              </p>
            </>
          ) : (
            <p className="action default-method">Default</p>
          )}
        </div>
      </div>
    </CreditCardStyles>
  );
};

export default CreditCard;
