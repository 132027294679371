import { api } from '@goldfishcode/modern-groom-api-sdk';
import { updateSuitParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit';
import { IPhysicals } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import { message } from 'antd';
import { KeyLocalStorage } from 'enum';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setSuitDetail } from 'reducers/suit/action';
import iconShirt from '../../../../assets/images/iconShirt.png';
import BackgroundImageCreateWeddingSteps2 from '../../../../assets/images/wedding-party-register-step-2-background.jpg';
import { uploadMultiImage } from '../../../../controllers/file';
import { RootState } from '../../../../reducers';
import { setUser } from '../../../../reducers/user/action';
import { AppContext } from '../../../contexts/AppContext';
import MeasuringForm, { DataSubmit } from '../../register-groomsman/step3/MeasuringForm';
import { StyleRegisterGroomStep2 } from '../StyleRegisterGroom';

interface WeddingPartyRegisterStep2Props extends RouteComponentProps {
  activeStep(step: number): void;
}

const WeddingPartyRegisterStep2: FC<WeddingPartyRegisterStep2Props> = ({ activeStep }) => {
  const appContext = useContext(AppContext);
  const [physicalSizes, setPhysicalSizes] = useState<IPhysicals | null>(null);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);

  const dispatch = useDispatch();

  const getPhysicalSizes = async () => {
    appContext.setLoading(true);
    try {
      const response = await api.User.getPhysicalSizes();
      setPhysicalSizes(response);
    } catch (error) {
      message.error((error as Error).message);
    }
    appContext.setLoading(false);
  };

  const submitForm = async (dataSubmit: DataSubmit) => {
    const { data, id } = dataSubmit;
    appContext.setLoading(true);
    try {
      const faceImageIsFileType = data.facing_image && typeof data.facing_image === 'object';
      const userSuitImageIsFileType = data.user_suit_image && typeof data.user_suit_image === 'object';
      const files: File[] = [
        ...(faceImageIsFileType ? [data.facing_image] : []),
        ...(userSuitImageIsFileType ? [data.user_suit_image] : []),
      ];
      if (files.length > 0) {
        const res = await uploadMultiImage(files);
        if (res && res.length > 0) {
          if (faceImageIsFileType) {
            data.facing_image = res[0].url;
            if (userSuitImageIsFileType) {
              data.facing_image = res[1].url;
            }
          }
          if (userSuitImageIsFileType) {
            data.user_suit_image = res[0].url;
          }
        }
      }
      const suit = await api.Suit.updateUserSuit(id, data as updateSuitParams);
      if (suit) {
        dispatch(setSuitDetail(suit));
      }
      await api.User.updateProfile({ profile_completed: true });
      const userInfo = await api.User.me();
      dispatch(setUser(userInfo));
      appContext.setLoading(false);
      navigate('/wedding-party/register/step3');
    } catch (error) {
      message.error((error as Error).message);
      appContext.setLoading(false);
    }
  };

  const onSkip = () => {
    const listSkip: Array<string> = JSON.parse(localStorage.getItem(KeyLocalStorage.Skip) || '[]');
    listSkip.push(activeParty?.id || '');
    localStorage.setItem(KeyLocalStorage.Skip, JSON.stringify(listSkip));
    window.location.reload();
  };

  useEffect(() => {
    appContext.setBackground(BackgroundImageCreateWeddingSteps2);
    activeStep(1);
    getPhysicalSizes();
    if (!activeParty) return;
    if (activeParty.opt_out_measurement) {
      navigate(`/wedding-party/register/step1`);
    }
  }, [activeParty]);

  return (
    <StyleRegisterGroomStep2>
      <section className="back">
        <Link to="/wedding-party/register/step1">
          <FaAngleLeft />
          GO BACK
        </Link>
      </section>
      <section className="icon">
        <img src={iconShirt} alt="shirt" />
      </section>
      <h3 className="title">Let’s Get Fitted</h3>
      <p className="help-text">
        Please complete the Sizing Questionnaire below. For further assistance, you can click the questionmark icon next to each measurement
        for helpful "how to measure" videos!
      </p>
      <MeasuringForm
        age={(physicalSizes && physicalSizes.AGE) || []}
        chest={(physicalSizes && physicalSizes.CHEST_SIZE) || []}
        shoeSize={(physicalSizes && physicalSizes.SHOE_SIZE) || []}
        jeanWaistSize={(physicalSizes && physicalSizes.JEAN_WAIST_SIZE) || []}
        neckSize={(physicalSizes && physicalSizes.NECK_SIZE) || []}
        sleeve={(physicalSizes && physicalSizes.SLEEVE_SIZE) || []}
        tShirtSize={(physicalSizes && physicalSizes.T_SHIRT_SIZE) || []}
        waist={(physicalSizes && physicalSizes.WAIST) || []}
        height={(physicalSizes && physicalSizes.HEIGHT) || []}
        inches={(physicalSizes && physicalSizes.INCHES) || []}
        suitSize={(physicalSizes && physicalSizes.SUIT_SIZE) || []}
        dressShirtSize={(physicalSizes && physicalSizes.DRESS_SHIRT_SIZE) || []}
        seatSize={(physicalSizes && physicalSizes.SEAT) || []}
        jacketWaistSize={(physicalSizes && physicalSizes.JACKET_WAIST) || []}
        onSubmitForm={submitForm}
        disableChange={!activeParty?.active}
        isRegisterStep
        onSkip={onSkip}
      />
    </StyleRegisterGroomStep2>
  );
};

export default WeddingPartyRegisterStep2;
