import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../../styles/definedStyle';

const StyledYourSuit = styled.div``;

const StyledPayment = styled.div`
  .header {
    display: grid;
    justify-content: center;
    margin-top: 15px;
    .suit-info {
      display: grid;
      justify-items: center;
      .info-content {
        width: fit-content;
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 7px;
        span {
          font-weight: 600;
          height: fit-content;
        }
        p {
          margin: 0;
        }
      }
    }
  }
  .wrapper-image {
    margin: 15px auto auto;
    ${tw`relative`}
    width: 198px;
    height: 295px;
    border: 2px solid ${colors.blueDark};
    ${tw`flex justify-center`}
    img {
      object-fit: contain;
      width: 100%;
      max-height: 100%;
    }
    svg {
      ${tw`absolute`}
      right: 34px;
      top: 14px;
      height: 20px;
      width: 20px;
    }
  }
  .suit-name {
    ${tw`font-Poppins-Semi-Bold block text-center`}
    margin-top: 25px;
  }
  .action {
    ${tw`text-center`}
    margin-top: 24px;
  }
  .suit-name {
    ${tw`font-Poppins-Semi-Bold block text-center`}
    margin-top: 25px;
  }
  .action {
    ${tw`text-center`}
    margin-top: 24px;
  }
`;

const StyledSuitRental = styled.div`
  ${tw`text-center`}
  .title {
    ${tw`text-center block font-Poppins-Semi-Bold`}
    margin-top: 37px;
  }
  .suit-rental {
    padding: 25px 26px;
    ${tw`m-auto border border-solid border-greyBlack`}
    margin-top: 16px;
    width: 100%;
    max-width: 290px;
    height: fit-content;
    .title-rental {
      display: grid;
      grid-template-columns: 1fr;
      .title-content {
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 7px;
        width: fit-content;
        justify-self: center;
        span {
          font-weight: 600;
          height: fit-content;
        }
        p {
          margin: 0;
        }
      }
    }
    .image-rental {
      margin-top: 22px;
      img {
        width: 236px;
        height: 157px;
        object-fit: contain;
      }
    }
    .total-cost {
      margin-top: 21px;
    }
    .date-rental {
      ${tw`flex`}
      font-size: 12px;
      line-height: 16px;
      justify-content: space-around;
      justify-items: center;
      .contents {
        ${tw`font-Poppins-Regular`}
      }
      .date {
        ${tw`font-Poppins-Semi-Bold`}
      }
      .payment-date,
      .shipped-date {
        display: grid;
      }
    }
    .action {
      ${tw`flex items-center`}
      margin-top: 20px;
      .total-file-select {
        ${tw`font-Poppins-Regular text-blueDark text-opacity-50 block text-left flex-1`}
        margin-left: 13px;
        font-size: 10px;
      }
    }
  }
`;

export { StyledYourSuit, StyledPayment, StyledSuitRental };
