import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../styles/definedStyle';

const StyleLoginIndex = styled.div`
  ${tw`flex justify-end h-screen mr-24`}
  width: 384px;
  background-color: ${colors.black};
  .login-wrapper {
    ${tw`w-full overflow-auto flex flex-col justify-between`}
    .head {
      ${tw`flex items-center justify-center`}
    }
    .center-item {
      ${tw`flex-auto flex-col flex items-center justify-center`}
      .title {
        ${tw`font-Poppins-Semi-Bold text-white`}
        margin-bottom: 30px;
        font-size: 24px;
      }
    }
    .footer-item {
      ${tw`flex justify-center items-center font-Poppins-Semi-Bold text-white`}
      margin-bottom: 40px;
      font-size: 16px;
      a {
        color: ${colors.white};
      }
    }
    .login-logo {
      ${tw`m-12`}
    }
  }
  @media only screen and (max-width: 767px) {
    margin: 0;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    .ant-form {
      width: 250px !important;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 0 20px;
  }
`;

const StyleLoginLayout = styled.div`
  ${tw`flex justify-end flex-auto`}
`;

const StyleLoginPage = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  .action {
    ${tw`flex flex-col items-center`}
    button:not(:first-of-type) {
      margin-top: 20px;
    }
  }
  .forgot {
    ${tw`flex justify-center text-orange font-Source-Sans-Pro-Bold`}
    margin-top: 18px;
  }
  .remember {
    margin-top: 30px;
    ${tw`text-white`}
    div {
      ${tw`font-Source-Sans-Pro-Regular`}
      margin-left: 10px;
    }
  }
`;

export { StyleLoginIndex, StyleLoginLayout, StyleLoginPage };
