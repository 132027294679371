import { ActionBase } from '..';
import { SET_SUIT_MASTER_DATA, SET_STATE_PARTY_MODAL, SET_ALERT_CONFIG } from './action';
import { AssetState } from './model';

const initialReducer: AssetState = {
  suitMasterData: null,
  isOpenPartyModal: false,
  alertConfig: null,
};

const assetReducer = (state = initialReducer, action: ActionBase): AssetState => {
  switch (action.type) {
    case SET_SUIT_MASTER_DATA:
      return {
        ...state,
        suitMasterData: action.payload,
      };
    case SET_STATE_PARTY_MODAL:
      return {
        ...state,
        isOpenPartyModal: action.payload,
      };
    case SET_ALERT_CONFIG:
      return {
        ...state,
        alertConfig: action.payload,
      };
    default:
      return state;
  }
};

export default assetReducer;
