import { ISuit } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/models';
import { Row, Spin } from 'antd';
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iconShirt from '../../../assets/images/iconShirt.png';
import { pageSize } from '../../../constants';
import { StatusSuit } from '../../../enum';
import { RootState } from '../../../reducers';
import { setListSuit } from '../../../reducers/suit/action';
import { StyleButtonOrange, StyledScrollIndex } from '../../ui/Styled';
import { StyledListSuits } from './StyleSuitList';

interface ListSuitsProps {
  payment(id: string): void;
  onSelectSuit(suit: ISuit): void;
  suitSelected?: ISuit;
  getMoreSuits(): void;
  disableAction?: boolean;
}
const ListSuits: FC<ListSuitsProps> = (props) => {
  const { payment, onSelectSuit, suitSelected, getMoreSuits, disableAction } = props;
  const [loadMore, setLoadMore] = useState(false);
  const suitListBoxRef = useRef<HTMLDivElement | null>(null);
  const yourSuitRef = useRef<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);
  const listSuit = useSelector((state: RootState) => state.suitReducer.listSuit);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadMore(false);
  }, [listSuit]);
  useEffect(() => {
    return () => {
      dispatch(setListSuit({ page: 0, count: 0, results: [] }));
    };
  }, []);

  const mutationObserverCallback = useCallback((mutationsList: MutationRecord[], observer: MutationObserver) => {
    if (mutationsList[0].type === 'childList' && yourSuitRef) {
      yourSuitRef.current?.scrollIntoView({ behavior: 'smooth' });
      observer.disconnect();
    }
  }, []);
  const mutationConfig = {
    attributes: true,
    childList: true,
    subtree: true,
  };

  const intersectionObserverCallback = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      if (entries[0].isIntersecting) {
        if (listSuit.page * pageSize < listSuit.count) {
          setLoadMore(true);
          getMoreSuits();
        }
        observer.disconnect();
      }
    },
    [listSuit],
  );

  useLayoutEffect(() => {
    const mutationObserver = new MutationObserver(mutationObserverCallback);
    if (suitListBoxRef.current) {
      mutationObserver.observe(suitListBoxRef.current, mutationConfig);
    }
  }, [mutationObserverCallback]);

  useLayoutEffect(() => {
    let intersectionObserver: IntersectionObserver;
    if (suitListBoxRef && lastItemRef.current && lastItemRef && lastItemRef.current) {
      const intersectionConfig = {
        root: suitListBoxRef.current,
        rootMargin: '0px',
      };
      intersectionObserver = new IntersectionObserver(intersectionObserverCallback, intersectionConfig);
      intersectionObserver.observe(lastItemRef.current);
    }
    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }
    };
  }, [intersectionObserverCallback]);

  return (
    <StyledListSuits>
      <div className="title">Your Party’s Suits</div>
      <StyledScrollIndex className="activity-suit" ref={suitListBoxRef}>
        {listSuit.results.map((suit, index) => {
          return (
            <Row
              className={`party-row ${suitSelected && suitSelected.id === suit.id ? 'active' : ''} `}
              key={index}
              wrap={false}
              onClick={() => onSelectSuit(suit)}
              ref={(ref) => {
                if (index === 0) {
                  yourSuitRef.current = ref;
                }
                if (index === listSuit.results.length - 1) {
                  lastItemRef.current = ref;
                }
              }}
            >
              <img alt="icon" src={suit.suit_photo || iconShirt} className="party-image" />
              <div className="suit-info">
                <span className="suit-info-name">{suit.suit_user_name}</span>
                <span className="suit-info-status">Status:</span>
                <span className="suit-info-title">{suit.status_description}</span>
              </div>
              {(suit.status === StatusSuit.NEED_TO_PAY || suit.status === StatusSuit.PAYMENT_FAILED) && suit?.suit_color_obj?.name ? (
                <div className="you-complete">
                  <StyleButtonOrange width={80} height={36} onClick={() => payment(suit.id)} disabled={disableAction}>
                    Pay for Suit
                  </StyleButtonOrange>
                </div>
              ) : null}
            </Row>
          );
        })}
        {loadMore && (
          <div className="spin">
            <Spin size="small" />
          </div>
        )}
      </StyledScrollIndex>
    </StyledListSuits>
  );
};

export default ListSuits;
