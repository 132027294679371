import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../../styles/definedStyle';

const StyledPartySuit = styled.div`
  .suit {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
  }
  @media only screen and (max-width: 576px) {
    .suit {
      grid-template-columns: 1fr;
    }
  }
`;

const SuitInformationStyles = styled.div`
  width: 100%;
  .title {
    ${tw`font-bold font-Poppins-Semi-Bold text-center`}
    .view-your-suit {
      background: none;
      border: none;
      color: ${colors.orange};
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .suit-information {
    margin-top: 16px;
    height: 420px;
    width: 100%;
    min-width: 317px;
    padding: 0 27px 25px;
    ${tw`border border-greyBlack border-solid flex flex-col`}
    .your-title {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr;
      .title-content {
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 7px;
        width: fit-content;
        justify-self: center;
        span {
          font-weight: 600;
          height: fit-content;
        }
        p {
          margin: 0;
        }
      }
    }
    .suit-empty {
      ${tw`flex flex-auto items-center justify-center font-Poppins-Semi-Bold`}
    }
    .you-image {
      ${tw`flex justify-center`}
      margin-top: 22px;
      width: 100%;
      height: 157px;
      img {
        object-fit: contain;
      }
    }
    .you-footer {
      margin-bottom: 6px;
      margin-top: 21px;
      * {
        ${tw`font-Poppins-Regular flex justify-center`}
        font-size: 12px;
      }
      .paid {
        ${tw`font-Poppins-Semi-Bold`}
      }
    }
    .you-complete {
      ${tw`flex justify-center items-center`}
    }
    .sent-payment {
      margin-top: 21px;
      .payment-sent,
      .suit-shipped {
        ${tw`flex flex-col items-center`}
        .title {
          ${tw`font-Poppins-Regular`}
          font-weight: 400;
          font-size: 12px;
        }
        .text {
          ${tw`font-Poppins-Semi-Bold`}
          font-size: 12px;
        }
      }
    }
    .action {
      ${tw`flex items-center`}
      margin-top: 20px;
      .total-file-select {
        ${tw`font-Poppins-Regular text-blueDark text-opacity-50`}
        margin-left: 13px;
        font-size: 10px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .suit-information {
      min-width: 100%;
    }
  }
`;

const StyledListSuits = styled.div`
  display: grid;
  .title {
    ${tw`font-Poppins-Semi-Bold text-center`}
  }
  .activity-suit {
    height: 420px;
    margin-top: 16px;
    border: 1px solid ${colors.greyback};
    .party-row {
      height: 96px;
      ${tw`items-center`};
      padding: 16px 30px 16px 10px;
      border-top: 1px solid ${colors.greyback};
      cursor: pointer;
      border-left: 12px solid ${colors.white};
      &:first-of-type {
        border-top: 0px;
      }
      &:last-of-type {
        border-bottom: 1px solid ${colors.greyback};
      }
      &.active {
        border-left: 12px solid ${colors.orange};
      }
      .party-image {
        width: 55px;
        height: 55px;
        object-fit: contain;
      }
      .suit-info {
        margin-left: 13px;
        ${tw`flex-auto flex flex-col`}
        .suit-info-name {
          ${tw`font-Poppins-Regular`}
        }
        .suit-info-status {
          ${tw`font-Source-Sans-Pro-Bold`}
        }
      }
    }
    .spin {
      ${tw`py-2 text-center`}
    }
  }
`;

export { StyledPartySuit, SuitInformationStyles, StyledListSuits };
