import { ISuitBuilder } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/models';
import { ModalFuncProps } from 'antd';
import { ActionBase } from '..';

const BASE_ACTION = '@asset';

const SET_SUIT_MASTER_DATA = `${BASE_ACTION}/`;
const SET_STATE_PARTY_MODAL = `${BASE_ACTION}/OPEN_PARTY_MODAL`;
const SET_ALERT_CONFIG = `${BASE_ACTION}/SET_ALERT_CONFIG`;
const setSuitMasterData = (payload: ISuitBuilder | null): ActionBase => ({ type: SET_SUIT_MASTER_DATA, payload });
const setStatePartyModal = (payload: boolean): ActionBase => ({ type: SET_STATE_PARTY_MODAL, payload });
const setAlertConfig = (payload: ModalFuncProps): ActionBase => ({ type: SET_ALERT_CONFIG, payload });

export { SET_SUIT_MASTER_DATA, SET_STATE_PARTY_MODAL, SET_ALERT_CONFIG, setSuitMasterData, setStatePartyModal, setAlertConfig };
