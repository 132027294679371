import styled from '@emotion/styled';
import { Popover } from 'antd';
import React, { useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { colors } from 'styles/definedStyle';

type WarningBoxProps = {
  content: string | React.ReactElement;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const WarningBoxStyles = styled.div`
  position: fixed;
  z-index: 1001;
  bottom: 50px;
  right: 10px;
  color: red;
  svg {
    position: relative;
    top: 3px;
    width: 50px;
    color: ${colors.orange};
    height: auto;
  }
  .ant-popover-inner {
    background: ${colors.orange};
    color: white;
    max-width: 390px;
    width: 80vw;
    .ant-popover-inner-content {
      color: white;
      font-size: 19px;
    }
  }
`;

const WarningBox = (props: WarningBoxProps) => {
  const { content, visible, setVisible } = props;
  const [isRendered, setRendered] = useState(false);
  return (
    <WarningBoxStyles
      id="warning-box-wrapper"
      ref={(node) => {
        if (node) {
          setRendered(true);
        }
      }}
    >
      {isRendered && (
        <Popover
          content={content}
          trigger=""
          getPopupContainer={() => {
            return document.getElementById('warning-box-wrapper') || document.body;
          }}
          placement="topRight"
          visible={visible}
        >
          <AiOutlineWarning id="warning-icon" onClick={() => setVisible((prev) => !prev)} />
        </Popover>
      )}
    </WarningBoxStyles>
  );
};

export default WarningBox;
