import { RouteComponentProps } from '@reach/router';
import React, { FC, useContext, useEffect } from 'react';
import BackgroundImageCreateWeddingStep1 from '../../../../assets/images/wedding-party-create-background.jpg';
import { AppContext } from '../../../contexts/AppContext';
import HeaderContainer from '../../../ui/HeaderContainer';
import { StyleRegisterGroomStep1 } from '../StyleRegisterGroom';
import UserInfoStep1 from './UserInfoStep1';

interface WeddingPartyRegisterStep1Props extends RouteComponentProps {
  activeStep(step: number): void;
}

const RegisterGroomStep1: FC<WeddingPartyRegisterStep1Props> = (props) => {
  const { activeStep } = props;

  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.setBackground(BackgroundImageCreateWeddingStep1);
    activeStep(0);
  }, []);

  return (
    <StyleRegisterGroomStep1>
      <HeaderContainer title="Register Your Info" />
      <UserInfoStep1 />
    </StyleRegisterGroomStep1>
  );
};

export default RegisterGroomStep1;
