import styled from '@emotion/styled';
import { Modal } from 'antd';
import tw from 'twin.macro';
import { colors } from '../../../styles/definedStyle';

const StyleListWedding = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-body {
      padding: 25px 29px 32px 30px;
      .back button {
        ${tw`flex items-center font-Poppins-Semi-Bold text-blueDark p-0`}
        font-size: 16px;
      }
      .header {
        margin-top: 23px;
        h3 {
          ${tw`font-Poppins-Semi-Bold text-center text-blueDark`}
          font-size: 24px;
          line-height: 35px;
        }
      }
      .list {
        ${tw`flex flex-col`}
        border:1px solid ${colors.greyback};
        .wedding-item {
          padding-left: 16px;
          cursor: pointer;
          &:not(:last-child) {
            border-bottom: 1px solid ${colors.greyback};
          }
          .item {
            ${tw`flex items-center `}
            &:not(:last-child) {
              ${tw`border-0 border-b border-solid border-greyLight border-opacity-50`}
            }
            height: 90px;
            .left {
              ${tw`flex flex-col  flex-auto `}
              .name {
                ${tw`font-Poppins-Regular text-blueDark `}
                line-height: 21px;
              }
              .role {
                ${tw`font-Source-Sans-Pro-Regular`}
                line-height: 18px;
              }
              .date {
                ${tw`font-Source-Sans-Pro-Regular text-darkLight text-opacity-50`}
                line-height: 18px;
              }
            }
            .arrow {
              margin-right: 41px;
            }
          }
        }
      }
      .no-data {
        text-align: center;
        padding: 30px;
      }
      .spinner {
        padding: 30px;
        display: grid;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const StyleUserNotAssignWedding = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
  }
  h3 {
    ${tw`font-Poppins-Regular text-xl`}
  }
  .action {
    ${tw`flex justify-end mt-8`}
  }
`;

export { StyleListWedding, StyleUserNotAssignWedding };
