import React, { FC, useContext } from 'react';
import { Modal, Row, Spin } from 'antd';
import { AppContext } from '../contexts/AppContext';

const Loading: FC = () => {
  const appContext = useContext(AppContext);
  return (
    <Modal
      footer={null}
      modalRender={() => (
        <Row justify="center">
          <Spin />
        </Row>
      )}
      zIndex={9999}
      closable={false}
      centered
      visible={appContext.isLoading}
    />
  );
};

export default Loading;
