// import { UserProfileParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Form, Empty, Image } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import UploadPhoto from 'components/suit/list/modal/UploadImage';
import { identity, pickBy } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import * as yup from 'yup';
import DefaultImage from 'assets/images/default-image.jpeg';
import helpIcon from '../../../../assets/images/help-circle.png';
import { videoUrl } from '../../../../constants';
import BaseInput from '../../../ui/Input';
import BasePlayer from '../../../ui/Player';
import BaseRadio from '../../../ui/Radio';
import BaseSelectOption, { DataSelectOption } from '../../../ui/SelectOption';
import { StyleButtonGrey, StyleButtonOrange } from '../../../ui/Styled';
import { StyleWeddingPartyRegisterStep3Form } from '../StyleRegisterGroomsman';

interface RegisterGroomsmanSchema {
  userSuitImage?: string;
  age: string;
  height: string;
  inch?: string;
  weight: string;
  chestStructure: string;
  stomachStructure: string;
  jeanWaistSize: string;
  waist: string;
  suitSize?: string;
  dressShirtSize?: string;
  chestSize: string;
  neckSize: string;
  sleeveSize: string;
  tShirtSize: string;
  jacketWaist: string;
  seat: string;
  shoeSize: string;
  facingImage?: any;
  profileImage?: any;
  describeBuild: string;
}

export interface DataSubmit {
  id: string;
  data: any;
}

interface MeasuringFormProps {
  age: DataSelectOption[];
  height: DataSelectOption[];
  inches: DataSelectOption[];
  jeanWaistSize: DataSelectOption[];
  waist: DataSelectOption[];
  chest: DataSelectOption[];
  neckSize: DataSelectOption[];
  sleeve: DataSelectOption[];
  tShirtSize: DataSelectOption[];
  suitSize: DataSelectOption[];
  dressShirtSize: DataSelectOption[];
  jacketWaistSize: DataSelectOption[];
  seatSize: DataSelectOption[];
  shoeSize: DataSelectOption[];
  onSubmitForm(data: DataSubmit): void;
  onSkip?: () => void;
  isSkipped?: boolean;
  actionName?: string;
  disableChange?: boolean;
  isRegisterStep: boolean;
}

const MeasuringForm: FC<MeasuringFormProps> = (props) => {
  const {
    age,
    height,
    inches,
    waist,
    tShirtSize,
    sleeve,
    neckSize,
    dressShirtSize,
    jeanWaistSize,
    chest,
    shoeSize,
    suitSize,
    seatSize,
    jacketWaistSize,
    actionName = 'Continue',
    disableChange,
    isRegisterStep,
    isSkipped,
    onSubmitForm,
    onSkip,
  } = props;
  const [videoPreview, setVideoPreview] = useState({ url: '', visible: false });
  const [isOpenUploadPhotoModal, setOpenUploadPhotoModal] = useState<boolean>(false);
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);
  const formSchema = yup.object().shape<RegisterGroomsmanSchema>({
    age: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    weight: yup
      .string()
      .strict(false)
      .transform((v) => (v === null ? '' : v))
      .trim()
      .required('This field is required.'),
    jeanWaistSize: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    tShirtSize: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    neckSize: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    sleeveSize: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    waist: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    chestSize: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    shoeSize: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    stomachStructure: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    chestStructure: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    height: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    seat: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    jacketWaist: yup
      .string()
      .transform((v) => (v === null ? '' : v))
      .required('This field is required.'),
    describeBuild: yup
      .string()
      .strict(false)
      .transform((v) => (v === null ? '' : v))
      .trim()
      .required('This field is required.'),
    ...(!suitDetail?.facing_image
      ? {
          facingImage: yup
            .mixed()
            .transform((v) => (!v ? undefined : v))
            .required('This field is required.'),
        }
      : {}),
    ...(!suitDetail?.user_suit_image
      ? {
          profileImage: yup
            .mixed()
            .transform((v) => (!v ? undefined : v))
            .required('This field is required.'),
        }
      : {}),
  });

  const { handleSubmit, control, setValue, clearErrors, formState } = useForm<RegisterGroomsmanSchema>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const submitForm = handleSubmit((data) => {
    const formSubmitData: any = {
      age: data.age,
      chest_size: data.chestSize,
      chest_structure: data.chestStructure,
      dress_shirt_size: data.dressShirtSize || '',
      height: data.height,
      inch: data.inch || '',
      jean_waist_size: data.jeanWaistSize,
      neck_size: data.neckSize,
      sleeve_size: data.sleeveSize,
      stomach_structure: data.stomachStructure,
      suit_size: data.suitSize || '',
      t_shirt_size: data.tShirtSize,
      waist: data.waist,
      weight: data.weight,
      shoes_size: data.shoeSize,
      seat: data.seat,
      jacket_waist: data.jacketWaist,
      user_suit_image: data.profileImage,
      facing_image: data.facingImage,
      description: data.describeBuild,
    };
    const removeNull = pickBy(formSubmitData, identity);
    onSubmitForm({
      id: (suitDetail && suitDetail.id) || '',
      data: removeNull,
    });
  });

  const chestStructure = [
    {
      label: 'Muscular',
      value: 'MUSCULAR',
    },
    {
      label: 'Regular',
      value: 'REGULAR',
    },
    {
      label: 'Hefty',
      value: 'HEFLY',
    },
  ];

  const stomachStructure = [
    {
      label: 'Flat',
      value: 'FLAT',
    },
    {
      label: 'Average',
      value: 'AVERAGE',
    },
    {
      label: 'Rounded',
      value: 'ROUNDED',
    },
  ];

  const onClosePreviewVideo = useCallback(() => {
    setVideoPreview({
      url: '',
      visible: false,
    });
  }, []);

  useEffect(() => {
    if (suitDetail && suitDetail.user_suit_image) {
      setValue('userSuitImage', suitDetail.user_suit_image);
    }
  }, [suitDetail]);

  return (
    <StyleWeddingPartyRegisterStep3Form>
      {suitDetail ? (
        <>
          <Form layout="vertical" className="form">
            <div className="info-row row-1 select">
              <div className="info-item">
                <Form.Item
                  label={
                    <p>
                      Your Age <span className="asterisk">*</span>
                    </p>
                  }
                  validateStatus={formState.errors.age ? 'error' : ''}
                  help={formState.errors.age?.message}
                >
                  <Controller
                    name="age"
                    control={control}
                    defaultValue={suitDetail?.age}
                    render={({ value, onChange }) => {
                      return (
                        <BaseSelectOption
                          data={age}
                          placeholder="Select Age"
                          onChange={onChange}
                          dataSelect={value}
                          disabled={disableChange}
                        />
                      );
                    }}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  label={
                    <p>
                      Your Height <span className="asterisk">*</span>
                    </p>
                  }
                  validateStatus={formState.errors.height ? 'error' : ''}
                  help={formState.errors.height?.message}
                >
                  <Controller
                    name="height"
                    control={control}
                    defaultValue={suitDetail?.height || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={height}
                        placeholder="Select Feet"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item validateStatus={formState.errors.inch ? 'error' : ''} help={formState.errors.inch?.message}>
                  <Controller
                    name="inch"
                    control={control}
                    defaultValue={suitDetail?.inch || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={inches}
                        placeholder="Select Inches"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  label="Your Weight"
                  validateStatus={formState.errors.weight ? 'error' : ''}
                  help={formState.errors.weight?.message}
                >
                  <Controller
                    name="weight"
                    control={control}
                    defaultValue={suitDetail?.weight || null}
                    render={({ value, onChange }) => (
                      <BaseInput placeholder="Enter Weight" type="number" value={value} onChange={onChange} disabled={disableChange} />
                    )}
                  />
                </Form.Item>
              </div>
            </div>
            <Divider className="divider" />

            <div className="info-row row-2 radio">
              <Form.Item
                label={
                  <p>
                    Chest Structure <span className="asterisk">*</span>
                  </p>
                }
                validateStatus={formState.errors.chestStructure ? 'error' : ''}
                help={formState.errors.chestStructure?.message}
              >
                <Controller
                  name="chestStructure"
                  control={control}
                  defaultValue={suitDetail?.chest_structure || null}
                  render={({ value, onChange }) => (
                    <BaseRadio options={chestStructure} value={value} onChange={(v) => onChange(v.target.value)} disabled={disableChange} />
                  )}
                />
              </Form.Item>

              <Form.Item
                label={
                  <p>
                    Stomach Structure <span className="asterisk">*</span>
                  </p>
                }
                validateStatus={formState.errors.stomachStructure ? 'error' : ''}
                help={formState.errors.stomachStructure?.message}
              >
                <Controller
                  name="stomachStructure"
                  control={control}
                  defaultValue={suitDetail?.stomach_structure || null}
                  render={({ value, onChange }) => (
                    <BaseRadio
                      options={stomachStructure}
                      value={value}
                      onChange={(v) => onChange(v.target.value)}
                      disabled={disableChange}
                    />
                  )}
                />
              </Form.Item>
            </div>
            <Divider className="divider" />

            <div className="info-row row-3 select">
              <div className="info-item">
                <Form.Item
                  label={
                    <p>
                      Polo / T-Shirt Size <span className="asterisk">*</span>
                    </p>
                  }
                  validateStatus={formState.errors.tShirtSize ? 'error' : ''}
                  help={formState.errors.tShirtSize?.message}
                >
                  <Controller
                    name="tShirtSize"
                    control={control}
                    defaultValue={suitDetail?.t_shirt_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={tShirtSize}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>

              <div className="info-item">
                <Form.Item
                  label={
                    <p>
                      Jean Waist Size <span className="asterisk">*</span>
                    </p>
                  }
                  validateStatus={formState.errors.jeanWaistSize ? 'error' : ''}
                  help={formState.errors.jeanWaistSize?.message}
                >
                  <Controller
                    name="jeanWaistSize"
                    control={control}
                    defaultValue={suitDetail?.jean_waist_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={jeanWaistSize}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>

              <div className="info-item">
                <Form.Item
                  className="small-text"
                  label={
                    <p>
                      If you currently own a suit, what size is it <span className="asterisk">*</span>
                    </p>
                  }
                >
                  <Controller
                    name="suitSize"
                    control={control}
                    defaultValue={suitDetail?.suit_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={suitSize}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  className="small-text"
                  label={
                    <p>
                      If you currently own a dress shirt, what size is it? <span className="asterisk">*</span>
                    </p>
                  }
                >
                  <Controller
                    name="dressShirtSize"
                    control={control}
                    defaultValue={suitDetail?.dress_shirt_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={dressShirtSize}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </div>
            <Divider className="divider" />

            <div className="info-row  row-4 select row">
              <div className="info-item">
                <Form.Item
                  label={
                    <span className="icon">
                      <p>
                        Neck Size <span className="asterisk">*</span>
                      </p>
                      <img
                        src={helpIcon}
                        onClick={() =>
                          setVideoPreview({
                            url: videoUrl.MEASURING_THE_NECK,
                            visible: true,
                          })
                        }
                        alt="help"
                      />
                    </span>
                  }
                  className="custom"
                  validateStatus={formState.errors.neckSize ? 'error' : ''}
                  help={formState.errors.neckSize?.message}
                >
                  <Controller
                    name="neckSize"
                    control={control}
                    defaultValue={suitDetail?.neck_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={neckSize}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  validateStatus={formState.errors.sleeveSize ? 'error' : ''}
                  help={formState.errors.sleeveSize?.message}
                  label={
                    <span className="icon">
                      <p>
                        Sleeve Length <span className="asterisk">*</span>
                      </p>
                      <img
                        src={helpIcon}
                        onClick={() =>
                          setVideoPreview({
                            url: videoUrl.MEASURING_THE_SLEEVE,
                            visible: true,
                          })
                        }
                        alt="help"
                      />
                    </span>
                  }
                  className="custom"
                >
                  <Controller
                    name="sleeveSize"
                    control={control}
                    defaultValue={suitDetail?.sleeve_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={sleeve}
                        placeholder="Select Length"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  label={
                    <span className="icon">
                      <p>
                        Chest <span className="asterisk">*</span>
                      </p>
                      <img
                        src={helpIcon}
                        onClick={() =>
                          setVideoPreview({
                            url: videoUrl.MEASURING_THE_CHEST,
                            visible: true,
                          })
                        }
                        alt="help"
                      />
                    </span>
                  }
                  className="custom"
                  validateStatus={formState.errors.chestSize ? 'error' : ''}
                  help={formState.errors.chestSize?.message}
                >
                  <Controller
                    name="chestSize"
                    control={control}
                    defaultValue={suitDetail?.chest_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={chest}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  validateStatus={formState.errors.jacketWaist ? 'error' : ''}
                  help={formState.errors.jacketWaist?.message}
                  label={
                    <span className="icon">
                      <p>
                        Jacket Waist <span className="asterisk">*</span>
                      </p>
                      <img
                        src={helpIcon}
                        onClick={() =>
                          setVideoPreview({
                            url: videoUrl.MEASURING_THE_JACKET_WAIST,
                            visible: true,
                          })
                        }
                        alt="help"
                      />
                    </span>
                  }
                  className="custom"
                >
                  <Controller
                    name="jacketWaist"
                    control={control}
                    defaultValue={suitDetail?.jacket_waist || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={jacketWaistSize}
                        placeholder="Select Length"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </div>

            <Divider className="divider" />
            <div className="info-row  row-4 select row">
              <div className="info-item">
                <Form.Item
                  label={
                    <span className="icon">
                      <p>
                        Waist <span className="asterisk">*</span>
                      </p>
                      <img
                        src={helpIcon}
                        alt="help"
                        onClick={() =>
                          setVideoPreview({
                            url: videoUrl.MEASURING_THE_WAIST,
                            visible: true,
                          })
                        }
                      />
                    </span>
                  }
                  className="custom"
                  validateStatus={formState.errors.waist ? 'error' : ''}
                  help={formState.errors.waist?.message}
                >
                  <Controller
                    name="waist"
                    control={control}
                    defaultValue={suitDetail?.waist || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={waist}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  label={
                    <span className="icon">
                      <p>
                        Seat <span className="asterisk">*</span>
                      </p>
                      <img
                        src={helpIcon}
                        alt="help"
                        onClick={() =>
                          setVideoPreview({
                            url: videoUrl.MEASURING_THE_SEAT,
                            visible: true,
                          })
                        }
                      />
                    </span>
                  }
                  validateStatus={formState.errors.seat ? 'error' : ''}
                  help={formState.errors.seat?.message}
                  className="custom"
                >
                  <Controller
                    name="seat"
                    control={control}
                    defaultValue={suitDetail?.seat || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={seatSize}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="info-item">
                <Form.Item
                  label={
                    <p>
                      Shoe Size <span className="asterisk">*</span>
                    </p>
                  }
                  validateStatus={formState.errors.shoeSize ? 'error' : ''}
                  help={formState.errors.shoeSize?.message}
                >
                  <Controller
                    name="shoeSize"
                    control={control}
                    defaultValue={suitDetail?.shoes_size || null}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={shoeSize}
                        placeholder="Select Size"
                        onChange={onChange}
                        dataSelect={value}
                        disabled={disableChange}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </div>
            <Divider className="divider" />
            {!isRegisterStep && !isSkipped ? (
              <>
                <div className="info-row your-photo action">
                  <div className="image">
                    <h3>
                      Full length forward facing photo of yourself <span className="asterisk">*</span>
                    </h3>
                    <Image alt="" src={suitDetail?.facing_image || DefaultImage} />
                  </div>
                  <div className="image">
                    <h3>
                      Full length side/profile photo of yourself <span className="asterisk">*</span>
                    </h3>
                    <Image src={suitDetail?.user_suit_image || DefaultImage} alt="" />
                  </div>
                </div>
                <Divider className="divider" />
                <div className="info-row describe-build action">
                  <h3>
                    Describe your Build type <span className="asterisk">*</span>
                  </h3>
                  <TextArea rows={4} placeholder="Type here..." disabled={disableChange} value={suitDetail.description} />
                  <h5 className="footer-row">
                    Ex: long arm span, broad shoulders, tall/lean, average build, muscular/big things, long/short torso, long/short legs,
                    carries weight in midsection, athletic build, etc.
                  </h5>
                </div>
                <div className="continue">
                  <StyleButtonOrange width={150} onClick={() => submitForm()} disabled={disableChange}>
                    {actionName}
                  </StyleButtonOrange>
                  {onSkip && (
                    <StyleButtonGrey className="btn-skip" width={150} onClick={() => onSkip()} disabled={disableChange}>
                      Skip
                    </StyleButtonGrey>
                  )}
                </div>
              </>
            ) : (
              <div className="info-row upload-image action">
                <div className="image">
                  <h3>Please click below to upload a couple full length photos for us</h3>
                  <h4>Do us a favor and please be clothed!</h4>
                  <Form.Item
                    validateStatus={
                      formState.errors.describeBuild || formState.errors.facingImage || formState.errors.profileImage ? 'error' : ''
                    }
                    help={
                      formState.errors.describeBuild?.message ||
                      formState.errors.facingImage?.message ||
                      formState.errors.profileImage?.message
                    }
                  >
                    <StyleButtonGrey
                      disabled={disableChange}
                      width={135}
                      className="choose-file"
                      onClick={() => setOpenUploadPhotoModal(true)}
                    >
                      Upload Photos
                    </StyleButtonGrey>
                  </Form.Item>
                  {/* hidden for check code */}
                  <Controller
                    name="facingImage"
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => <input value={value} onChange={onChange} hidden />}
                  />
                  <Controller
                    name="profileImage"
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => <input value={value} onChange={onChange} hidden />}
                  />
                  <Controller
                    name="describeBuild"
                    control={control}
                    defaultValue={suitDetail.description}
                    render={({ value, onChange }) => <input value={value} onChange={onChange} hidden />}
                  />
                </div>
                <div className="continue">
                  {onSkip && (
                    <StyleButtonGrey className="btn-skip" width={150} onClick={() => onSkip()} disabled={disableChange}>
                      Skip
                    </StyleButtonGrey>
                  )}
                  <StyleButtonOrange width={150} onClick={() => submitForm()} disabled={disableChange}>
                    {actionName}
                  </StyleButtonOrange>
                </div>
              </div>
            )}
          </Form>
          <BasePlayer onClose={onClosePreviewVideo} videoUrl={videoPreview.url} visible={videoPreview.visible} />
          <UploadPhoto
            visible={isOpenUploadPhotoModal}
            facingImageUrl={suitDetail?.facing_image}
            profileImageUrl={suitDetail?.user_suit_image}
            description={suitDetail?.description}
            onOk={(data) => {
              Object.keys(data).forEach((key) => {
                setValue(key, data[key]);
                clearErrors(key);
              });
              setOpenUploadPhotoModal(false);
            }}
            onCancel={() => setOpenUploadPhotoModal(false)}
          />
        </>
      ) : (
        <div className="no-data">
          <Empty />
        </div>
      )}
    </StyleWeddingPartyRegisterStep3Form>
  );
};

export default MeasuringForm;
