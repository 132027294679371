import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import logo from '../../assets/images/logo-login.png';
import LoginBase from './LoginBase';
import { StyleLoginIndex } from './StyleLogin';

const LoginIndex = () => {
  return (
    <StyleLoginIndex className="login-index">
      <div className="login-wrapper">
        <div className="head">
          <img className="login-logo" src={logo} alt="logo" />
        </div>
        <div className="center-item">
          <span className="title">Login to Our Portal</span>
          <LoginBase />
        </div>
        <div className="footer-item">
          <FaAngleLeft />
          <a href="https://themoderngroom.com/">
            <span className="return">RETURN TO MAIN SITE</span>
          </a>
        </div>
      </div>
    </StyleLoginIndex>
  );
};

export default LoginIndex;
