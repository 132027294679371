import { api } from '@goldfishcode/modern-groom-api-sdk';
import { ListWeddingPartyMemberParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '@reach/router';
import { Form, message, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import UseConfigAlert from 'components/hooks/useConfigAlert';
import UseHandleEmailAction from 'components/hooks/useHandleEmailAction';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setAuth } from 'reducers/auth/action';
import * as yup from 'yup';
import { KeyLocalStorage } from '../../enum';
import { setStatePartyModal } from '../../reducers/asset/action';
import { setListParty } from '../../reducers/user/action';
import { getParamsUrl } from '../../util';
import { AppContext } from '../contexts/AppContext';
import ResetPassword from '../reset-password/ResetPassword';
import CheckBase from '../ui/Checkbox';
import BaseInput from '../ui/Input';
import BaseInputPassword from '../ui/InputPassword';
import { StyleButtonOrange } from '../ui/Styled';
import { StyleLoginPage } from './StyleLogin';

interface LoginForm {
  email: string;
  password: string;
}

const weddingPartyListParams: ListWeddingPartyMemberParams = {
  limit: 100,
  page: 1,
};

const LoginBase: React.FC = () => {
  const appContext = useContext(AppContext);
  const storeIsRemember = localStorage.getItem(KeyLocalStorage.RememberMe);
  const [isRemember, setRemember] = useState(storeIsRemember ? JSON.parse(storeIsRemember)?.value : false);
  const listParty = useSelector((state: RootState) => state.userReducer.listParty);
  const dispatch = useDispatch();
  const queryParams = useMemo(() => getParamsUrl<any>(), []);

  const { showModalForceLogout } = UseConfigAlert();
  UseHandleEmailAction();

  const loginSchema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().strict(false).trim().required('Email is required').email('Email is invalid'),
      password: yup.string().strict(false).trim().required('Password is required'),
    });
  }, []);

  const { control, handleSubmit, formState } = useForm<LoginForm>({
    resolver: yupResolver(loginSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const getPartyList = async () => {
    try {
      const res = await api.User.myWeddingParties(weddingPartyListParams);
      if (res.results && res.results.length > 0) {
        dispatch(setListParty(res.results));
      } else {
        showModalForceLogout();
      }
    } catch (error) {
      if ((error as any).code === 1011) return;
      showModalForceLogout();
    }
  };

  const onChangeRemember = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    setRemember(isChecked);
    if (isChecked) {
      const dataStore = {
        value: isChecked,
      };
      const dataString = JSON.stringify(dataStore);
      localStorage.setItem(KeyLocalStorage.RememberMe, dataString);
    } else {
      localStorage.removeItem(KeyLocalStorage.RememberMe);
    }
  };
  const onLogin = async (email: string, password: string) => {
    appContext.setLoading(true);
    try {
      await api.Auth.login(email, password);
      await getPartyList();
      appContext.setLoading(false);
    } catch (error) {
      message.error('Email or password is invalid.');
      appContext.setLoading(false);
    }
  };

  const handleLogin = handleSubmit((data) => {
    const { email, password } = data;
    onLogin(email, password);
  });

  const activeAccount = async (email: string, otp: string): Promise<void> => {
    try {
      appContext.setLoading(true);
      await api.Auth.verifyAccount({
        email,
        otp,
      });
      await getPartyList();
      appContext.setLoading(false);
    } catch (error) {
      appContext.setLoading(false);
      message.error((error as Error).message);
    }
  };

  useEffect(() => {
    if (queryParams && queryParams.email && queryParams.otp && !queryParams?.resetPassword) {
      activeAccount(queryParams.email, queryParams.otp);
    }
  }, []);

  useEffect(() => {
    if (listParty.length === 0) return;
    if (Object.keys(queryParams).length !== 0 && queryParams?.user_id) {
      dispatch(setAuth(true));
      navigate(`/?${window.location.href.split('?')[1]}`);
      return;
    }
    dispatch(setStatePartyModal(true));
  }, [listParty, queryParams]);

  return (
    <StyleLoginPage>
      <Form className="form" onFinish={handleLogin}>
        <Form.Item validateStatus={formState.errors.email ? 'error' : ''} help={formState.errors.email?.message}>
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({ onChange, value }) => (
              <BaseInput onChange={onChange} value={value} placeholder="Email Address" className="email" autoComplete="new-email" />
            )}
          />
        </Form.Item>
        <Form.Item validateStatus={formState.errors.password ? 'error' : ''} help={formState.errors.password?.message}>
          <Controller
            control={control}
            name="password"
            defaultValue=""
            render={({ onChange, value }) => (
              <BaseInputPassword
                onChange={onChange}
                value={value}
                type="password"
                placeholder="Password"
                className="password"
                autoComplete="new-password"
              />
            )}
          />
        </Form.Item>
        <div className="action">
          <StyleButtonOrange width={187} htmlType="submit">
            Login
          </StyleButtonOrange>
        </div>
        <Row className="remember" justify="center">
          <CheckBase onChange={onChangeRemember} checked={isRemember} />
          <div>Remember me</div>
        </Row>
      </Form>
      <ResetPassword />
    </StyleLoginPage>
  );
};

export default LoginBase;
