import { api } from '@goldfishcode/modern-groom-api-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from '@reach/router';
import { Form, message } from 'antd';
import { isObject } from 'lodash';
import moment, { Moment } from 'moment';
import React, { FC, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { RegPhoneNumber } from '../../../../constants';
import { RootState } from '../../../../reducers';
import { setUser, setActiveParty, setListParty } from '../../../../reducers/user/action';
import { formatPhone } from '../../../../util';
import { AppContext } from '../../../contexts/AppContext';
import BaseCalendar from '../../../ui/Calendar';
import BaseInput from '../../../ui/Input';
import { StyleButtonOrange } from '../../../ui/Styled';
import { StyleUserInfoStep1 } from './StyleStep1';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .strict(false)
    .trim()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.')
    .test('len', 'This field must be less than 30 characters', (v) => !!(v && v.length <= 30)),
  lastName: yup
    .string()
    .strict(false)
    .trim()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.')
    .test('len', 'This field must be less than 30 characters', (v) => !!(v && v.length <= 30)),
  partyName: yup
    .string()
    .strict(false)
    .trim()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.'),
  weddingDate: yup
    .string()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.'),
  phoneNumber: yup
    .string()
    .strict(false)
    .trim()
    .transform((v) => (v === null ? '' : v))
    .required('This field is required.')
    .test('phone', 'Your phone is invalid', (v) => !!v && RegPhoneNumber.test(v)),
});
interface SubmitProps {
  firstName: string;
  lastName: string;
  partyName: string;
  weddingDate: string;
  phoneNumber: string;
}
interface ValidateProps {
  firstName: string;
  lastName: string;
  partyName: string;
  weddingDate: string | Moment;
  phoneNumber: string;
}

interface UserInfoStep1Props {
  className?: string;
  actionName?: string;
  onChangeSuccess?(): void;
  disableChange?: boolean;
}
const UserInfoStep1: FC<UserInfoStep1Props> = ({ actionName = 'Continue', onChangeSuccess, className, disableChange }) => {
  const { control, handleSubmit, formState, trigger } = useForm<ValidateProps>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const appContext = useContext(AppContext);
  const userProfile = useSelector((state: RootState) => state.userReducer.userProfile);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const listParty = useSelector((state: RootState) => state.userReducer.listParty);
  const dispatch = useDispatch();

  const onSubMitStep1 = async (data: SubmitProps) => {
    if (!activeParty) return;
    try {
      appContext.setLoading(true);
      const params = {
        first_name: data.firstName,
        last_name: data.lastName,
        wedding_party_name: data.partyName,
        wedding_date: data.weddingDate !== userProfile?.wedding_date ? data.weddingDate : undefined,
        phone: data.phoneNumber,
        ...(activeParty?.opt_out_measurement ? { profile_completed: true } : {}),
      };
      const userInfo = await api.User.updateProfile(params);
      const newActiveParty = {
        ...activeParty,
        party_name: data.partyName,
        wedding_date: data.weddingDate,
        ...(activeParty?.opt_out_measurement ? { profile_completed: true } : {}),
      };
      const newListParty = [...listParty];
      const indexOfActiveParty = listParty.findIndex((party) => party.id === newActiveParty.id);
      if (indexOfActiveParty !== -1) {
        newListParty[indexOfActiveParty] = newActiveParty;
      }
      dispatch(setListParty(newListParty));
      dispatch(setActiveParty(newActiveParty));
      dispatch(setUser(userInfo));
      if (typeof onChangeSuccess === 'function') {
        onChangeSuccess();
      } else {
        if (activeParty?.opt_out_measurement) {
          navigate('/groom/your-suit');
          return;
        }
        navigate('/wedding-party/register/step2');
      }
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setLoading(false);
    }
  };
  const onSubmit = handleSubmit((data: SubmitProps) => {
    const resp = moment(data.weddingDate).format('YYYY-MM-DD');
    data.phoneNumber = data.phoneNumber.replace(/([^0-9])/g, '');
    data.weddingDate = resp;
    onSubMitStep1(data);
  });

  return (
    <StyleUserInfoStep1 className={className}>
      <section className="info">
        <Form layout="vertical">
          <div className="info-row full-name-party">
            <p className="row-title">
              Wedding Party Name <span className="asterisk">*</span>
            </p>
            <div className="name">
              <Form.Item
                className="enter"
                validateStatus={formState.errors.partyName ? 'error' : ''}
                help={formState.errors.partyName?.message}
              >
                <Controller
                  name="partyName"
                  control={control}
                  defaultValue={activeParty?.party_name || null}
                  render={({ onChange, value }) => <BaseInput onChange={onChange} value={value} placeholder="Party Name" disabled />}
                />
              </Form.Item>
            </div>
          </div>
          <div className="info-row your-name">
            <p className="row-title">
              Your Name <span className="asterisk">*</span>
            </p>
            <div className="first-name">
              <Form.Item validateStatus={formState.errors.firstName ? 'error' : ''} help={formState.errors.firstName?.message}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={(isObject(userProfile) && userProfile?.first_name) || null}
                  render={({ onChange, value }) => (
                    <BaseInput
                      onChange={(e) => onChange(e.target.value.trim())}
                      value={value}
                      placeholder="First Name"
                      disabled={disableChange}
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="last-name">
              <Form.Item
                className="enter"
                validateStatus={formState.errors.lastName ? 'error' : ''}
                help={formState.errors.lastName?.message}
              >
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={(isObject(userProfile) && userProfile?.last_name) || null}
                  render={({ onChange, value }) => (
                    <BaseInput
                      onChange={(e) => onChange(e.target.value.trim())}
                      value={value}
                      placeholder="Last Name"
                      disabled={disableChange}
                    />
                  )}
                />
              </Form.Item>
            </div>
          </div>
          <div className="info-row phone-number">
            <p className="row-title">
              Your Phone Number <span className="asterisk">*</span>
            </p>
            <div className="phone">
              <Form.Item
                className="enter"
                validateStatus={formState.errors.phoneNumber ? 'error' : ''}
                help={formState.errors.phoneNumber?.message}
              >
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue={formatPhone(userProfile?.phone) || null}
                  render={({ onChange, value }) => (
                    <BaseInput
                      onChange={(e) => {
                        const formattedPhone = formatPhone(e.target.value);
                        if (formattedPhone !== null) {
                          onChange(formattedPhone);
                          trigger('phoneNumber');
                        }
                      }}
                      onBlur={() => {
                        trigger('phoneNumber');
                      }}
                      value={value}
                      placeholder="Phone Number"
                      disabled={disableChange}
                    />
                  )}
                />
              </Form.Item>
            </div>
          </div>
          <div className="calendar-content">
            <h3>Your Wedding Date</h3>
            {/* does not allow users to select or edit wedding dates on FE */}
            <Controller
              name="weddingDate"
              control={control}
              defaultValue={moment(activeParty?.wedding_date ?? moment())}
              render={({ value, onChange }) => (
                <BaseCalendar disabledDate={() => true} daySelected={value} onChangeDaySelected={onChange} />
              )}
            />
          </div>
        </Form>
      </section>
      <section className="action">
        <StyleButtonOrange width={135} onClick={() => onSubmit()} disabled={disableChange}>
          {actionName}
        </StyleButtonOrange>
      </section>
    </StyleUserInfoStep1>
  );
};

export default UserInfoStep1;
