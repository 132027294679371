import { api } from '@goldfishcode/modern-groom-api-sdk';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { message } from 'antd';
import React, { FC, useContext, useEffect } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setActiveParty, setUser } from 'reducers/user/action';
import BackgroundImageCreateWeddingStep2 from '../../../../assets/images/wedding-party-create-background.jpg';
import { AppContext } from '../../../contexts/AppContext';
import HeaderContainer from '../../../ui/HeaderContainer';
import { StyleButtonOrange } from '../../../ui/Styled';
import { StyleWeddingPartyRegisterStep2 } from '../StyleRegisterGroomsman';
import GroomsmanDetail from './GroomsmanDetail';

interface WeddingPartyRegisterStep2Props extends RouteComponentProps {
  onContinue(step: number): void;
  activeStep(step: number): void;
}

const RegisterGroomsmanStep2: FC<WeddingPartyRegisterStep2Props> = (props) => {
  const { onContinue, activeStep } = props;
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const currentActiveParty = useSelector((state: RootState) => state.userReducer.activeParty);

  const onClick = async () => {
    if (!currentActiveParty) return;
    try {
      if (currentActiveParty?.opt_out_measurement) {
        appContext.setLoading(true);
        const profile = await api.User.updateProfile({ profile_completed: true });
        const newActiveParty = { ...currentActiveParty, profile_completed: true };
        dispatch(setActiveParty(newActiveParty));
        dispatch(setUser(profile));
        navigate('/groomsman/your-profile');
        return;
      }
      onContinue(3);
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setLoading(false);
    }
  };

  useEffect(() => {
    appContext.setBackground(BackgroundImageCreateWeddingStep2);
    activeStep(2);
  }, []);

  return (
    <StyleWeddingPartyRegisterStep2>
      <section className="back">
        <Link to="/wedding-party/register/step1">
          <FaAngleLeft />
          GO BACK
        </Link>
      </section>
      <HeaderContainer title="Your Wedding Party Info" />
      <GroomsmanDetail />
      <section className="action">
        <StyleButtonOrange width={135} onClick={onClick}>
          Continue
        </StyleButtonOrange>
      </section>
    </StyleWeddingPartyRegisterStep2>
  );
};

export default RegisterGroomsmanStep2;
