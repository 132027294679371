import React from 'react';

import { useDispatch } from 'react-redux';
import { setAlertConfig, setStatePartyModal } from 'reducers/asset/action';
import useAuth from './useAuth';

const UseConfigAlert = () => {
  const dispatch = useDispatch();
  const { forceLogout } = useAuth();

  const showModalForceLogout: (title?: string, content?: string, okText?: string) => void = (
    title = 'Error',
    content = 'Something went wrong. Please contact your administrator.',
    okText = 'Logout',
  ) => {
    dispatch(
      setAlertConfig({
        visible: true,
        title,
        content,
        okText,
        onOk: async () => {
          await forceLogout();
        },
      }),
    );
  };

  const alertOrderPlaced = (title) => {
    dispatch(
      setAlertConfig({
        type: 'info',
        visible: true,
        title: <h3 className="font-bold text-lg">{title}</h3>,
        content: 'Your order has already been placed and in the event you need to make any changes please use contact us button below!',
        okText: 'Choose other party',
        onOk: async () => {
          dispatch(setStatePartyModal(true));
        },
      }),
    );
  };

  return {
    alertOrderPlaced,
    showModalForceLogout,
  };
};

export default UseConfigAlert;
