/* eslint-disable react/button-has-type */
import { api } from '@goldfishcode/modern-groom-api-sdk';
import { navigate, useLocation } from '@reach/router';
import { Menu, message } from 'antd';
import { UserRole } from 'enum';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { definedGroomRouter } from '../../constants';
import { RootState } from '../../reducers';
import { setReadingStatus } from '../../reducers/user/action';
import { StyleMenuDashboard } from './StyleDashboard';

interface MenuDashboardProps {
  className: string;
}

const MenuDashboardGroom: FC<MenuDashboardProps> = ({ className }) => {
  const [keyActive, setKeyActive] = useState<string[]>(['']);
  const location = useLocation();
  const readingStatus = useSelector((state: RootState) => state.userReducer.readingStatus);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);

  const dispatch = useDispatch();

  const getReadingStatus = async () => {
    try {
      const res = await api.User.checkMarkRead();
      dispatch(setReadingStatus(res));
    } catch (error) {
      message.error((error as Error).message);
    }
  };
  const handleClick = async (e: any) => {
    if (e.domEvent?.currentTarget?.children?.length > 0) {
      getReadingStatus();
    }
    navigate(e.key);
    setKeyActive([e.key]);
  };

  useEffect(() => {
    setKeyActive([location.pathname]);
  }, [location.pathname]);

  useEffect(() => {
    if (!activeParty) return;
    if (activeParty?.user_role?.key === UserRole.GROOM) {
      getReadingStatus();
    }
  }, [activeParty]);

  const MenuList = (
    <Menu onClick={handleClick} selectedKeys={keyActive} mode="inline">
      <Menu.Item key={definedGroomRouter.yourProfile}>Your Profile</Menu.Item>
      {suitDetail && suitDetail.suit_name && <Menu.Item key={definedGroomRouter.yourSuit}>Your Suit</Menu.Item>}
      <Menu.Item key={definedGroomRouter.suit}>Party's Suits {readingStatus.suit_unread >= 1 ? <div className="dot" /> : ''}</Menu.Item>
      <Menu.Item key={definedGroomRouter.weddingPartyList}>
        Wedding Party {readingStatus.groomsmen_unread >= 1 ? <div className="dot" /> : ''}
      </Menu.Item>
      <Menu.Item key={definedGroomRouter.activityList}>
        Activity {readingStatus.activities_unread >= 1 ? <div className="dot" /> : ''}
      </Menu.Item>
    </Menu>
  );

  return <StyleMenuDashboard className={className}>{MenuList}</StyleMenuDashboard>;
};

export default MenuDashboardGroom;
