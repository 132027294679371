import { RouteComponentProps } from '@reach/router';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { KeyLocalStorage } from 'enum';
import { RootState } from '../../../reducers';
import HeaderContainer from '../../ui/HeaderContainer';
import UserInfoStep1 from '../../wedding-party/register-groom/step1/UserInfoStep1';
import ChangeEmailForm from '../change-email/Form';
import ProfileUpdated from '../modal/ProfileUpdated';
import UpdateUserInfoDetail from './step-2/Step2';
import { StyleYourProfileGroom } from './StyleYourProfileGroom';

const YourProfileGroom: FC<RouteComponentProps> = () => {
  const [showModal, setShowModal] = useState(false);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const listSkip: Array<string> = JSON.parse(localStorage.getItem(KeyLocalStorage.Skip) || '[]');
  const [isSkipped, setIsSkipped] = useState(activeParty?.id ? listSkip.includes(activeParty?.id) : false);

  const onChangeSuccess = (isChangeMeasurement: boolean) => {
    if (isSkipped && isChangeMeasurement) {
      setIsSkipped(false);
      localStorage.removeItem(KeyLocalStorage.Skip);
    }
    setShowModal(true);
  };
  return (
    <StyleYourProfileGroom activeParty={activeParty}>
      <HeaderContainer
        title="Your Profile"
        subTitle="Please take your time when creating your profile. Should changes need to be made after submission, please reach out to us by using the Contact Us icon below."
      />
      <ChangeEmailForm disableChange={!activeParty?.active} />
      <div
        className={`user-step-1  mt-[30px] ${
          !activeParty?.opt_out_measurement && 'border-0 border-greyLight border-b border-solid border-opacity-25'
        }`}
      >
        <UserInfoStep1
          actionName="Save Changes"
          onChangeSuccess={() => onChangeSuccess(false)}
          className="profile"
          disableChange={!activeParty?.active}
        />
      </div>
      {!activeParty?.opt_out_measurement && (
        <div className="user-step-3">
          <UpdateUserInfoDetail onChangeSuccess={() => onChangeSuccess(true)} disableChange={!isSkipped} isSkipped={isSkipped} />
        </div>
      )}

      <ProfileUpdated
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </StyleYourProfileGroom>
  );
};

export default YourProfileGroom;
