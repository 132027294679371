// eslint-disable-next-line no-shadow
export enum KeyLocalStorage {
  RememberMe = 'RememberMe',
  Skip = 'Skip',
}

export enum UserRole {
  GROOM = 'GROOM',
  GROOMSMAN = 'GROOMSMAN',
  FATHER_OF_GROOM = 'FATHER_OF_GROOM',
  FATHER_OF_BRIDE = 'FATHER_OF_BRIDE',
  USHER = 'USHER',
  OTHER = 'OTHER',
  GRANDFATHER_OF_THE_BRIDE = 'GRANDFATHER_OF_THE_BRIDE',
  GRANDFATHER_OF_THE_GROOM = 'GRANDFATHER_OF_THE_GROOM',
  OFFICIANT = 'OFFICIANT',
  MAN_OF_HONOR = 'MAN_OF_HONOR',
  ADD_ON_WEDDING_MEMBER = 'ADD_ON_WEDDING_MEMBER',
}

export enum StatusSuit {
  NEED_TO_PAY = 'need_to_pay',
  PAID = 'paid',
  PAYMENT_FAILED = 'payment_failed',
  OTHER = 'other',
}

export enum SuitType {
  YOUR_SUIT = 'YOUR_SUIT',
  PARTY_SUIT = 'PARTY_SUIT',
}

export enum HeaderType {
  REGISTER_MENU = 'REGISTER_HEADER',
  MAIN_MENU = 'MAIN_HEADER',
}
