import { RouteComponentProps, Router } from '@reach/router';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { KeyLocalStorage } from 'enum';
import ActivityList from '../components/activity/list/ActivityList';
import DashBoardGroomContainer from '../components/dashboard/DashBoardGroomContainer';
import GroomSuitList from '../components/suit/list/GroomSuitList';
import WeddingPartyList from '../components/wedding-party/list/WeddingPartyList';
import RegisterGroom from '../components/wedding-party/register-groom/RegisterGroom';
import YourProfileGroom from '../components/your-profile/groom/YourProfileGroom';
import YourSuit from '../components/suit/detail/YourSuit';
import { RootState } from '../reducers';
import DefaultRouter from './DefaultRouter';

const RouterGroom: FC<RouteComponentProps> = () => {
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const listSkip: Array<string> = JSON.parse(localStorage.getItem(KeyLocalStorage.Skip) || '[]');
  const isSkipped = activeParty?.id ? listSkip.includes(activeParty?.id) : false;
  return activeParty ? (
    <Router
      basepath="/"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {activeParty.profile_completed || isSkipped ? (
        <>
          <DashBoardGroomContainer path="/groom">
            <WeddingPartyList path="/wedding-list" />
            <ActivityList path="/activity-list" />
            <GroomSuitList path="/suit-list" />
            <YourProfileGroom path="/your-profile" />
            <YourSuit path="/your-suit" />
          </DashBoardGroomContainer>
          <DefaultRouter default link="/groom/your-profile" />
        </>
      ) : (
        <>
          <RegisterGroom path="/wedding-party/register/*" />
          <DefaultRouter default link="/wedding-party/register/step1" />
        </>
      )}
    </Router>
  ) : null;
};

export default RouterGroom;
