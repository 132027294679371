import { api } from '@goldfishcode/modern-groom-api-sdk';
import { IActivity } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { RouteComponentProps } from '@reach/router';
import { Empty, message, Spin } from 'antd';
import { ceil } from 'lodash';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { pageSize } from '../../../constants';
import { PageValue } from '../../../model';
import { AppContext } from '../../contexts/AppContext';
import HeaderContainer from '../../ui/HeaderContainer';
import { StyledScrollIndex } from '../../ui/Styled';
import { StyledActivity } from './StyleActivity';

const ActivityList: FC<RouteComponentProps> = () => {
  const appContext = useContext(AppContext);

  const [loadMore, setLoadMore] = useState(false);
  const [activityData, setActivityData] = useState<PageValue<IActivity>>({
    data: [],
    page: 1,
    totalPage: 0,
  });

  const getList = async (page = 1) => {
    try {
      const dataList = await api.User.listActivities({
        page,
        limit: pageSize,
      });
      setActivityData({
        page,
        data: activityData.data.concat(dataList.results),
        totalPage: ceil(dataList.count / pageSize),
      });
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const onScroll = async (e) => {
    const event = e.currentTarget;
    if (loadMore) return;
    if (event.scrollHeight - 20 <= Math.round(event.clientHeight + event.scrollTop) && activityData.page < activityData.totalPage) {
      setLoadMore(true);
      await getList(activityData.page + 1);
      setLoadMore(false);
    }
  };

  const initialData = async () => {
    appContext.setLoading(true);
    await getList();
    appContext.setLoading(false);
  };

  const fromNow = (date: string) => {
    if (moment(date).isValid()) {
      moment.updateLocale('en', {
        relativeTime: {
          d: '1 day',
        },
      });
      return moment(date).fromNow();
    }
    return 'N/A';
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <StyledActivity>
      <HeaderContainer title="Activity" />
      <span className="header">Latest Activity</span>
      <StyledScrollIndex className="activity-suit" onScroll={onScroll}>
        {activityData.data.map((activity) => (
          <div className="wrapper" key={activity.id}>
            <div className="contain">
              <span className="name">{activity.name}</span>
              <span className="title">{activity.content}</span>
              <span className="time">{fromNow(activity.created || activity.modified)}</span>
            </div>
          </div>
        ))}
        {activityData.data.length === 0 && (
          <div className="no-data">
            <Empty />
          </div>
        )}
        {loadMore && (
          <div className="spin">
            <Spin size="small" />
          </div>
        )}
      </StyledScrollIndex>
    </StyledActivity>
  );
};

export default ActivityList;
