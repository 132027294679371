import { api } from '@goldfishcode/modern-groom-api-sdk';
import { navigate, useLocation } from '@reach/router';
import { Button, Dropdown, Menu, message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/logo.png';
import { definedGroomRouter, definedGroomsmanRouter } from '../constants';
import { KeyLocalStorage, UserRole } from '../enum';
import { RootState } from '../reducers';
import { setAlertConfig } from '../reducers/asset/action';
import { setReadingStatus } from '../reducers/user/action';
import { StyleHamburgerDashBoard } from './dashboard/StyleDashboard';
import useAuth from './hooks/useAuth';
import { StyleHeader } from './StyleLayout';
import BaseSelectOption, { DataSelectOption } from './ui/SelectOption';
import { StyleButtonOrange } from './ui/Styled';

const Header: FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => {
  const [keyActive, setKeyActive] = useState<string[]>(['']);
  const listParty = useSelector((state: RootState) => state.userReducer.listParty);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const readingStatus = useSelector((state: RootState) => state.userReducer.readingStatus);
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);
  const listSkip: Array<string> = JSON.parse(localStorage.getItem(KeyLocalStorage.Skip) || '[]');
  const isSkipped = activeParty?.id ? listSkip.includes(activeParty?.id) : false;
  const [arrayMenuParty, setArrayMenuParty] = useState<Array<DataSelectOption>>([]);
  const location = useLocation();
  const { forceLogout } = useAuth();
  const dispatch = useDispatch();

  const getReadingStatus = async () => {
    try {
      const res = await api.User.checkMarkRead();
      dispatch(setReadingStatus(res));
    } catch (error) {
      message.error((error as Error).message);
    }
  };
  const handleClick = async (e: any) => {
    if (e.key === definedGroomsmanRouter.logout) {
      await forceLogout();
      return;
    }
    navigate(e.key);
    setKeyActive([e.key]);
  };
  const switchParty = async (partyId) => {
    try {
      await api.User.activeParty(partyId);
      window.location.pathname = '/';
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const alertOrderPlaced = (title) => {
    dispatch(
      setAlertConfig({
        type: 'info',
        visible: true,
        title: <h3 className="font-bold text-lg">{title}</h3>,
        content: 'Your order has already been placed and in the event you need to make any changes please use contact us button below!',
        okText: 'OK',
        onOk: async () => {
          dispatch(setAlertConfig({ visible: false }));
        },
      }),
    );
  };

  useEffect(() => {
    setKeyActive([location.pathname]);
  }, [location.pathname]);

  useEffect(() => {
    if (listParty.length > 0) {
      setArrayMenuParty(listParty.map((item) => ({ id: item.wedding_party, name: `${item.party_name}` })));
    }
  }, [listParty]);

  useEffect(() => {
    if (!activeParty) return;
    if (activeParty?.user_role?.key === UserRole.GROOM) {
      getReadingStatus();
    }
  }, [activeParty]);
  const MenuList = (
    <Menu onClick={handleClick} selectedKeys={keyActive} mode="inline">
      {(activeParty?.profile_completed || isSkipped) &&
        (activeParty?.user_role?.key === UserRole.GROOM ? (
          <>
            <Menu.Item key={definedGroomRouter.yourProfile}>Your Profile</Menu.Item>
            {suitDetail && suitDetail.suit_name && <Menu.Item key={definedGroomRouter.yourSuit}>Your Suit</Menu.Item>}
            <Menu.Item key={definedGroomRouter.suit}>
              Party's Suits {readingStatus.suit_unread >= 1 ? <div className="dot" /> : ''}
            </Menu.Item>
            <Menu.Item key={definedGroomRouter.weddingPartyList}>
              Wedding Party {readingStatus.groomsmen_unread >= 1 ? <div className="dot" /> : ''}
            </Menu.Item>
            <Menu.Item key={definedGroomRouter.activityList}>
              Activity {readingStatus.activities_unread >= 1 ? <div className="dot" /> : ''}
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item key={definedGroomsmanRouter.yourProfile}>Your Profile</Menu.Item>
            {suitDetail && suitDetail.suit_name && <Menu.Item key={definedGroomsmanRouter.yourSuit}>Your Suit</Menu.Item>}
          </>
        ))}
      <Menu.Item key={definedGroomsmanRouter.logout} className="btn-logout">
        Logout
      </Menu.Item>
    </Menu>
  );
  const HamburgerMenu = <StyleHamburgerDashBoard>{MenuList}</StyleHamburgerDashBoard>;
  return (
    <StyleHeader className={className}>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="children">{children}</div>
      <div className="wedding-party">
        <p>Wedding Party</p>
        <BaseSelectOption
          data={arrayMenuParty}
          dataSelect={activeParty?.wedding_party}
          onChange={(id) => {
            const index = listParty.findIndex((party) => party.wedding_party === id);
            if (index !== -1 && listParty[index].order_expired) {
              alertOrderPlaced(listParty[index].party_name);
              return;
            }
            switchParty(id);
          }}
        />
      </div>
      <hr className="hr-tag" />
      <StyleButtonOrange onClick={forceLogout} width={135} className="btn-logout">
        Logout
      </StyleButtonOrange>
      <StyleHamburgerDashBoard className={className}>
        <Dropdown overlay={HamburgerMenu} trigger={['click']} placement="bottomRight">
          <Button shape="circle">
            <FaBars />
          </Button>
        </Dropdown>
      </StyleHamburgerDashBoard>
    </StyleHeader>
  );
};

export default Header;
