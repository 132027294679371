import { useEffect, useState } from 'react';

export enum MessageKey {
  FORCE_LOGOUT = '@BROADCAST_CHANNEL/FORCE_LOGOUT',
}
export const useBroadcastChannel = () => {
  const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel>();
  const receiveMessage = (e: MessageEvent) => {
    if (e.data.key === MessageKey.FORCE_LOGOUT) {
      window.location.reload();
    }
  };
  useEffect(() => {
    const channel = new BroadcastChannel('my-channel');
    setBroadcastChannel(channel);
    channel.addEventListener('message', receiveMessage);
    return () => {
      channel.removeEventListener('message', receiveMessage);
    };
  }, []);

  return {
    broadcastChannel,
  };
};
