import { api } from '@goldfishcode/modern-groom-api-sdk';
import { ISuit } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/models';
import { RouteComponentProps } from '@reach/router';
import { message, Empty } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapResultGetList, pageSize } from '../../../constants';
import { RootState } from '../../../reducers';
import { setListSuit, setSuitDetail } from '../../../reducers/suit/action';
import { AppContext } from '../../contexts/AppContext';
import Payment from '../../payment/Payment';
import HeaderContainer from '../../ui/HeaderContainer';
import ListSuits from './ListSuits';
import { StyledPartySuit } from './StyleSuitList';

import SuitInformation from './SuitInformation';

const GroomSuitList: FC<RouteComponentProps> = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [idSuitPay, setIdSuitPay] = useState<string>();

  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);
  const listSuit = useSelector((state: RootState) => state.suitReducer.listSuit);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const disableAction = !activeParty?.active;

  const [suitSelected, setSuitSelected] = useState<ISuit | undefined>();

  const appContext = useContext(AppContext);
  const dispatch = useDispatch();

  const onPaySuccess = async () => {
    if (!idSuitPay) return;
    try {
      appContext.setLoading(true);
      const getSuitDetail = await api.Suit.getSuit(idSuitPay);
      if (getSuitDetail.id === suitDetail?.id) {
        dispatch(setSuitDetail(getSuitDetail));
      }
      if (getSuitDetail.id === suitSelected?.id) {
        setSuitSelected(getSuitDetail);
      }
      const indexSuitPayment = listSuit.results.findIndex((f) => f.id === idSuitPay);
      if (indexSuitPayment !== -1) {
        const suitClone = { ...listSuit };

        suitClone.results[indexSuitPayment] = getSuitDetail;
        dispatch(setListSuit(suitClone));
      }
      setVisibleModal(false);
      setIdSuitPay(undefined);
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setLoading(false);
    }
  };

  const paySuit = (id: string) => {
    setIdSuitPay(id);
    setVisibleModal(true);
  };

  const getListSuit = async (page = 1) => {
    appContext.setLoading(true);
    try {
      const listSuitResult = await api.Suit.listSuits({
        page,
        limit: pageSize,
      });
      if (!suitSelected) {
        setSuitSelected(listSuitResult.results[0]);
      }
      if (suitDetail) {
        const indexOfYourSuit = listSuitResult.results.findIndex((suit) => suit.id === suitDetail.id);
        listSuitResult.results.splice(indexOfYourSuit, 1);
      }
      listSuitResult.results = listSuit.results.concat(listSuitResult.results);
      const suits = mapResultGetList(listSuitResult, page);
      dispatch(setListSuit(suits));
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setLoading(false);
    }
  };
  const getSuitForMe = async () => {
    appContext.setLoading(true);
    try {
      if (activeParty) {
        const result = await api.Suit.mySuit();
        dispatch(setSuitDetail(result));
        // setSuitSelected(result);
      }
    } catch (error) {
      message.error((error as Error).message);
    }
    appContext.setLoading(false);
  };

  const updateSuitDetail: <T extends keyof ISuit>(fieldName: T, value: ISuit[T]) => void = (fieldName, value) => {
    const partySuits = { ...listSuit };
    const index = listSuit.results.findIndex((item) => item.id === suitSelected?.id);
    if (index !== -1 && partySuits.results[index][fieldName]) {
      partySuits.results[index][fieldName] = value;
      dispatch(setListSuit(partySuits));
    }
  };

  useEffect(() => {
    if (!suitDetail) {
      getSuitForMe();
    }
  }, [suitDetail]);
  useEffect(() => {
    if (suitDetail) {
      getListSuit();
    }
  }, [suitDetail]);

  return (
    <StyledPartySuit>
      {listSuit.results.length > 0 ? (
        <>
          <HeaderContainer title="Suit" />
          <div className="suit">
            <div className="party-suit">
              <ListSuits
                getMoreSuits={() => getListSuit(listSuit.page + 1)}
                payment={(id) => paySuit(id)}
                onSelectSuit={(suit) => setSuitSelected(suit)}
                suitSelected={suitSelected}
                disableAction={disableAction}
              />
            </div>
            <div className="your-suit">
              <SuitInformation
                payment={(id) => paySuit(id)}
                suitDetail={suitSelected}
                viewYourSuit={(suit) => {
                  setSuitSelected(suit);
                }}
                disableAction={disableAction}
                updateSuitDetail={(fieldName: keyof ISuit, value: string) => updateSuitDetail(fieldName, value)}
              />
            </div>
          </div>
          {idSuitPay && (
            <Payment
              visible={visibleModal}
              onClose={() => setVisibleModal(false)}
              suit={suitSelected}
              onSuccess={() => {
                onPaySuccess();
              }}
            />
          )}
        </>
      ) : (
        <div className="no-data">
          <Empty />
        </div>
      )}
    </StyledPartySuit>
  );
};

export default GroomSuitList;
