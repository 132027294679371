import React, { FC } from 'react';
import { StyleButtonOrange } from '../../ui/Styled';
import { StyleSuitPaymentConfirm } from './StyleSuitPaymentConfirm';

interface SuitPaymentConfirmProps {
  visible: boolean;
  onClose(): void;
}
const SuitPaymentConfirm: FC<SuitPaymentConfirmProps> = (props) => {
  const { visible, onClose } = props;
  return (
    <StyleSuitPaymentConfirm centered closable={false} footer={null} visible={visible} width={514}>
      <h3 className="title">Order Confirmation</h3>
      <section className="description">
        <span className="text">
          Thank you for your payment.
          <br /> You will be notified once your package has been shipped.
        </span>
      </section>
      <section className="action">
        <StyleButtonOrange onClick={onClose} width={135}>
          Done
        </StyleButtonOrange>
      </section>
    </StyleSuitPaymentConfirm>
  );
};

export default SuitPaymentConfirm;
