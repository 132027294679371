import React, { FC } from 'react';
import { StyleDotSteps } from './Styled';

interface DotStepProps {
  active?: boolean;
}
const DotStep: FC<DotStepProps> = ({ active }) => {
  return <StyleDotSteps>{active ? <span className="dot" /> : null}</StyleDotSteps>;
};

export default DotStep;
