import { api } from '@goldfishcode/modern-groom-api-sdk';
import { InviteQueryParams } from 'components/layout-login/LayoutLogin';
import { checkRefreshToken } from 'controllers/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setAuth } from 'reducers/auth/action';
import { KeyLocalStorage } from 'enum';
import { getParamsUrl } from '../../util';
import { useBroadcastChannel, MessageKey } from './useBroadcastChannel';

const useAuth = () => {
  const dispatch = useDispatch();
  const { broadcastChannel } = useBroadcastChannel();
  const forceLogout = useCallback(async () => {
    try {
      localStorage.removeItem(KeyLocalStorage.Skip);
      await api.Auth.logout();
      broadcastChannel?.postMessage({
        key: MessageKey.FORCE_LOGOUT,
      });
      dispatch({ type: 'USER_LOGOUT' });
    } catch (error) {
      // no action
    }
  }, [broadcastChannel]);

  const checkAuth = useCallback(async () => {
    const { pathname } = window.location;
    const queryParams = getParamsUrl<InviteQueryParams>();
    if (pathname === '/login' && queryParams && queryParams.otp) {
      try {
        await api.Auth.logout();
        dispatch(setAuth(false));
      } catch (error) {
        // do not thing
      }
    } else {
      const isAuth = await api.Auth.isAuthenticated();
      await checkRefreshToken(forceLogout);
      dispatch(setAuth(isAuth));
    }
  }, []);

  return {
    forceLogout,
    checkAuth,
  };
};

export default useAuth;
