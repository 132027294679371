import { api } from '@goldfishcode/modern-groom-api-sdk';
import { KeyLocalStorage } from 'enum';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

let timeout: NodeJS.Timeout | null = null;
export const checkRefreshToken = async (logOutCallback: () => void) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  const authToken = await api.Auth.getAuthToken();

  if (authToken) {
    const { exp } = jwtDecode<{ exp: number }>(authToken.access_token);
    const currentTime = +moment();
    const timeRemaining = exp * 1000 - currentTime;
    const storeIsRemember = localStorage.getItem(KeyLocalStorage.RememberMe);
    const isRemember = storeIsRemember ? JSON.parse(storeIsRemember)?.value : false;
    if (!isRemember) {
      if (timeRemaining < 0) {
        logOutCallback();
        return;
      }
      setTimeout(() => {
        logOutCallback();
      }, timeRemaining);
    } else {
      if (timeRemaining < 0 && !isRemember) {
        logOutCallback();
        return;
      }
      // if exp less than 10min trigger refresh
      if (timeRemaining < 10 * 60 * 1000) {
        try {
          await api.Auth.refreshToken();
          await checkRefreshToken(logOutCallback);
        } catch (error) {
          logOutCallback();
        }
      }
      // trigger refresh token before 10min
      else {
        timeout = setTimeout(async () => {
          await checkRefreshToken(logOutCallback);
        }, timeRemaining - 10 * 60 * 1000);
      }
    }
  } else {
    // no action
  }
};
