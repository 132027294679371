import { api } from '@goldfishcode/modern-groom-api-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, message } from 'antd';
import React, { FC, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AppContext } from '../contexts/AppContext';
import BaseInput from '../ui/Input';
import { StyleFooterModal, StyleResetPasswordStep2 } from './StyleResetPassword';

interface ResetPasswordContentProps {
  onGetData: (email: string) => void;
  handleCancel: () => void;
}
interface SubmitProps {
  email: string;
}
const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Email is invalid'),
});
const ResetPasswordContent: FC<ResetPasswordContentProps> = (props) => {
  const appContext = useContext(AppContext);
  const { control, formState, handleSubmit, setValue, clearErrors } = useForm<SubmitProps>({
    resolver: yupResolver(schema),
  });
  const { onGetData, handleCancel } = props;
  const onReset = async (email: string) => {
    try {
      appContext.setLoading(true);
      const resp = await api.Auth.resetPassword(email);
      if (resp?.detail) {
        onGetData(email);
      }
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setLoading(false);
    }
  };
  const onSubmit = handleSubmit((data: SubmitProps) => {
    const { email } = data;
    onReset(email);
  });
  const onHandleCancel = () => {
    setValue('email', '');
    clearErrors('email');
    handleCancel();
  };
  return (
    <Form layout="vertical">
      <StyleResetPasswordStep2>
        {/* <span className="email-address">Email Address</span> <br /> */}
        <Form.Item validateStatus={formState.errors.email ? 'error' : ''} help={formState.errors.email?.message}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => <BaseInput onChange={onChange} value={value} placeholder="Email Address" className="email" />}
          />
        </Form.Item>
      </StyleResetPasswordStep2>
      <StyleFooterModal className="footer">
        <Button type="primary" className="cancel" onClick={() => onHandleCancel()}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => onSubmit()} className="conrfirm">
          Confirm
        </Button>
      </StyleFooterModal>
    </Form>
  );
};

export default ResetPasswordContent;
