import { ActionBase } from '..';
import { SET_ACTIVE_WEDDING, SET_USER, SET_LIST_PARTY, SET_READING_STATUS } from './action';
import { UserState } from './model';

const initialReducer: UserState = {
  userProfile: null,
  listParty: [],
  activeParty: undefined,
  readingStatus: {
    suit_unread: 0,
    groomsmen_unread: 0,
    activities_unread: 0,
  },
};

const userReducer = (state = initialReducer, action: ActionBase): UserState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        userProfile: action.payload,
      };
    case SET_ACTIVE_WEDDING:
      return {
        ...state,
        activeParty: action.payload,
      };
    case SET_LIST_PARTY:
      return {
        ...state,
        listParty: action.payload,
      };
    case SET_READING_STATUS:
      return {
        ...state,
        readingStatus: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
