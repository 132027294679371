import { useLocation } from '@reach/router';
import React, { FC, useEffect, useRef } from 'react';

interface OnRouteChangeWorkerProps {
  action(): void;
}

const OnRouteChangeWorker: FC<OnRouteChangeWorkerProps> = (props) => {
  const locationRef = useRef('');
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname !== locationRef.current) {
      locationRef.current = pathname;
      props.action();
    }
  });

  return <div />;
};

export default OnRouteChangeWorker;
