import React, { FC } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { v1 } from 'uuid';
import { RootState } from '../../reducers';
import { StyleMessage } from './StyleNeedHelp';

interface Message {
  id: string;
  text: string;
  time: Date;
}
const MessageLayout: FC = () => {
  const useInfo = useSelector((state: RootState) => state.userReducer.userProfile);

  const messFake: Message[] = [
    {
      id: '111',
      time: moment().subtract(5, 'm').toDate(),
      text: 'Thank you for contacting Modern Groom! How can we assist you today?',
    },
    {
      id: useInfo?.id || '111',
      time: moment().subtract(3, 'm').toDate(),
      text: 'Hello! I was wondering if there is a way to check and see if my groomsmen have finished paying for their suits?',
    },
    {
      id: '111',
      time: moment().subtract(1, 'm').toDate(),
      text:
        // eslint-disable-next-line max-len
        'Yes there is! If you go to your Groomsmen dashboard, you can view the status for each of your invited groomsmen. Let me know if you are able to see it.',
    },
  ];

  return (
    <StyleMessage>
      {messFake.map((f) => (
        <div key={v1()} className={`message ${f.id === useInfo?.id ? 'me' : 'not-me'}`}>
          <div className="text">{f.text}</div>
          <div className="time">{moment(f.time).fromNow()}</div>
        </div>
      ))}
    </StyleMessage>
  );
};

export default MessageLayout;
