import { api } from '@goldfishcode/modern-groom-api-sdk';
import { Image, message } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import yourSuit from '../../../assets/images/you-suit.png';
import { uploadMultiImage } from '../../../controllers/file';
import { RootState } from '../../../reducers';
import { AppContext } from '../../contexts/AppContext';
import { StyleButtonOrange } from '../../ui/Styled';
import UploadPhoto from '../list/modal/UploadImageSuccessful/UploadPhoto';
import SuitBuilder from '../list/SuitBuilder';
import { StyledSuitRental } from './Styles';
import { Currency } from '../../../constants';

interface GroomsmanSuitRentalProp {
  getSuitForMe(): void;
  disableAction?: boolean;
}

const GroomsmanSuitRental: FC<GroomsmanSuitRentalProp> = (props) => {
  const { getSuitForMe, disableAction } = props;
  const fileRef = useRef<HTMLInputElement>(null);
  const [isUploadPhotoSuccess, setUploadPhoto] = useState(false);
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);

  const appContext = useContext(AppContext);

  const onSelectFile = async (files: FileList | null) => {
    if (suitDetail && files !== null) {
      if (files.length < 1) return;
      try {
        appContext.setLoading(true);
        const filesList: File[] = [];
        for (let index = 0; index < files.length; index++) {
          filesList.push(files[index]);
        }
        const responseFileUpload = await uploadMultiImage(filesList);
        const links = responseFileUpload.map((f) => f.url || f.file_path);

        await api.Suit.createUserSuitSwearing({
          image_urls: links,
          suit: suitDetail.id,
        });
        setUploadPhoto(true);
        getSuitForMe();
      } catch (error) {
        message.error((error as Error).message);
      } finally {
        if (fileRef.current && fileRef.current.value) {
          fileRef.current.value = '';
        }
        appContext.setLoading(false);
      }
    }
  };

  const getFormatDay = (day: string, defaultValue = '') => {
    if (moment(day).isValid()) {
      return moment(day).format('MM/DD/YY');
    }
    return defaultValue;
  };

  return (
    <StyledSuitRental>
      <span className="title">Your Order Details</span>
      {suitDetail && (
        <div className="suit-rental">
          <div className="title-rental">
            <div className="title-content">
              <span>Color: </span>
              <p>{suitDetail.suit_color_obj?.name}</p>
            </div>
            <div className="title-content">
              <span>Tie:</span>
              <p>
                {suitDetail.tie_type_obj?.price === 0
                  ? 'Opted out'
                  : `${suitDetail.tie_type_obj?.name} (${suitDetail.tie_color_obj?.name})`}
              </p>
            </div>
            <div className="title-content">
              <span>Shirt: </span>
              <p>{suitDetail.shirt_color_obj?.price === 0 ? 'Opted out' : suitDetail.shirt_color_obj?.name}</p>
            </div>
            <div className="title-content">
              <span>Shoes: </span>
              <p>{suitDetail.more_item_obj?.price === 0 ? 'Opted out' : suitDetail.more_item_obj?.name}</p>
            </div>
          </div>
          <div className="image-rental">
            {suitDetail?.suit_color_obj?.part ? (
              <Image.PreviewGroup>
                <SuitBuilder suit={suitDetail} />
              </Image.PreviewGroup>
            ) : (
              <img src={suitDetail?.suit_photo || yourSuit} alt="avatar" />
            )}
          </div>
          <section className="total-cost">
            <span className="label">Total:</span>{' '}
            <strong className="date">{Currency[suitDetail?.order?.currency || 'usd'].symbol + suitDetail?.order?.total}</strong>
          </section>
          <section className="date-rental">
            <div className="payment-date">
              <span className="contents">Payment sent on</span>{' '}
              <strong className="date">{getFormatDay(suitDetail?.payment_sent || '', '-')}</strong>
            </div>
            {suitDetail.shipped_date && (
              <div className="shipped-date">
                <span className="contents">Suit shipped on</span>{' '}
                <strong className="date">{getFormatDay(suitDetail?.shipped_date || '', '-')}</strong>
              </div>
            )}
          </section>
          {suitDetail.shipped_date && (
            <>
              <section className="action">
                <StyleButtonOrange width={179} onClick={() => fileRef.current && fileRef.current.click()} disabled={disableAction}>
                  Upload Photos
                </StyleButtonOrange>
                <span className="total-file-select">{suitDetail?.total_images_uploaded} files selected</span>
                <input
                  ref={fileRef}
                  multiple
                  name="fileCheck"
                  accept="image/*"
                  onChange={(e) => onSelectFile(e.target.files)}
                  type="file"
                  hidden
                  disabled={disableAction}
                />
              </section>
              <UploadPhoto visible={isUploadPhotoSuccess} onClose={() => setUploadPhoto(false)} />
            </>
          )}
        </div>
      )}
    </StyledSuitRental>
  );
};

export default GroomsmanSuitRental;
