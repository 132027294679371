import { Select } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { colors } from '../../styles/definedStyle';
import { StyleSelectOption } from './Styled';

export interface DataSelectOption {
  id: string;
  name: string;
}

export interface BaseSelectOptionProps {
  dataSelect?: string;
  onChange(value: string): void;
  data: Array<DataSelectOption | string>;
  placeholder?: string;
  disabled?: boolean;
}

const BaseSelectOption: FC<BaseSelectOptionProps> = (props) => {
  const { dataSelect, data, onChange, placeholder, disabled } = props;
  const [value, setValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (dataSelect && data.length > 0) {
      setValue(dataSelect);
    } else {
      setValue('');
    }
  }, [dataSelect, data]);

  return (
    <StyleSelectOption
      suffixIcon={<BiChevronDown color={colors.black} size={25} />}
      placeholder={placeholder}
      value={value}
      onChange={(v) => onChange((v && v.toString()) || '')}
      getPopupContainer={(trigger) => trigger.parentElement}
      className={value ? '' : 'empty-select'}
      disabled={disabled}
    >
      {placeholder && (
        <Select.Option value="" className="placeholder-select">
          {placeholder}
        </Select.Option>
      )}
      {data.map((item) =>
        typeof item === 'string' ? (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ) : (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ),
      )}
    </StyleSelectOption>
  );
};

export default BaseSelectOption;
