import { CalendarProps } from 'antd/lib/calendar/generateCalendar';
import moment, { Moment } from 'moment';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import React, { FC, useState } from 'react';
import { Button } from 'antd';
import { StyleCalendar } from './Styled';

interface BaseCalendarProps extends CalendarProps<Moment> {
  daySelected: Moment;
  onChangeDaySelected(v: Moment): void;
}
const BaseCalendar: FC<BaseCalendarProps> = (props) => {
  const { daySelected, onChangeDaySelected } = props;
  moment.updateLocale('en', {
    weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  });
  const [monthSelected, setMonthSelected] = useState<Moment>(moment(daySelected)) || moment(new Date());

  const dateFullCellRender = (date: Moment) => {
    if (monthSelected && moment(monthSelected).format('MM') === moment(date).format('MM')) {
      const checkFormat = 'DD/MM/yyyy';
      // day select

      if (moment(daySelected).format(checkFormat) === date.format(checkFormat)) {
        return (
          <Button type="text" className="day day-selected">
            {moment(date).format('D')}
          </Button>
        );
      }
      // current day
      if (moment().format(checkFormat) === date.format(checkFormat)) {
        return (
          <Button className="day current-day" type="text" onClick={() => onChangeDaySelected(date)}>
            {moment(date).format('D')}
          </Button>
        );
      }
      return (
        <Button type="text" onClick={() => onChangeDaySelected(date)} className="day">
          {moment(date).format('D')}
        </Button>
      );
    }
    return null;
  };
  const headerRender = (value: Moment, onChange: (date: Moment) => void) => {
    const current = value;
    return (
      <div className="calendar-custom-header">
        <FaAngleLeft className="icon" onClick={() => onChange(moment(current.subtract(1, 'months')))} />
        <span className="text">{moment(current).format('MMMM yyyy')}</span>
        <FaAngleRight className="icon" onClick={() => onChange(moment(current).add(1, 'months'))} />
      </div>
    );
  };
  return (
    <StyleCalendar
      value={monthSelected}
      onChange={setMonthSelected}
      defaultValue={moment(new Date())}
      headerRender={({ value, onChange }) => headerRender(value, onChange)}
      dateFullCellRender={(date) => dateFullCellRender(date)}
      fullscreen={false}
      mode="month"
      {...props}
    />
  );
};

export default BaseCalendar;
