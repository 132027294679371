/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Form, Image, ModalProps, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { FC, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import DefaultImage from 'assets/images/default-image.jpeg';
import { StyleButtonGrey, StyleButtonOrange } from '../../../../ui/Styled';
import { StyleUploadPhoto } from './styles';

interface UploadPhotoProps extends ModalProps {
  onOk: (data: any) => void;
  facingImageUrl?: string;
  profileImageUrl?: string;
  description?: string;
}
interface RegisterGroomsmanSchema {
  describeBuild: string;
  facingImage?: any;
  profileImage?: any;
}
const UploadPhoto: FC<UploadPhotoProps> = (props) => {
  const { visible, onCancel, onOk, facingImageUrl, profileImageUrl, description } = props;
  const [previewFile, setPreviewFile] = useState<{ facingImage: string; profileImage: string }>({
    facingImage: '',
    profileImage: '',
  });
  const facingImageRef = useRef<HTMLInputElement | null>(null);
  const profileImageRef = useRef<HTMLInputElement | null>(null);
  const formSchema = yup.object().shape<RegisterGroomsmanSchema>({
    describeBuild: yup
      .string()
      .strict(false)
      .transform((v) => (v === null ? '' : v))
      .trim()
      .required('This field is required.'),
    ...(!facingImageUrl
      ? {
          facingImage: yup
            .mixed()
            .transform((v) => (!v ? undefined : v))
            .required('This field is required.'),
        }
      : {}),
    ...(!profileImageUrl
      ? {
          profileImage: yup
            .mixed()
            .transform((v) => (!v ? undefined : v))
            .required('This field is required.'),
        }
      : {}),
  });
  const { handleSubmit, control, setValue, clearErrors, formState, errors, getValues } = useForm<RegisterGroomsmanSchema>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit(() => {
    const data = getValues();
    onOk({
      describeBuild: data.describeBuild,
      facingImage: data.facingImage || facingImageUrl,
      profileImage: data.profileImage || profileImageUrl,
    });
  });

  const onSelectFile = (key: keyof RegisterGroomsmanSchema, files: FileList | null) => {
    if (files && files.length > 0) {
      const allowedExtension = ['jpg', 'png', 'jpeg'];
      const fileExtension = files[0].name.split('.').pop()?.toLowerCase();
      if (!fileExtension || !allowedExtension.includes(fileExtension)) {
        message.error(`The selected file is not a valid image type. Please select a file in ${allowedExtension.join(', ')} format.`);
        return;
      }
      setValue(key, files[0]);
      const reader = new FileReader();
      reader.onload = (_file) => {
        setPreviewFile((prev) => {
          return {
            ...prev,
            [key]: reader.result,
          };
        });
      };
      reader.readAsDataURL(files[0]);
      clearErrors(key);
    }
  };
  return (
    <StyleUploadPhoto width={514} visible={visible} centered footer={null} onCancel={onCancel}>
      <h3 className="title">Upload photos</h3>
      <Form layout="vertical" className="form">
        <div className="upload-img">
          <p className="header-row">
            Upload a full length forward facing photo of yourself <span className="asterisk">*</span>
          </p>
          <Image src={previewFile.facingImage || facingImageUrl || DefaultImage} alt="" className="image" width="300" height="300" />
          <Form.Item validateStatus={formState.errors.facingImage ? 'error' : ''} help={formState.errors.facingImage?.message}>
            <StyleButtonGrey width={135} className="choose-file" onClick={() => facingImageRef.current && facingImageRef.current.click()}>
              {facingImageRef.current?.files![0]?.name || 'Upload Image'}
            </StyleButtonGrey>
            <span className="footer-row">Do us a favor and please be clothed</span>
          </Form.Item>

          <Controller
            name="facingImage"
            control={control}
            defaultValue=""
            render={() => (
              <input
                ref={facingImageRef}
                name="facingImage"
                accept="image/*"
                onChange={(e) => onSelectFile('facingImage', e.target.files)}
                type="file"
                hidden
              />
            )}
          />
        </div>
        <Divider className="divider" />
        <div className="upload-img">
          <p className="header-row">
            Upload a full length side/profile photo of yourself <span className="asterisk">*</span>
          </p>
          <Image src={previewFile.profileImage || profileImageUrl || DefaultImage} alt="" className="image" width="300" height="300" />
          <Form.Item validateStatus={errors.profileImage ? 'error' : ''} help={errors.profileImage?.message}>
            <StyleButtonGrey
              width={135}
              className="choose-file"
              onClick={() => profileImageRef.current && profileImageRef.current!.click()}
            >
              {profileImageRef.current?.files![0]?.name || 'Upload Image'}
            </StyleButtonGrey>
            <span className="footer-row">Do us a favor and please be clothed</span>
          </Form.Item>
          <Controller
            name="profileImage"
            control={control}
            defaultValue=""
            render={() => (
              <input
                ref={profileImageRef}
                name="profileImage"
                accept="image/*"
                onChange={(e) => onSelectFile('profileImage', e.target.files)}
                type="file"
                hidden
              />
            )}
          />
        </div>
        <Divider className="divider" />
        <div className="describe-build">
          <p className="header-row">
            Describe your Build Type: <span className="asterisk">*</span>
          </p>
          <Form.Item validateStatus={formState.errors.describeBuild ? 'error' : ''} help={formState.errors.describeBuild?.message}>
            <Controller
              name="describeBuild"
              control={control}
              defaultValue={description}
              render={({ value, onChange }) => (
                <>
                  <TextArea value={value} onChange={(e) => onChange(e.target.value)} rows={4} placeholder="Type here..." />
                  <h5 className="footer-row">
                    Ex: long arm span, broad shoulders, tall/lean, average build, muscular/big thighs, long/short torso, long/short legs,
                    carries weight in midsection, athletic build, etc.
                  </h5>
                </>
              )}
            />
          </Form.Item>
        </div>
        <StyleButtonOrange width={135} onClick={() => onSubmit()} className="btn-done">
          Done
        </StyleButtonOrange>
      </Form>
      <Form layout="vertical" className="form" />
    </StyleUploadPhoto>
  );
};

export default UploadPhoto;
