import styled from '@emotion/styled';
import { Modal } from 'antd';
import tw from 'twin.macro';

const StyleProfileUpdate = styled(Modal)`
  .ant-modal-content {
    ${tw`font-Poppins-Semi-Bold text-blueDark! text-center`}
    border-radius: 15px;
    .title {
      font-size: 24px;
    }
    .text {
      margin-top: 30px;
    }
    .action {
      margin-top: 31px;
      margin-bottom: 22px;
    }
  }
`;

export { StyleProfileUpdate };
