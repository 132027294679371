import { Collapse } from 'antd';
import React, { FC, useMemo } from 'react';
import { BiMinus } from 'react-icons/bi';
import { FiSend } from 'react-icons/fi';
import { colors } from '../../styles/definedStyle';
import BaseInput from '../ui/Input';
import MessageLayout from './MessageLayout';
import { StyleChat } from './StyleNeedHelp';

interface ChatProps {
  isShow: boolean;
  onHide(): void;
  className?: string;
}

const Chat: FC<ChatProps> = ({ isShow, onHide, className }) => {
  const active = useMemo(() => {
    if (isShow) return 1;
    return 0;
  }, [isShow]);
  const widthContent = (): number => {
    const w = window && window.innerHeight - 150;
    return w > 620 ? 620 : w;
  };
  return (
    <StyleChat widthContent={widthContent()} className={className}>
      <Collapse activeKey={active}>
        <Collapse.Panel header={null} key="1" showArrow={false}>
          <div className="chat-content">
            <div className="head">
              <div className="title">Help Chat</div>
              <button type="button" onClick={() => onHide()}>
                <BiMinus />
              </button>
            </div>
            <div className="chat">
              <div className="mess">
                <MessageLayout />
              </div>
              <div className="input">
                <BaseInput placeholder="Enter Message" suffix={<FiSend color={colors.greyback} size={25} />} width="100%" />
              </div>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </StyleChat>
  );
};

export default Chat;
