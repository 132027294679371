import { ISuit } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/models';
import { ActionBase, IListPagination } from '..';

const BASE_ACTION = '@suit';

const SET_LIST_SUIT = `${BASE_ACTION}/SET_LIST_SUIT`;
const setListSuit = (payload: IListPagination<ISuit>): ActionBase => ({ payload, type: SET_LIST_SUIT });

const SET_SUIT_DETAIL = `${BASE_ACTION}/SET_SUIT_DETAIL`;
const setSuitDetail = (payload?: ISuit): ActionBase => ({ type: SET_SUIT_DETAIL, payload });

export { SET_LIST_SUIT, setListSuit, SET_SUIT_DETAIL, setSuitDetail };
