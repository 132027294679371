import { api } from '@goldfishcode/modern-groom-api-sdk';
import { IWeddingPartyMember } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { RouteComponentProps } from '@reach/router';
import { Button, Empty, Modal, Spin, message } from 'antd';
import { ceil } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FaBell } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { pageSize } from '../../../constants';
import { RootState } from '../../../reducers';
import { colors } from '../../../styles/definedStyle';
import { AppContext } from '../../contexts/AppContext';
import GroomsmenItem from '../../ui/GroomsmenItem';
import HeaderContainer from '../../ui/HeaderContainer';
import RegisterGroomStep3Form, { RegisterGroomStep3FormSchema } from '../register-groom/step3/RegisterGroomStep3Form';
import { StyleWeddingPartyList } from './StyleWeddingPartyList';
import './style.scss';

interface GroomsmenData {
  results: IWeddingPartyMember[];
  page: number;
  totalPage: number;
}

const WeddingPartyList: FC<RouteComponentProps> = () => {
  const appContext = useContext(AppContext);
  const currentParty = useSelector((state: RootState) => state.userReducer.activeParty);

  const [loadMore, setLoadMore] = useState(false);
  const [groomsmenData, setGroomsmenData] = useState<GroomsmenData>({
    results: [],
    page: 1,
    totalPage: 0,
  });
  const disableAction = !currentParty?.active;
  const disableRemindAll = disableAction || groomsmenData.results.length === 0;

  const [modalRefs, setModalRefs] = useState<
    Array<{
      destroy: () => void;
    }>
  >([]);

  const getListGroomsmen = async (page = 1) => {
    try {
      appContext.setCallStackLoading(true);
      const dataList = await api.User.listGroomsMen({
        limit: pageSize,
        page,
      });
      const resultList = groomsmenData.results;
      if (page <= groomsmenData.page) {
        resultList.splice((page - 1) * pageSize, pageSize, ...dataList.results);
      } else {
        resultList.push(...dataList.results);
      }
      setGroomsmenData({
        page,
        results: resultList,
        totalPage: ceil(dataList.count / pageSize),
      });
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setCallStackLoading(false);
    }
  };

  const onRemove = async (email: string) => {
    const modal = Modal.confirm({
      content: 'Are you sure you want to remove this guest from your party?',
      okText: 'Remove',
      cancelText: 'Cancel',
      centered: true,
      icon: null,
      className: 'confirm-yes-no',
      bodyStyle: {
        textAlign: 'center',
      },
      cancelButtonProps: {
        style: {
          width: 90,
        },
      },
      okButtonProps: {
        style: {
          background: colors.blueDark,
          borderColor: colors.blueDark,
          width: 90,
          marginLeft: 15,
        },
      },
      onOk: async () => {
        const index = groomsmenData.results.findIndex((f) => f.member.email === email);
        if (index !== -1) {
          try {
            await api.User.removeGroomsMen(groomsmenData.results[index].member.id);
            setGroomsmenData((prev) => {
              const result = { ...prev };
              result.results.splice(index, 1);
              return result;
            });
            message.success('Removed successfully');
          } catch (error) {
            message.error((error as Error).message);
          }
        }
      },
    });
    setModalRefs((prev) => [...prev, modal]);
  };
  const initData = async () => {
    appContext.setCallStackLoading(true);
    await getListGroomsmen();
    appContext.setCallStackLoading(false);
  };

  const onAdd = async (data: RegisterGroomStep3FormSchema) => {
    try {
      appContext.setCallStackLoading(true);
      await api.User.inviteGroomsMen([
        {
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          user_role: data.role,
        },
      ]);
      await getListGroomsmen(1);
      message.success('Invited successfully');
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setCallStackLoading(false);
    }
  };

  const onScroll = async (e) => {
    const event = e.currentTarget;
    if (loadMore) return;
    if (event.scrollHeight - 20 <= Math.round(event.clientHeight + event.scrollTop) && groomsmenData.page < groomsmenData.totalPage) {
      try {
        setLoadMore(true);
        await getListGroomsmen(groomsmenData.page + 1);
        setLoadMore(false);
      } catch (error) {
        message.error((error as Error).message);
      } finally {
        appContext.setCallStackLoading(false);
      }
    }
  };

  const remindAll = async () => {
    const modal = Modal.confirm({
      className: 'confirm-yes-no',
      content: "Do you want to remind all guests who haven't submitted anything yet",
      okText: 'Remind',
      cancelText: 'Cancel',
      centered: true,
      bodyStyle: {
        textAlign: 'center',
      },
      icon: null,
      cancelButtonProps: {
        style: {
          width: 90,
        },
      },
      okButtonProps: {
        style: {
          background: colors.blueDark,
          borderColor: colors.blueDark,
          width: 90,
          marginLeft: 15,
        },
      },
      onOk: async () => {
        try {
          await api.User.remind({ party_id: currentParty?.wedding_party || '' });
          message.success('Remined Successfully!');
        } catch (error) {
          message.error((error as Error).message);
        }
      },
    });
    setModalRefs((prev) => [...prev, modal]);
  };

  const remindMember = async (email: string) => {
    const modal = Modal.confirm({
      className: 'confirm-yes-no',
      content: 'Are you sure you want to remind this guest?',
      okText: 'Remind',
      cancelText: 'Cancel',
      centered: true,
      bodyStyle: {
        textAlign: 'center',
      },
      icon: null,
      cancelButtonProps: {
        style: {
          width: 90,
        },
      },
      okButtonProps: {
        style: {
          background: colors.blueDark,
          borderColor: colors.blueDark,
          width: 90,
          marginLeft: 15,
        },
      },
      onOk: async () => {
        try {
          await api.User.remind({ email, party_id: currentParty?.wedding_party || '' });
          message.success('Remined Successfully!');
        } catch (error) {
          message.error((error as Error).message);
        }
      },
    });
    setModalRefs((prev) => [...prev, modal]);
  };

  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    return () => {
      modalRefs.forEach((ref) => {
        if (!ref || !ref.destroy) return;
        ref.destroy();
      });
    };
  }, [modalRefs]);

  return (
    <StyleWeddingPartyList>
      <HeaderContainer title="Wedding Party" />
      <section className="list-groomsmen">
        <div className="list-groomsmen-header">
          <span className="title">Current List of Groomsmen</span>
          <div className="remind-all">
            <Button onClick={() => remindAll()} disabled={disableRemindAll}>
              Remind All <FaBell />
            </Button>
          </div>
        </div>
        <section className="list" onScroll={onScroll}>
          {groomsmenData.results.map((groomsman) => (
            <GroomsmenItem
              key={groomsman.member.email}
              email={groomsman.member.email}
              firstName={groomsman.member.first_name}
              lastName={groomsman.member.last_name}
              role={groomsman.user_role.value}
              status={groomsman.status}
              onRemove={onRemove}
              remindMember={remindMember}
              isShowStatus
              isShowRemindButton={!groomsman.sent_reminder && !groomsman.profile_completed && !disableAction}
              isShowRemoveButton={groomsman.status.toLocaleLowerCase() !== 'wedding package paid in full'}
            />
          ))}
          {groomsmenData.results.length === 0 && (
            <div className="no-data">
              <Empty />
            </div>
          )}
          {loadMore && (
            <div className="spin">
              <Spin size="small" />
            </div>
          )}
        </section>
      </section>
      <section className="invite">
        <h3 className="title">Invite More of Your Wedding Party</h3>
        <RegisterGroomStep3Form onSubmit={onAdd} disableAction={disableAction} />
      </section>
    </StyleWeddingPartyList>
  );
};

export default WeddingPartyList;
