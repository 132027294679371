import { ActionBase } from '..';
import { SET_LIST_EMAIL } from './action';
import { RegisterGroomState } from './model';

const initialState: RegisterGroomState = {
  listEmailStep3: [],
};

const registerGroomReducer = (state = initialState, action: ActionBase): RegisterGroomState => {
  switch (action.type) {
    case SET_LIST_EMAIL:
      return {
        ...state,
        listEmailStep3: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default registerGroomReducer;
