/* eslint-disable prettier/prettier */
import styled from '@emotion/styled';
import { Select, Input, Radio, Calendar, Modal, Button } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import tw from 'twin.macro';
import { colors, fontFamily, shadow } from '../../styles/definedStyle';
import closeRange from '../../assets/images/closeRange.png';

interface StyleButtonProps {
  width?: number;
  height?: number;
}
const StyleButtonOrange = styled(Button)<StyleButtonProps>`
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  height: ${(props) => (props.height ? `${props.height}px` : '38px')};
  padding: 0;
  background-color: ${colors.orange};
  color: ${colors.white};
  border-width: 0px;
  cursor: pointer;
  font-family: ${fontFamily.SourceSansProRegular};
  line-height: 18px;
  box-shadow: ${shadow.default};
  ${tw`hover:text-white hover:bg-orange focus:text-white focus:bg-orange`}

  &:disabled {
    background-color: ${colors.orange};
    color: ${colors.white};
    opacity: 0.8;
    &:hover,
    &.focus,
    &.active {
      background-color: ${colors.orange};
      color: ${colors.white};
    }
  }
`;

const StyleButtonGrey = styled.button<StyleButtonProps>`
  ${tw`truncate`}
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  height: ${(props) => (props.height ? `${props.height}px` : '38px')};
  background-color: ${colors.greyback};
  color: ${colors.white};
  border-width: 0px;
  cursor: pointer;
  font-family: ${fontFamily.SourceSansProRegular};
  line-height: 18px;
  box-shadow: ${shadow.default};
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyleDotSteps = styled.div`
  .dot {
    background: ${colors.orange};
    width: 20px;
    display: block;
    height: 20px;
    border-radius: 50%;
  }
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid white;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyleSelectOption = styled(Select)`
  ${tw`flex items-center`}
  &.ant-select-disabled {
    ${tw`text-black opacity-80`}
    .ant-select-selection-item {
      ${tw`text-black`}
    }
  }
  &.empty-select {
    .ant-select-selection-item {
      ${tw`text-darkLight text-opacity-50`}
    }
  }
  .placeholder-select .ant-select-item-option-content {
    ${tw`text-darkLight text-opacity-50`}
  }
  .ant-select-selector {
    height: 38px !important;
    border-radius: 25px !important;
    border-color: #8e9c9c !important;
    .ant-select-selection-placeholder {
      ${tw`text-darkLight flex items-center text-opacity-50`}
    }

    .ant-select-selection-item {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: block;
      line-height: 36px;
    }
  }
  .ant-select-arrow {
    top: unset;
    right: 7px;
    width: unset;
    height: unset;
    margin-top: unset;
  }
`;

const StyleInput = styled(Input)`
  ${tw`border-greyBlack hover:border-greyBlack`};
  font-family: 'Source Sans Pro Regular';
  height: 38px;
  border-radius: 25px;
  font-weight: 400;
  ${tw`border-greyBlack`}
  &::placeholder {
    color: #bfbfbf;
  }
  &.ant-input-disabled {
    ${tw`opacity-80 text-black cursor-not-allowed`}
    &:hover {
      ${tw`border-greyBlack`}
    }
  }
`;

const StyleInputPassword = styled(Input.Password)`
  ${tw`border-greyBlack hover:border-greyBlack`};
  font-family: 'Source Sans Pro Regular';
  height: 38px;
  border-radius: 25px;
  font-weight: 400;
  ${tw`border-greyBlack`}
  &.ant-input-disabled {
    ${tw`opacity-80 text-black cursor-not-allowed`}
    &:hover {
      ${tw`border-greyBlack`}
    }
  }
`;

interface StyledRadioProps {
  vertical: boolean;
}
const StyledRadio = styled(Radio.Group)<StyledRadioProps>`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    &.ant-radio-wrapper-disabled {
      span,
      ant-radio-disabled {
        ${tw`text-black opacity-80`}
      }
    }
  }
  .ant-radio {
    width: 28px;
    height: 28px;

    .ant-radio-inner {
      border-color: #8e9c9c;
      width: 28px;
      height: 28px;
      ::after {
        display: block;
        width: 20px;
        height: 20px;
        background-color: ${colors.orange};
        border-radius: 50%;
      }
    }
  }
`;

const StyleAwaitChecking = styled.div`
  ${tw`h-full flex items-center justify-center`}
`;

const StyleCheckbox = styled(Checkbox)`
  width: 18px;
  height: 18px;
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: white;
      }
    }
  }
  .ant-checkbox-inner {
    border: 1px solid white !important;
    background: transparent;
    &:hover {
    }
    &:focus {
      border-color: white;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border-color: white;
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: white;
      }
    }
    .ant-checkbox-inner {
      background-image: url(${closeRange});
      background-color: transparent;
      background-size: cover;
      &::after {
        content: '';
        display: none;
      }
    }
  }
`;

const StyleSavePaymentCheckbox = styled(Checkbox)`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  .ant-checkbox {
    width: 28px;
    height: 28px;
    top: 0;
    .ant-checkbox-inner {
      border-color: #8e9c9c;
      background-color: #fff;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      ::after {
        left: 3px;
        top: 18px;
        display: block;
        width: 20px;
        height: 20px;
        background-color: ${colors.orange};
        border-radius: 50%;
      }
    }
  }
  .ant-checkbox-checked {
    &::after {
      border: none;
    }
  }
`;

const StyleToggleBillingCheckbox = styled(StyleSavePaymentCheckbox)`
  font-size: 20px !important;
  font-weight: 600;
  color: ${colors.blueDark};
  .text {
    &::after {
      content: 'Billing address different than shipping address';
    }
  }
  @media only screen and (max-width: 576px) {
    font-size: 18px !important;
  }
  @media only screen and (max-width: 480px) {
    .text {
      &::after {
        content: 'Billing different shipping';
      }
    }
  }
`;

const StyleCalendar = styled(Calendar)`
  cursor: ${(props) => (props.disabledDate ? 'not-allowed' : '')};
  width: 100%;
  max-width: 290px;
  .ant-picker-cell::before {
    ${tw`hidden!`}
  }
  tr {
    th,
    td {
      width: 40px;
      height: 40px;
    }
  }
  .calendar-custom-header {
    ${tw`flex items-center justify-between text-black text-opacity-40 `}
    .icon {
      ${tw`cursor-pointer`}
      font-size: 20px;
    }
    .text {
      ${tw`font-Roboto-Medium`}
      font-size: 14px;
    }
  }
  .ant-picker-panel {
    ${tw`border-t-0`}
    padding-top: 19px;
    .ant-picker-body {
      .ant-picker-content {
        th {
          ${tw`font-Roboto-Medium text-black`}
          font-size: 12px;
        }
        .ant-picker-cell {
          ${tw`p-0`}
          &.ant-picker-cell-disabled {
            .day:not(.current-day) span {
              ${tw`text-gray`}
            }
          }
        }
        .day {
          ${tw`p-0 w-full font-Roboto-Regular`}
          font-size: 12px;
          width: 40px;
          height: 40px;
          &.current-day {
            ${tw`text-orange font-Roboto-Bold`}
          }
          &.day-selected {
            ${tw`rounded-full bg-orange text-white font-Roboto-Bold`}
          }
        }
      }
    }
  }
`;

const StylePlayer = styled(Modal)`
  .ant-modal-header {
    ${tw`hidden`}
  }
  .ant-modal-body {
    .player {
      padding: 24px 22px 25px 22px;
      overflow: hidden;
      position: relative;
      width: 100%;
      &::after {
        display: block;
        content: '';
      }
      &.vertical::after {
        padding-top: 55%;
      }
      &.horizontal::after {
        padding-top: 35%;
      }
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &:focus {
          outline: none;
        }
      }
    }

    .back button {
      ${tw`flex items-center font-Poppins-Semi-Bold text-blueDark`}
      padding-left: 0px;
      padding-right: 5px;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
`;

const StyleHeaderContainer = styled.div`
  ${tw`flex flex-col`}
  .img {
    width: 75px;
    height: 75px;
  }
  .title {
    ${tw`font-Poppins-Semi-Bold text-center text-blueDark`}
    font-size: 24px;
  }
  .sub-title {
    word-wrap: break-word;
    padding-bottom: 35px;
    margin-top: 30px;
    ${tw`border-0 border-greyLight border-b border-solid border-opacity-25`}

    p {
      ${tw`text-center mb-0 font-Poppins-Semi-Bold`}
      line-height: 21px;
    }
  }
`;

const StyleGroomsmenItem = styled.section`
  ${tw`border-0 border-greyLight border-opacity-50 border-b border-solid flex justify-between items-center`}
  margin-top: 15px;
  padding-right: 15px;
  padding-left: 16px;
  padding-bottom: 13px;
  word-break: break-all;
  grid-gap: 10px;
  .left {
    ${tw`flex flex-col`}
    .name {
      ${tw`font-Poppins-Regular`}
    }
    .role {
      ${tw`text-darkLight text-opacity-50`}
    }
    .status {
      .title {
        ${tw`font-Source-Sans-Pro-Bold`}
      }
    }
  }
  .right {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    .reminder {
      width: 24px;
      height: 24px;
      min-width: 24px;
      display: grid;
      align-items: center;
      justify-content: center;
      border: none;
      ${tw`text-white bg-orange`}
    }
  }
`;

const StyledScrollIndex = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  position: relative;
  margin: 10px 0;
  ::-webkit-scrollbar {
    width: 30px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    ${tw`bg-blueDark bg-opacity-25`}
    border-radius: 20px;
    height: 6px;
    border: 9px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 20px;
  }
`;

export {
  StyleGroomsmenItem,
  StyleHeaderContainer,
  StylePlayer,
  StyleCalendar,
  StyleAwaitChecking,
  StyleButtonGrey,
  StyledRadio,
  StyleInput,
  StyleButtonOrange,
  StyleDotSteps,
  StyleSelectOption,
  StyleCheckbox,
  StyleSavePaymentCheckbox,
  StyleToggleBillingCheckbox,
  StyleInputPassword,
  StyledScrollIndex,
};
