import React, { FC, useMemo } from 'react';
import { Row } from 'antd';
import { StyleHeaderContainer } from './Styled';
import iconShirt from '../../assets/images/iconShirt.png';

interface HeaderContainerProps {
  title: string;
  subTitle?: string;
}
const HeaderContainer: FC<HeaderContainerProps> = ({ title, subTitle }) => {
  const newText = useMemo(() => {
    if (subTitle) {
      return subTitle.split('\n').map((str, index) => (str === '' ? <br /> : <p key={index}>{str}</p>));
    }
    return null;
  }, [subTitle]);
  return (
    <StyleHeaderContainer>
      <Row justify="center">
        <img src={iconShirt} className="img" alt="shirt" />
      </Row>
      <span className="title">{title}</span>
      {newText && <div className="sub-title">{newText}</div>}
    </StyleHeaderContainer>
  );
};

export default HeaderContainer;
