/* eslint-disable prefer-rest-params */
import { ApiClient, SocketIO, api } from '@goldfishcode/modern-groom-api-sdk';
import { IWeddingPartyMember } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { SocketEventName } from '@goldfishcode/modern-groom-api-sdk/libs/socket';
import { Router } from '@reach/router';
import { Modal, message } from 'antd';
import useAuth from 'components/hooks/useAuth';
import { useBroadcastChannel } from 'components/hooks/useBroadcastChannel';
import UseConfigAlert from 'components/hooks/useConfigAlert';
import UseHandleEmailAction from 'components/hooks/useHandleEmailAction';
import { useInterceptor } from 'components/hooks/useInterceptor';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSuitDetail } from 'reducers/suit/action';
import './App.scss';
import LayoutMain from './components/LayoutMain/LayoutMain';
import { AppContext } from './components/contexts/AppContext';
import { AppSocketContext } from './components/contexts/AppSocketContext';
import LayoutLogin from './components/layout-login/LayoutLogin';
import ListWedding from './components/layout-login/modal/ListWedding';
import AwaitChecking from './components/ui/AwaitChecking';
import { UserRole } from './enum';
import { RootState } from './reducers';
import { setStatePartyModal } from './reducers/asset/action';
import { setAuth } from './reducers/auth/action';
import { setActiveParty, setListParty, setReadingStatus, setUser } from './reducers/user/action';
import DefaultRouter from './router/DefaultRouter';
import RouterGroom from './router/RouterGroom';
import RouterGroomsman from './router/RouterGroomsman';

declare global {
  interface Window {
    visitorGlobalObjectAlias: any;
    q: any;
    l: any;
    vgo: any;
  }
}

const webSocket = SocketIO.instance();

const App: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const callStack = useRef(0);
  const [backGroundImg, setBackground] = useState('');
  const isOpenPartyModal = useSelector((state: RootState) => state.assetReducer.isOpenPartyModal);
  const userProfile = useSelector((state: RootState) => state.userReducer.userProfile);
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);

  const isAuthenticated = useSelector((state: RootState) => state.authReducer.isAuthenticated);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const listParty = useSelector((state: RootState) => state.userReducer.listParty);
  const alertConfig = useSelector((state: RootState) => state.assetReducer.alertConfig);

  const { forceLogout, checkAuth } = useAuth();

  const dispatch = useDispatch();

  const { alertOrderPlaced, showModalForceLogout } = UseConfigAlert();

  UseHandleEmailAction();
  useBroadcastChannel();
  useInterceptor();

  const setLoading = (v: boolean) => {
    if (v) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  };
  const setCallStackLoading = (v: boolean) => {
    if (v) {
      callStack.current++;
      if (!isLoading) {
        setIsLoading(true);
      }
    } else {
      if (callStack.current === 1) {
        setIsLoading(false);
      }
      callStack.current--;
    }
  };

  const getUserInfo = async () => {
    const userInfo = await api.User.me();
    dispatch(setUser(userInfo));
    const userSuit = await api.Suit.mySuit();
    dispatch(setSuitDetail(userSuit));
    // eslint-disable-next-line func-names
    (function (e, t, o, n) {
      e.visitorGlobalObjectAlias = n;
      e[e.visitorGlobalObjectAlias] =
        e[e.visitorGlobalObjectAlias] ||
        // eslint-disable-next-line func-names
        function () {
          (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments);
        };
      e[e.visitorGlobalObjectAlias].l = new Date().getTime();
      const r = t.createElement('script');
      r.src = o;
      r.async = true;
      const i = t.getElementsByTagName('script')[0];
      if (i && i.parentNode) {
        i.parentNode.insertBefore(r, i);
      }
    })(window, document, 'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js', 'vgo');
    if (window.vgo) {
      window.vgo('setAccount', '1000679488');
      window.vgo('setTrackByDefault', true);
      window.vgo('setEmail', userInfo.email);
      window.vgo('process');
    }
  };
  const getReadingStatus = async () => {
    try {
      const res = await api.User.checkMarkRead();
      dispatch(setReadingStatus(res));
    } catch (error) {
      message.error((error as Error).message);
    }
  };
  const getListParty = async () => {
    const res = await api.User.myWeddingParties({ page: 1, limit: 100 });
    if (res.results && res.results.length > 0) {
      dispatch(setListParty(res.results));
      let activeParty;
      res.results.forEach((item) => {
        if (item.is_active) {
          activeParty = item;
        }
      });
      if (activeParty) {
        dispatch(setActiveParty(activeParty));
        if (activeParty.order_expired) {
          alertOrderPlaced(activeParty.party_name);
        }
        if (activeParty.user_role !== UserRole.GROOM) return;
        getReadingStatus();
      }
      if (!activeParty) {
        dispatch(setActiveParty(activeParty));
      }
    } else {
      showModalForceLogout();
    }
  };

  const connectSocket = async () => {
    const session = await ApiClient.getAuthToken();
    if (session?.access_token) {
      webSocket.connect(process.env.REACT_APP_SOCKET_URL || '', session.access_token);
      webSocket.on(SocketEventName.Connect, () => {
        // no action
      });
      webSocket.on(SocketEventName.UserChangeStatus, getReadingStatus);
    } else {
      webSocket.disconnect();
    }
  };

  const onChooseWeddingParty = async (party: IWeddingPartyMember) => {
    if (!party.wedding_party) {
      showModalForceLogout('Error', 'The wedding party does not exist, please contact the administrator');
      return;
    }

    if (party.order_expired) {
      alertOrderPlaced(party.party_name);
      dispatch(setStatePartyModal(false));
      return;
    }

    try {
      await api.User.activeParty(party.wedding_party);
      dispatch(setActiveParty(party));
      dispatch(setStatePartyModal(false));
      dispatch(setAuth(true));
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const getUserDetails = async () => {
    try {
      setCallStackLoading(true);
      await getUserInfo();
      if (!listParty || listParty.length < 1) {
        await getListParty();
      }
    } catch (error) {
      if ((error as any).code === 1011) return;
      showModalForceLogout();
    } finally {
      setCallStackLoading(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      getUserDetails();
      connectSocket();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (alertConfig && alertConfig.visible) {
      Modal[alertConfig.type || 'info']({
        title: <h3 className="font-bold text-lg">{alertConfig.title}</h3>,
        content: alertConfig.content,
        okText: alertConfig.okText,
        onOk: alertConfig.onOk,
      });
    }
  }, [alertConfig]);

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AppSocketContext.Provider value={webSocket}>
      <AppContext.Provider
        value={{
          isLoading,
          setLoading,
          setCallStackLoading,
          backGroundImg,
          setBackground,
        }}
      >
        <LayoutMain>
          {isAuthenticated === null && (!userProfile || !suitDetail) ? (
            <AwaitChecking />
          ) : (
            <Router
              basepath="/"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {isAuthenticated && userProfile && activeParty?.user_role?.key === UserRole.GROOM && <RouterGroom path="/*" />}
              {isAuthenticated && userProfile && activeParty?.user_role?.key !== UserRole.GROOM && <RouterGroomsman path="/*" />}
              {!isAuthenticated && <LayoutLogin path="/login" />}
              {!isAuthenticated && <DefaultRouter default link="/login" />}
            </Router>
          )}
          {isOpenPartyModal && (
            <ListWedding
              onLogOut={() => {
                dispatch(setStatePartyModal(false));
                forceLogout();
              }}
              onChooseWedding={(party) => {
                onChooseWeddingParty(party);
              }}
            />
          )}
        </LayoutMain>
      </AppContext.Provider>
    </AppSocketContext.Provider>
  );
};

export default App;
