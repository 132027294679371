import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../../styles/definedStyle';
import { StyleWeddingPartyRegisterStep3 } from '../register-groomsman/StyleRegisterGroomsman';

const StyleRegisterGroom = styled.div`
  ${tw`relative flex flex-col w-full h-full overflow-auto`}
  .header-step {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    .text {
      ${tw`text-white font-Poppins-Semi-Bold text-center`}
    }
    .steps {
      ${tw`flex items-center`}
      margin-top: 10px;
      .ant-steps-item-container {
        ${tw`flex items-center`}
      }
      .ant-steps-item-custom {
        ${tw`pl-0`}
      }
      .ant-steps-item-icon {
        ${tw`mr-0`}
      }
      .ant-steps-item-title {
        &::after {
          ${tw`left-0 text-white`}
          left: 0px;
        }
      }
    }
  }
  .container {
    margin: auto;
    z-index: 50;
    padding: 45px 0px;
  }
  @media only screen and (max-width: 1200px) {
    .header-step {
      grid-template-columns: 120px 391px;
      grid-gap: 10px;
      .steps {
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .container {
      width: 100%;
    }
  }

  @media only screen and (max-width: 576px) {
    .header-step {
      grid-template-columns: 120px 200px;
      grid-gap: 10px;
    }
  }
  @media only screen and (max-width: 375px) {
    .header-step {
      grid-template-columns: 100px 150px;
    }
    .container {
      padding: 0px;
    }
  }
`;

const StyleRegisterGroomStep1 = styled.div`
  ${tw`bg-white`}
  padding-top: 45px;
  padding-bottom: 45px;
  border-radius: 15px;
  width: 750px;
  .info {
    margin-top: 40px;
    padding-left: 54px;
    padding-right: 56px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
  }
`;

const StyleRegisterGroomStep2 = styled(StyleWeddingPartyRegisterStep3)``;

const StyleRegisterGroomStep3 = styled.div`
  padding-top: 25px;
  padding-bottom: 45px;
  border-radius: 15px;
  width: 750px;
  ${tw`bg-white`}
  .icon {
    ${tw`flex justify-center`}
  }
  .inches label {
    opacity: 0;
  }
  .back a {
    ${tw`flex items-center font-Poppins-Semi-Bold text-blueDark`}
    padding-left: 30px;
    font-size: 16px;
  }
  .icon {
    ${tw`flex justify-center`}
    img {
      ${tw`cursor-pointer`}
      width: 75px;
      height: 75px;
    }
  }
  .title {
    ${tw`font-Poppins-Semi-Bold text-center text-blueDark`}
    font-size: 24px;
    margin-top: 24.5px;
    line-height: 35px;
  }
  .list-groomsman {
    padding-left: 42px;
    padding-right: 39px;
    .title {
      ${tw`font-Poppins-Semi-Bold text-blueDark`}
      font-size: 14px;
    }
    .list {
      height: 300px;
      ${tw`border border-solid w-full border-greyBlack overflow-auto`}
      .item {
        ${tw`border-0 border-greyBlack border-b border-solid flex justify-between `}
        height: 51px;
        margin-top: 18px;
        padding-right: 15px;
        padding-left: 16px;
        .left {
          ${tw`flex flex-col`}
          .name {
            ${tw`font-Poppins-Regular`}
          }
          .role {
            ${tw`text-darkLight text-opacity-50`}
          }
        }
      }
    }
  }
  .add-member-form {
    padding-left: 42px;
    padding-right: 39px;
  }
  .action {
    margin-top: 44px;
    text-align: center;
  }
  .help-text {
    padding-left: 45px;
    padding-right: 50px;
    font-weight: 700;
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
  }
  @media only screen and (max-width: 375px) {
    .title {
      font-size: 18px;
    }
  }
`;

const StyleRegisterGroomListGroom = styled.div`
  border: 0.5px solid ${colors.greyback};
  .list-groom {
    ${tw`flex justify-between items-center`}
    padding: 14px 14px;
    button {
      background-color: ${colors.orange};
      border: none;
      svg {
        width: 20px;
        height: 20px;
        margin-top: 4px;
      }
    }
    .groom-title {
      ${tw`flex flex-col`}
      span {
        ${tw`font-bold`}
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
  }
  .border {
    border-bottom: 1px solid #707070;
    width: 100%;
  }
`;

const StyledRegisterGroomStep3Form = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 20px;
  .form {
    display: grid;
    grid-gap: 14px;
    .input-row {
      .label {
        ${tw`font-Poppins-Semi-Bold text-blueDark gap-8`}
        padding: 0 0 8px;
      }
      &.row-1 {
        display: grid;
        .wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
        }
      }
      &.row-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        .email,
        .role {
          display: grid;
        }
      }
    }
  }
  .circle-add {
    width: 38px;
    height: 38px;
    margin-bottom: 24px;
    ${tw`rounded-full flex items-center self-end justify-center bg-orange shadow-custom text-white`}
    font-size: 30px;
    &:hover {
      ${tw`border-0`}
    }
  }
  @media only screen and (max-width: 480px) {
    .row-1 {
      .wrapper {
        grid-template-columns: 1fr !important;
        gap: 5px !important;
      }
    }
    .row-2 {
      grid-template-columns: 1fr !important;
      gap: 5px !important;
    }
  }

  @media only screen and (max-width: 375px) {
    .form {
      display: block;
      .input-row {
        &.row-1 {
          grid-template-columns: 1fr;
          .first-name {
            margin: 0;
          }
          .last-name {
            grid-column: 1;
            grid-row: 3;
          }
        }
        &.row-3 {
          .ant-radio-group {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
`;

export {
  StyledRegisterGroomStep3Form,
  StyleRegisterGroom,
  StyleRegisterGroomStep1,
  StyleRegisterGroomStep2,
  StyleRegisterGroomStep3,
  StyleRegisterGroomListGroom,
};
