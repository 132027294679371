import styled from '@emotion/styled';
import tw from 'twin.macro';

const StyleNeedHelp = styled.div`
  display: none;
  ${tw`fixed`}
  z-index: 20;
  bottom: 42px;
  right: 37px;
  > div {
    ${tw`relative`}
  }
  .info {
    width: 186px;
    ${tw`ml-auto`}
    .text {
      ${tw`font-Source-Sans-Pro-Bold text-white`}
      font-size: 20px;
      margin-bottom: 11px;
    }
  }
  .chat-layout {
    ${tw`absolute bottom-0 right-0`}
    width: 345px;
  }
`;

interface StyleChatProps {
  widthContent: number;
}
const StyleChat = styled.div<StyleChatProps>`
  .ant-collapse {
    ${tw`border-0`}
    .ant-collapse-item {
      ${tw`border-0`}
      .ant-collapse-header {
        ${tw`hidden`}
      }
      .ant-collapse-content {
        ${tw`border-t-0`}
        .ant-collapse-content-box {
          ${tw`p-0`}
        }
      }
    }
  }
  .chat-content {
    height: ${(props) => `${props.widthContent}px`};
    ${tw`bg-orange flex flex-col`}
    padding: 13px 19px 19px;
    .head {
      ${tw`flex justify-between items-center text-white font-Source-Sans-Pro-Bold`}
      font-size: 20px;
      margin-bottom: 11px;
      line-height: 28px;
      button {
        ${tw`bg-transparent border-0 cursor-pointer px-0 
        focus:border-0! focus:outline-none!

        `}
      }
    }
    .chat {
      ${tw`flex flex-col justify-end flex-auto bg-white h-full overflow-hidden`}
      padding-bottom: 19px;
      .mess {
        ${tw`overflow-auto`}
        padding: 0px 15px;
      }
      .input {
        padding: 0px 15px;
      }
    }
  }
`;

const StyleMessage = styled.div`
  .message {
    ${tw`flex flex-col`}
    width: 196px;
    margin-bottom: 10px;
    &.not-me {
      .text {
        ${tw`bg-greyBlack bg-opacity-25`}
      }
    }
    &.me {
      ${tw`ml-auto`}
      .text {
        ${tw`bg-blueDark text-white`}
      }
    }
    .text {
      ${tw`font-Source-Sans-Pro-Regular`}
      font-size: 14px;
      line-height: 20px;
      border-radius: 10px;
      padding: 18px 14px 9px;
    }
    .time {
      ${tw`text-darkLight text-opacity-50 font-Source-Sans-Pro-Regular`}
      font-size: 14px;
    }
  }
`;

export { StyleNeedHelp, StyleChat, StyleMessage };
