import styled from '@emotion/styled';
import { Modal } from 'antd';
import tw from 'twin.macro';
import { colors } from '../../styles/definedStyle';

const StylePayment = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-body {
      padding: 25px 36px 32px 30px;
      .back button {
        ${tw`flex items-center font-Poppins-Semi-Bold text-blueDark p-0`}
        font-size: 16px;
      }
      .modal-head {
        .title {
          ${tw`font-Poppins-Semi-Bold text-center`}
          margin-top: 10px;
          font-size: 24px;
        }
        .info {
          margin-top: 35px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          justify-items: center;
          @media only screen and (max-width: 480px) {
            grid-template-columns: 1fr;
          }
          grid-gap: 10px;
          .item {
            ${tw`flex flex-col items-center`}
            width: fit-content;
            .title-small {
              ${tw`font-Poppins-Semi-Bold`}
              font-size: 14px;
            }
            .text {
              ${tw`font-Poppins-Regular`}
              font-size: 14px;
            }
          }
          .cost {
            .cost-row {
              display: grid;
              width: 100%;
              grid-template-columns: 105px auto;
              &.discount {
                padding-bottom: 5px;
                .text {
                  &.loading {
                    padding-left: 10px;
                  }
                }
              }
              &.total {
                border-top: 1px solid #000;
              }
              .text {
                padding-left: 10px;
              }
            }
          }
        }
      }
      .card-info {
        margin-top: 24px;
        .btn-toggle-billing {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
        .btn-save-payment {
          justify-content: center;
          a {
            text-decoration: underline;
            color: #0000ee;
            &:hover {
              color: ##0000ee;
            }
          }
        }
        .ant-collapse {
          .ant-form-item {
            margin: 0;
          }
        }
        .info-row {
          p {
            margin: 0;
          }
          display: grid;
          grid-gap: 12px;
          margin-bottom: 20px;
          .info-title {
            ${tw`font-Poppins-Semi-Bold`}
            font-size: 14px;
            line-height: 21px;
          }
          .ant-form-item {
            margin: 0;
          }
          &.coupon {
            .coupon-box {
              display: grid;
              grid-template-columns: 1fr 1fr;
              .apply-coupon-btn {
                justify-self: start;
                margin-left: 20px;
                width: 100px;
                background: ${colors.blueDark};
                color: ${colors.white};
                &:disabled {
                  opacity: 0.8;
                  color: #fff;
                }
              }
            }
            .discount {
              padding: 0 20px;
              background-color: ${colors.orange};
              width: fit-content;
              color: #fff;
            }
          }
          &.coupon {
            .coupon-box {
              display: grid;
              grid-template-columns: 1fr 1fr;
              .apply-coupon-btn {
                justify-self: start;
                margin-left: 20px;
                width: 100px;
                background: ${colors.blueDark};
                color: ${colors.white};
                &:disabled {
                  opacity: 0.8;
                  color: #fff;
                }
              }
            }
            .discount {
              padding: 0 20px;
              background-color: ${colors.orange};
              width: fit-content;
              color: #fff;
            }
          }
          &.name-card {
          }
          &.expDate-Cvc {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 15px;
            .card-cvc,
            .card-expiry {
              display: grid;
              grid-gap: 12px;
              margin-bottom: 5px;
            }
            @media only screen and (max-width: 576px) {
              grid-template-columns: 1fr;
              grid-gap: 0;
            }
            .card-cvc {
              span {
                font-size: 12px;
                font-weight: 400;
                ${tw`font-Poppins-Regular`}
              }
            }
          }
          &.shipping-address {
            .city-state-zipcode {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              grid-gap: 15px;
            }
            .shipping-name {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 15px;
            }
            .shipping-fee {
              padding: 10px;
            }
          }
        }
        .payment-methods {
          .wrapper {
            padding: 20px;
            max-height: 500px;
            height: fit-content;
            overflow: auto;
            background: ${colors.blueDark};
          }
        }
        .currency-payment-method-box {
          display: flex;
          .currency-cover {
            display: none;
            width: 50%;
            margin-right: 20px;
          }
          .list-card-img {
            display: grid;
            justify-items: end;
            img {
              height: 44px;
            }
          }
          .currency-cover,
          .payment-method-cover {
            .info-title {
              font-family: Poppins Semi Bold;
              font-size: 14px;
              line-height: 21px;
              margin: 0;
              margin-bottom: 10px;
            }
            .hidden {
              visibility: hidden;
            }
          }
        }

        .card-time {
          .code-small-label {
            ${tw`font-Poppins-Regular`}
            font-size: 12px;
          }
        }
        .action {
          margin-top: 25px;
        }
        @media only screen and (max-width: 480px) {
          .btn-toggle-billing {
            justify-content: flex-start;
          }
          .btn-save-payment {
            justify-content: flex-start;
          }
          .action {
            grid-gap: 10px;
          }
        }
      }
      .ant-input {
        &:disabled {
          color: #979494;
          background: #f4f6f6;
          border: 1px solid #f4f6f6;
          cursor: not-allowed;
        }
      }
      .field-stripe {
        ${tw`border-greyBlack hover:border-greyBlack`}
        height: fit-content;
        border-radius: 25px;
        padding: 1px 13px;
        &::placeholder {
          ${tw`text-black text-opacity-50`}
        }
        &.invalid-field {
          border: 1px solid #ff4d4f;
        }
        &.disabled-input {
          background: #f4f6f6;
          border: 1px solid #f4f6f6;
        }
      }
    }
  }
  .ant-collapse {
    .billing-title {
      ${tw`font-Poppins-Semi-Bold`};
      font-size: 14px;
      line-height: 21px;
    }
    .ant-collapse-header {
      display: none;
    }
    .ant-collapse-content-box {
      ${tw`p-0`};
      display: grid;
      grid-gap: 15px;
    }
  }
`;

export { StylePayment };
