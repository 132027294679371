import { Spin } from 'antd';
import React, { FC } from 'react';
import { StyleAwaitChecking } from './Styled';

const AwaitChecking: FC = () => {
  return (
    <StyleAwaitChecking>
      <Spin size="large" />
    </StyleAwaitChecking>
  );
};

export default AwaitChecking;
