import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors, fontFamily } from '../../../styles/definedStyle';

const StyleWeddingPartyRegister = styled.div`
  ${tw`flex flex-col w-full h-full`}
  .header-step {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    .text {
      ${tw`text-white font-Poppins-Semi-Bold text-center`}
    }
    .steps {
      ${tw`flex items-center`}
      .ant-steps-item-container {
        ${tw`flex items-center`}
      }
      .ant-steps-item-custom {
        ${tw`pl-0`}
      }
      .ant-steps-item-icon {
        ${tw`mr-0`}
      }
      .ant-steps-item-title {
        &::after {
          ${tw`left-0 text-white`}
          left: 0px;
        }
      }
    }
  }
  .container {
    z-index: 50;
    padding: 45px 0px;
    overflow-y: auto;
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    .header-step {
      grid-template-columns: 120px 391px;
      grid-gap: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    .container {
      width: 100%;
    }
  }
  @media only screen and (max-width: 576px) {
    .header-step {
      grid-template-columns: 120px 200px;
      grid-gap: 10px;
    }
  }
  @media only screen and (max-width: 375px) {
    .header-step {
      grid-template-columns: 100px 150px;
    }
    .container {
      padding: 0px;
    }
  }
`;
const StyleWeddingPartyRegisterStep1 = styled.div`
  padding: 45px;
  border-radius: 15px;
  width: 750px;
  background: ${colors.white};
  margin: 0 auto;
  .action {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
  }
`;

const StyleWeddingPartyRegisterStep2 = styled.div`
  padding-top: 45px;
  padding-bottom: 45px;
  border-radius: 15px;
  width: 750px;
  background: ${colors.white};
  margin: 0 auto;
  .back a {
    ${tw`flex items-center font-Poppins-Semi-Bold text-blueDark`}
    padding-left: 30px;
    font-size: 16px;
  }
  .info {
    margin-top: 40px;
    padding: 0px 20px;
    display: grid;
  }
  .action {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
  }
`;

const StyleWeddingPartyRegisterStep3 = styled.div`
  margin: 0 auto;
  .form {
    padding-left: 45px;
    padding-right: 50px;
    margin-top: 45px;
  }

  padding-top: 45px;
  padding-bottom: 21px;
  border-radius: 15px;
  width: 750px;
  ${tw`bg-white`}
  .icon {
    ${tw`flex justify-center`}
  }
  .inches label {
    opacity: 0;
  }
  .back a {
    ${tw`flex items-center font-Poppins-Semi-Bold text-blueDark`}
    padding-left: 30px;
    font-size: 16px;
  }
  .icon {
    ${tw`flex justify-center`}
    img {
      ${tw`cursor-pointer`}
      width: 75px;
      height: 75px;
    }
  }
  .title {
    ${tw`font-Poppins-Semi-Bold text-center text-blueDark`}
    font-size: 24px;
    margin-top: 24.5px;
    line-height: 35px;
  }
  .help-text {
    padding-left: 45px;
    padding-right: 50px;
    font-weight: 700;
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-radius: 0;
  }
`;

const StyleWeddingPartyRegisterStep3Form = styled.div`
  .form {
    .info-row {
      display: grid;
      &.select {
        .ant-form-item {
          display: grid !important;
          grid-template-rows: 38px 62px;
          grid-gap: 8px;
          height: 100%;
          margin: 0;
          .ant-form-item-control {
            grid-row: 2;
          }
          &.small-text {
            .ant-form-item-label {
              label {
                font-size: 10px;
              }
            }
          }
          &.custom {
            .ant-form-item-label {
              label {
                width: 100%;
              }
            }
          }
          .ant-form-item-label {
            flex: 1;
            align-items: center;
            display: flex;
            .icon {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
          .ant-form-item-control {
            flex-grow: 0;
          }
        }
      }
      &.radio {
        .ant-form-item {
          display: grid !important;
          ${tw`w-1/2 `}
          &:last-of-type {
            padding-left: 10px;
            .ant-form-item-control {
              .ant-radio-group {
                max-width: 286px;
                ${tw`flex`}
                label:first-of-type {
                  margin-right: 35px;
                }
              }
            }
          }
        }
      }
      &.row-1,
      &.row-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
      }
      &.row-2 {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }
      &.row-3 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
      }
      &.upload-image {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        .item {
          .ant-row {
            display: grid;
            grid-template-rows: 1fr min-content;
            grid-gap: 8px;
            height: 100%;
            padding-bottom: 24px;
            margin: 0;
            .ant-form-item-label {
              padding: 0;
            }
          }
        }
      }
      &.your-photo {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        .image {
          display: grid;
          grid-template-rows: max-content auto;
          grid-gap: 5px;
          width: 200px;
          height: fit-content;
          .ant-image > img {
            width: 200px;
            height: 200px;
            object-fit: cover;
          }
        }
      }
    }
    .describe-build {
      margin-bottom: 24px;
      h3 {
        margin-bottom: 10px !important;
      }
    }

    .ant-form-item-label {
      ${tw`font-Poppins-Semi-Bold`}
    }
    .divider {
      margin: 6.5px 0 30.5px;
    }
    .action {
      h3 {
        ${tw`font-Poppins-Semi-Bold text-blueDark mb-0`}
        font-size: 11px;
        line-height: 17px;
      }
      h4 {
        ${tw`font-Poppins-Semi-Bold text-blueDark text-opacity-50 mb-0`}
        font-size: 9px;
        line-height: 13px;
      }
      button.choose-file {
        margin-top: 12px;
      }
      .ant-col.item {
        display: flex;
        align-items: flex-end;
        .ant-form-item {
          width: 150px;
        }
      }
    }
    .continue {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 24px;
    }
    .btn-skip {
      margin-right: 15px;
    }
  }
  @media only screen and (max-width: 767px) {
    .form {
      .info-row {
        &.row-1,
        &.row-3,
        &.row-4 {
          grid-template-columns: 1fr 1fr;
        }
        &.row-2 {
          grid-template-columns: 1fr 1fr;
          .ant-form-item {
            width: 100%;
            .ant-radio-group {
              display: grid !important;
              max-width: 100% !important;
              grid-gap: 5px;
            }
          }
        }
        &.upload-image {
          grid-template-columns: 1fr 1fr;
        }
        &.your-photo {
          grid-template-columns: 1fr 1fr;
          .item {
            grid-column: 1/3;
            width: calc(50% - 10px);
          }
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .form {
      .info-row {
        &.row-1,
        &.row-3,
        &.row-4 {
          grid-template-columns: 1fr 1fr;
        }
        &.row-2 {
          grid-template-columns: 1fr;
          .ant-form-item {
            &:last-of-type {
              padding: 0 !important;
            }
          }
          .ant-radio-group {
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 5px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .form {
      .info-row {
        &.row-1,
        &.row-3,
        &.row-4 {
          grid-template-columns: 1fr;
          grid-gap: 0;
        }
        &.upload-image {
          grid-template-columns: 1fr;
          .continue {
            grid-column: 1/2;
            margin: 0 auto;
          }
          .choose-file {
            display: block;
            margin: 0 auto;
            margin-top: 10px;
          }
        }
        &.your-photo {
          grid-template-columns: 1fr;
          .image {
            width: fit-content;
            justify-self: center;
            justify-items: center;
          }
          .item {
            width: 100%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .form {
      .info-row {
        &.row-2 {
          grid-template-columns: 1fr;
          .ant-form-item {
            &:last-of-type {
              padding: 0 !important;
            }
          }
          .ant-radio-group {
            grid-template-columns: 1fr;
            grid-gap: 5px;
          }
        }
      }
    }
  }
`;

const StyleGroomsmanDetail = styled.div`
  .info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 0;
      .head {
        ${tw`text-blueDark`}
        font-family: ${fontFamily.PoppinsSemiBold};
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 0px;
      }
      .text {
        ${tw`text-blueDark`}
        font-family: ${fontFamily.PoppinsRegular};
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .info {
      grid-template-columns: 1fr;
    }
  }
`;

export {
  StyleWeddingPartyRegisterStep1,
  StyleGroomsmanDetail,
  StyleWeddingPartyRegisterStep3Form,
  StyleWeddingPartyRegister,
  StyleWeddingPartyRegisterStep2,
  StyleWeddingPartyRegisterStep3,
};
