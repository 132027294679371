/* eslint-disable no-alert */
import { Button } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import GlobalStyle from '../../styles/GlobalStyle';
import { StyleLayoutMain } from '../StyleLayout';
import { AppContext } from '../contexts/AppContext';
import { useProgressiveImage } from '../hooks/LoadImage';
import Loading from '../ui/Loading';
import WarningBox from './WarningBox';

const LayoutMain: FC = ({ children }) => {
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);

  const appContext = useContext(AppContext);
  const img = useProgressiveImage(appContext.backGroundImg);
  const handleContactUs = () => {
    const email = 'info@themoderngroom.com';
    const mailToLink = `mailto:${email}`;
    if ('open' in window && window.open(mailToLink)) {
      // Email client opened successfully
    } else {
      // Provide alternative instructions for the user
      alert(`Please copy the email address and open your preferred email client manually: ${email}`);
    }
  };

  const isShowAlert =
    !!activeParty?.order_date &&
    moment(activeParty.order_date).diff(moment(new Date())) > 0 &&
    (suitDetail?.status === 'need_to_pay' || suitDetail?.status === 'added');
  const [visible, setVisible] = useState(true);
  const contentWarningAlert = (
    <p>
      The Modern Groom would like to deliver your suit on time. Please submit your measurements and payment by:{' '}
      <span style={{ textDecoration: 'underline' }}>{moment(activeParty?.order_date).format('MM-DD-YYYY')}</span>
    </p>
  );
  return (
    <StyleLayoutMain backgroundImg={img || ''}>
      <GlobalStyle />
      {children}
      <Loading />
      <Button shape="circle" className="mobile-btn mobile-contact-us" icon={<AiOutlineMail />} onClick={handleContactUs} />
      {isShowAlert && <WarningBox content={contentWarningAlert} visible={visible} setVisible={setVisible} />}
      <Button className="desktop-contact-us" onClick={handleContactUs}>
        <p>CONTACT US</p>
        <GrMail />
      </Button>
    </StyleLayoutMain>
  );
};

export default LayoutMain;
