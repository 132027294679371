import { Button } from 'antd';
import React, { FC } from 'react';
import { FaBell, FaMinus } from 'react-icons/fa';
import { StyleGroomsmenItem } from './Styled';

interface GroomsmenItemProps {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  status?: string;
  isShowStatus?: boolean;
  isShowRemindButton?: boolean;
  remindMember?(email: string): void;
  onRemove(email: string): void;
  isShowRemoveButton?: boolean;
}
const GroomsmenItem: FC<GroomsmenItemProps> = (props) => {
  const {
    email,
    firstName,
    lastName,
    role,
    status,
    isShowStatus = false,
    onRemove,
    remindMember,
    isShowRemindButton,
    isShowRemoveButton,
  } = props;

  return (
    <StyleGroomsmenItem>
      <section className="left">
        <div className="name">{`${firstName} ${lastName}`}</div>
        <span className="role">{`${role} | ${email}`}</span>
        {isShowStatus && (
          <span className="status">
            <span className="title">Status: </span>
            {status}
          </span>
        )}
      </section>
      <section className="right">
        {isShowRemindButton ? (
          <Button
            onClick={() => {
              if (remindMember) {
                remindMember(email);
              }
            }}
            className="reminder"
            shape="circle"
            icon={<FaBell />}
          />
        ) : (
          <div />
        )}
        {isShowRemoveButton && <Button onClick={() => onRemove(email)} className="reminder" shape="circle" icon={<FaMinus />} />}
      </section>
    </StyleGroomsmenItem>
  );
};

export default GroomsmenItem;
