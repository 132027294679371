import React, { FC, useEffect, useMemo, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { api } from '@goldfishcode/modern-groom-api-sdk';
import yourSuit from '../../../assets/images/avarta-suit.png';
import { RootState } from '../../../reducers';
import Payment from '../../payment/Payment';
import { StyleButtonOrange } from '../../ui/Styled';
import SuitBuilder from '../list/SuitBuilder';
import { StyledPayment } from './Styles';
import { setSuitDetail } from '../../../reducers/suit/action';
import { getParamsUrl } from '../../../util';

interface GroomsmanPaymentPropsType {
  disableAction?: boolean;
}

const GroomsmanPayment: FC<GroomsmanPaymentPropsType> = ({ disableAction }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const dispatch = useDispatch();
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);
  const queryParams = useMemo(() => getParamsUrl<any>(), []);

  const onPaySuccessfully = async () => {
    const result = await api.Suit.mySuit();
    dispatch(setSuitDetail(result));
    setVisibleModal(false);
  };

  useEffect(() => {
    if (queryParams?.action === 'payment') {
      setVisibleModal(true);
    }
  }, [queryParams]);
  return (
    <>
      <StyledPayment>
        <div className="header">
          <div className="suit-info">
            <div className="info-content">
              <span>Color: </span>
              <p>{suitDetail?.suit_color_obj?.name}</p>
            </div>
            <div className="info-content">
              <span>Tie:</span>
              <p>
                {suitDetail?.tie_type_obj?.price === 0
                  ? 'Opted out'
                  : `${suitDetail?.tie_type_obj?.name} (${suitDetail?.tie_color_obj?.name})`}
              </p>
            </div>
            <div className="info-content">
              <span>Shirt: </span>
              <p>{suitDetail?.shirt_color_obj?.price === 0 ? 'Opted out' : suitDetail?.shirt_color_obj?.name}</p>
            </div>
            <div className="info-content">
              <span>Shoes: </span>
              <p>{suitDetail?.more_item_obj?.price === 0 ? 'Opted out' : suitDetail?.more_item_obj?.name}</p>
            </div>
          </div>
        </div>
        <div className="wrapper-image">
          {suitDetail?.suit_color_obj?.part ? (
            <SuitBuilder suit={suitDetail} />
          ) : (
            <img src={suitDetail?.suit_color_obj?.file || yourSuit} alt="avatar" />
          )}
          <FiCheck />
        </div>
        <div className="action">
          <StyleButtonOrange disabled={!suitDetail?.status || disableAction} width={197} onClick={() => setVisibleModal(true)}>
            Continue to Payment
          </StyleButtonOrange>
        </div>
      </StyledPayment>
      {suitDetail && (
        <Payment visible={visibleModal} onClose={() => setVisibleModal(false)} suit={suitDetail} onSuccess={onPaySuccessfully} />
      )}
    </>
  );
};

export default GroomsmanPayment;
