import React from 'react';
import { Global, css } from '@emotion/core';

import SourceSansProBold from '../assets/fonts/SourceSansPro-Bold.ttf';
import SourceSansProItalic from '../assets/fonts/SourceSansPro-Italic.ttf';
import SourceSansProLight from '../assets/fonts/SourceSansPro-Light.ttf';
import SourceSansProRegular from '../assets/fonts/SourceSansPro-Regular.ttf';
import SourceSansProSemiBold from '../assets/fonts/SourceSansPro-SemiBold.ttf';
import PoppinsSemiBold from '../assets/fonts/Poppins-SemiBold.ttf';
import PoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
import PoppinsBold from '../assets/fonts/Poppins-Bold.ttf';
import PoppinsItalic from '../assets/fonts/Poppins-Italic.ttf';
import PoppinsLight from '../assets/fonts/Poppins-Light.ttf';
import RobotoMedium from '../assets/fonts/Roboto-Medium.ttf';
import RobotoRegular from '../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';

import 'antd/dist/antd.css';
// TODO init Global Style
const GlobalStyle: React.FC = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Source Sans Pro Bold';
          src: url(${SourceSansProBold}) format('TrueType');
        }
        @font-face {
          font-family: 'Source Sans Pro Italic';
          src: url(${SourceSansProItalic}) format('TrueType');
        }
        @font-face {
          font-family: 'Source Sans Pro Light';
          src: url(${SourceSansProLight}) format('TrueType');
        }
        @font-face {
          font-family: 'Source Sans Pro Regular';
          src: url(${SourceSansProRegular}) format('TrueType');
        }
        @font-face {
          font-family: 'Source Sans Pro Semi Bold';
          src: url(${SourceSansProSemiBold}) format('TrueType');
        }
        @font-face {
          font-family: 'Poppins Semi Bold';
          src: url(${PoppinsSemiBold}) format('TrueType');
        }
        @font-face {
          font-family: 'Poppins Regular';
          src: url(${PoppinsRegular}) format('TrueType');
        }
        @font-face {
          font-family: 'Poppins Bold';
          src: url(${PoppinsBold}) format('TrueType');
        }
        @font-face {
          font-family: 'Poppins Italic';
          src: url(${PoppinsItalic}) format('TrueType');
        }
        @font-face {
          font-family: 'Poppins Light';
          src: url(${PoppinsLight}) format('TrueType');
        }
        @font-face {
          font-family: 'Roboto Medium';
          src: url(${RobotoMedium}) format('TrueType');
        }
        @font-face {
          font-family: 'Roboto Bold';
          src: url(${RobotoBold}) format('TrueType');
        }
        @font-face {
          font-family: 'Roboto Regular';
          src: url(${RobotoRegular}) format('TrueType');
        }
        body,
        html {
          height: 100%;
        }
        body {
          font-family: 'Source Sans Pro Regular';
          font-weight: 400;
          color: #304251;
          #root {
            width: 100%;
            height: 100%;
          }
        }
        :root {
          font-size: 14px;
        }
        /*  */
        &:hover {
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            color: #cbcfd3;
            background-color: #cbcfd3;
          }
        }
        ::-webkit-scrollbar {
          width: 0.5em;
          height: 0.5em;
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
        ::-webkit-scrollbar-track {
          border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
        .ant-modal {
          color: #304251;
        }
        .ant-form-item-label {
          label {
            font-family: 'Poppins Semi Bold';
            color: #304251;
          }
        }
        .asterisk {
          color: red;
        }
        p {
          margin: 0;
        }
        .no-data {
          grid-column: 1/3;
          display: grid;
          align-items: center;
          justify-content: center;
          height: 100%;
          min-height: 309px;
          font-size: 22px;
        }
      `}
    />
  );
};

export default GlobalStyle;
