import { api } from '@goldfishcode/modern-groom-api-sdk';
import { IUploadFile } from '@goldfishcode/modern-groom-api-sdk/libs/api/upload/models';

export interface UploadFile extends IUploadFile {
  url?: string;
}

export const uploadImage = async (file: File): Promise<UploadFile> => {
  const formData = new FormData();
  formData.append('file', file);
  return api.UploadFile.uploadImage(formData);
};

export const uploadMultiImage = (files: File[]): Promise<UploadFile[]> => {
  const formData = new FormData();
  for (let index = 0; index < files.length; index++) {
    formData.append('file', files[index]);
  }
  return api.UploadFile.uploadMultiImages(formData);
};

export const uploadFile = (file: File): Promise<UploadFile> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('folder_name', 'file_upload');
  return api.UploadFile.upload(formData);
};

export const uploadMultiFiles = (files: File[]): Promise<UploadFile[]> => {
  const formData = new FormData();
  for (let index = 0; index < files.length; index++) {
    if (files[index]) {
      formData.append('file', files[index]);
    }
  }
  formData.append('folder_name', 'file_upload');
  return api.UploadFile.uploadMultiFiles(formData);
};
