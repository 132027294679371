import { api } from '@goldfishcode/modern-groom-api-sdk';
import { InviteGroomsMenParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/user';
import { Link, RouteComponentProps, navigate } from '@reach/router';
import { Modal, message } from 'antd';
import { remove } from 'lodash';
import React, { FC, useContext, useEffect } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import iconShirt from '../../../../assets/images/iconShirt.png';
import BackgroundImageCreateWeddingStep1 from '../../../../assets/images/wedding-party-create-background.jpg';
import { definedGroomRouter } from '../../../../constants';
import { RootState } from '../../../../reducers';
import { setListEmail } from '../../../../reducers/register-groom/action';
import { setActiveParty, setUser } from '../../../../reducers/user/action';
import { colors } from '../../../../styles/definedStyle';
import { AppContext } from '../../../contexts/AppContext';
import GroomsmenItem from '../../../ui/GroomsmenItem';
import { StyleButtonOrange } from '../../../ui/Styled';
import { StyleRegisterGroomStep3 } from '../StyleRegisterGroom';
import RegisterGroomStep3Form, { RegisterGroomStep3FormSchema } from './RegisterGroomStep3Form';

interface WeddingPartyRegisterStep1Props extends RouteComponentProps {
  activeStep(step: number): void;
}

export interface Groomsmen extends RegisterGroomStep3FormSchema {
  id: string;
}

const RegisterGroomStep3: FC<WeddingPartyRegisterStep1Props> = ({ activeStep }) => {
  const appContext = useContext(AppContext);
  const groomsmanList = useSelector((state: RootState) => state.registerGroomReducer.listEmailStep3);
  const currentActiveParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);

  const disableAction = !currentActiveParty?.active;
  const dispatch = useDispatch();

  const onAdd = (data: RegisterGroomStep3FormSchema) => {
    const isExistInList = groomsmanList.some((groomsman) => groomsman.email === data.email);
    if (!isExistInList) {
      dispatch(setListEmail([...groomsmanList, data]));
    } else {
      message.error('This email already exists in the list');
    }
  };

  const onRemove = (email: string) => {
    Modal.confirm({
      content: 'Are you sure you want to remove this guest from your party?',
      okText: 'Remove',
      cancelText: 'Cancel',
      centered: true,
      icon: null,
      className: 'confirm-yes-no',
      bodyStyle: {
        textAlign: 'center',
      },
      cancelButtonProps: {
        style: {
          width: 90,
        },
      },
      okButtonProps: {
        style: {
          background: colors.blueDark,
          borderColor: colors.blueDark,
          width: 90,
          marginLeft: 15,
        },
      },
      onOk: async () => {
        const bk = [...groomsmanList];
        remove(bk, (v) => v.email === email);
        dispatch(setListEmail(bk));
      },
    });
  };

  const onFinish = async () => {
    appContext.setLoading(true);
    try {
      const dataMap: InviteGroomsMenParams[] = groomsmanList.map((groomsman) => ({
        email: groomsman.email,
        first_name: groomsman.firstName,
        last_name: groomsman.lastName,
        user_role: groomsman.role,
      }));
      if (dataMap.length > 0) {
        await api.User.inviteGroomsMen(dataMap);
      }
      const profile = await api.User.updateProfile({ profile_completed: true });
      if (currentActiveParty) {
        const newActiveParty = { ...currentActiveParty, profile_completed: true };
        dispatch(setActiveParty(newActiveParty));
        dispatch(setUser(profile));
        dispatch(setListEmail([]));
        appContext.setLoading(false);
        if (suitDetail?.suit_name) {
          navigate(definedGroomRouter.yourSuit);
        } else {
          navigate(definedGroomRouter.yourProfile);
        }
      }
    } catch (error) {
      appContext.setLoading(false);
      message.error((error as Error).message);
    }
  };

  useEffect(() => {
    appContext.setBackground(BackgroundImageCreateWeddingStep1);
    activeStep(2);
    if (!currentActiveParty) return;
    if (currentActiveParty.opt_out_measurement) {
      navigate(`/wedding-party/register/step1`);
    }
  }, [currentActiveParty]);

  return (
    <StyleRegisterGroomStep3>
      <section className="back">
        <Link to={`/wedding-party/register/step${currentActiveParty?.opt_out_measurement ? 1 : 2}`}>
          <FaAngleLeft />
          GO BACK
        </Link>
      </section>
      <section className="icon">
        <img src={iconShirt} alt="shirt" />
      </section>
      <h3 className="title">Invite Your Wedding Party</h3>
      <RegisterGroomStep3Form onSubmit={onAdd} />
      <section className="list-groomsman">
        <span className="title">Current List of Groomsmen to Invite</span>
        <div className="list">
          {groomsmanList.map((groomsman) => (
            <GroomsmenItem
              key={groomsman.email}
              email={groomsman.email}
              firstName={groomsman.firstName}
              lastName={groomsman.lastName}
              role={groomsman.role}
              onRemove={onRemove}
              isShowRemindButton={!disableAction}
              isShowRemoveButton={!disableAction}
            />
          ))}
        </div>
      </section>
      <div className="action">
        <StyleButtonOrange width={155} onClick={() => onFinish()}>
          Finish
        </StyleButtonOrange>
      </div>
    </StyleRegisterGroomStep3>
  );
};

export default RegisterGroomStep3;
