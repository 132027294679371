import { IPagination } from '@goldfishcode/modern-groom-api-sdk/libs/type';
import { IListPagination } from '../reducers';

// eslint-disable-next-line no-control-regex
export const RegPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d)\S{8,30}$/);
export const RegCanadaPostalCode = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
export const RegUSPostalCode = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
export const RegPhoneNumber = new RegExp(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/);
export const pageSize = 20;

export const definedGroomRouter = {
  weddingPartyList: '/groom/wedding-list',
  activityList: '/groom/activity-list',
  suit: '/groom/suit-list',
  yourSuit: '/groom/your-suit',
  yourProfile: '/groom/your-profile',
  returnMainSite: 'https://themoderngroom.com/',
  logout: 'logout',
};

export const definedGroomsmanRouter = {
  yourSuit: '/groomsman/your-suit',
  yourProfile: '/groomsman/your-profile',
  returnMainSite: 'https://themoderngroom.com/',
  logout: 'logout',
};

export const videoUrl = {
  MEASURING_THE_JACKET_WAIST: 'https://modern-groom-videos.s3.amazonaws.com/Jacket+Waist+-+Belly.mp4',
  MEASURING_THE_NECK: 'https://modern-groom-videos.s3.amazonaws.com/Measuing+the+Neck.mp4',
  MEASURING_THE_SLEEVE: 'https://modern-groom-videos.s3.amazonaws.com/Measuing+the+Sleeve.mp4',
  MEASURING_THE_SEAT: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Seat.mp4',
  MEASURING_THE_CHEST: 'https://modern-groom-videos.s3.amazonaws.com/Measuing+the+Chest.mp4',
  MEASURING_THE_WAIST: 'https://modern-groom-videos.s3.amazonaws.com/Measuring+the+Waist.mp4',
};

export const initialListPage = <T>(): IListPagination<T> => {
  return {
    results: [],
    page: 1,
    count: 0,
  };
};

export const mapResultGetList = <T>(data: IPagination<T>, page: number): IListPagination<T> => {
  return {
    results: data.results,
    count: data.count,
    page,
  };
};

export enum TieType {
  NECK_TIE = 'Neck tie',
  BOW_TIE = 'Bow tie',
  OPT_OUT_OF_TIE = 'Opt-out of tie',
}

export enum CountryValue {
  USA = 'US',
  CANADA = 'CA',
}

export enum ShippingType {
  TWO_DAY_SHIPPING = '2_day_shipping',
  STANDARD = 'standard',
}

export const Currency = {
  USD: {
    unit: 'USD',
    symbol: '$',
  },
  CAD: {
    unit: 'CAD',
    symbol: 'C$',
  },
};
