import * as yup from 'yup';
import { RegCanadaPostalCode, RegPhoneNumber, RegUSPostalCode } from '../../constants';

export enum PaymentFormKey {
  couponCode = 'couponCode',
  cardNumber = 'cardNumber',
  cardExpiry = 'cardExpiry',
  cardCVC = 'cardCVC',
  isAgree = 'isAgree',
  currency = 'currency',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  state = 'state',
  zipcode = 'zipcode',
  country = 'country',
  shippingFirstName = 'shippingFirstName',
  shippingLastName = 'shippingLastName',
  shippingPhone = 'shippingPhone',
  activeBilling = 'activeBilling',
  billingAddress = 'billingAddress',
  billingFirstName = 'billingFirstName',
  billingLastName = 'billingLastName',
  billingCity = 'billingCity',
  billingState = 'billingState',
  billingCountry = 'billingCountry',
  billingZipcode = 'billingZipcode',
}

export type PaymentForm = {
  [PaymentFormKey.couponCode]?: string;
  [PaymentFormKey.cardNumber]?: string;
  [PaymentFormKey.cardExpiry]?: string;
  [PaymentFormKey.cardCVC]?: string;
  [PaymentFormKey.isAgree]: boolean;
  [PaymentFormKey.currency]: string;
  [PaymentFormKey.addressLine1]: string;
  [PaymentFormKey.addressLine2]: string;
  [PaymentFormKey.city]: string;
  [PaymentFormKey.state]: string;
  [PaymentFormKey.zipcode]: string;
  [PaymentFormKey.country]: string;
  [PaymentFormKey.shippingFirstName]: string;
  [PaymentFormKey.shippingLastName]: string;
  [PaymentFormKey.shippingPhone]: string;
  [PaymentFormKey.activeBilling]: boolean;
  [PaymentFormKey.billingFirstName]?: string;
  [PaymentFormKey.billingLastName]?: string;
  [PaymentFormKey.billingAddress]?: string;
  [PaymentFormKey.billingCity]?: string;
  [PaymentFormKey.billingState]?: string;
  [PaymentFormKey.billingCountry]?: string;
  [PaymentFormKey.billingZipcode]?: string;
};

export const BillingListKey = [
  PaymentFormKey.billingFirstName,
  PaymentFormKey.billingLastName,
  PaymentFormKey.billingAddress,
  PaymentFormKey.billingCity,
  PaymentFormKey.billingState,
  PaymentFormKey.billingCountry,
  PaymentFormKey.billingZipcode,
];

export const useSchema = (states: any[], isDisabledStripeForm: boolean, isCanada: (countryId: string) => boolean) => {
  return yup.object().shape<PaymentForm>({
    [PaymentFormKey.couponCode]: yup.string(),
    [PaymentFormKey.cardNumber]: !isDisabledStripeForm ? yup.string().required('Card number is required.') : yup.string(),
    [PaymentFormKey.cardExpiry]: !isDisabledStripeForm ? yup.string().required('ExpDate is required.') : yup.string(),
    [PaymentFormKey.cardCVC]: !isDisabledStripeForm ? yup.string().required('Security Code is required.') : yup.string(),
    [PaymentFormKey.isAgree]: yup.boolean(),
    [PaymentFormKey.currency]: yup.string().required('Currency is required'),
    [PaymentFormKey.addressLine1]: yup
      .string()
      .transform((v) => v.trim())
      .required('Address line 1 is required'),
    [PaymentFormKey.addressLine2]: yup.string(),
    [PaymentFormKey.country]: yup
      .string()
      .transform((v) => v.trim())
      .required('Country is required.'),
    [PaymentFormKey.state]: yup.string().required('State is required.'),
    [PaymentFormKey.city]: yup
      .string()
      .required()
      .when(PaymentFormKey.state, (state: string, schema) => {
        const objectState = states.find((item) => item.id === state);
        if (objectState?.postal_code !== 'DC') {
          return schema.required('City is required.');
        }
        return schema;
      }),
    [PaymentFormKey.zipcode]: yup
      .string()
      .transform((v) => v.trim())
      .required('Zipcode is required.')
      .when(PaymentFormKey.country, (country: string, schema) => {
        if (!country) {
          return schema.test(PaymentFormKey.zipcode, 'Please select country.', () => false);
        }
        if (isCanada(country)) {
          return schema.test(PaymentFormKey.zipcode, 'Zipcode is invalid.', (v: string) => RegCanadaPostalCode.test(v));
        }
        return schema.test(PaymentFormKey.zipcode, 'Zipcode is invalid.', (v: string) => RegUSPostalCode.test(v));
      }),
    [PaymentFormKey.shippingFirstName]: yup
      .string()
      .transform((v) => v.trim())
      .required('First name is required.'),

    [PaymentFormKey.shippingLastName]: yup
      .string()
      .transform((v) => v.trim())
      .required('Last name is required.'),
    [PaymentFormKey.shippingPhone]: yup
      .string()
      .required('Phone number is required')
      .test(PaymentFormKey.shippingPhone, 'Phone number is invalid', (v) => RegPhoneNumber.test(v || '')),
    [PaymentFormKey.activeBilling]: yup.boolean(),
    [PaymentFormKey.billingFirstName]: yup.string().when(PaymentFormKey.activeBilling, {
      is: true,
      then: yup
        .string()
        .transform((v) => v.trim())
        .required('First name is required.'),
    }),
    [PaymentFormKey.billingLastName]: yup.string().when(PaymentFormKey.activeBilling, {
      is: true,
      then: yup
        .string()
        .transform((v) => v.trim())
        .required('Last name is required.'),
    }),
    [PaymentFormKey.billingAddress]: yup.string().when(PaymentFormKey.activeBilling, {
      is: true,
      then: yup
        .string()
        .transform((v) => v.trim())
        .required('Address is required.'),
    }),
    [PaymentFormKey.billingCountry]: yup.string().when(PaymentFormKey.activeBilling, {
      is: true,
      then: yup.string().required('Country is required.'),
    }),
    [PaymentFormKey.billingState]: yup.string().when(PaymentFormKey.activeBilling, {
      is: true,
      then: yup.string().required('State is required.'),
    }),
    [PaymentFormKey.billingCity]: yup.string().when(PaymentFormKey.activeBilling, {
      is: true,
      then: yup.string().when(PaymentFormKey.billingState, (state: string, schema) => {
        const objectState = states.find((item) => item.id === state);
        if (objectState?.postal_code !== 'DC') {
          return schema.required('City is required.');
        }
        return yup.string();
      }),
    }),
    [PaymentFormKey.billingZipcode]: yup.string().when(PaymentFormKey.activeBilling, {
      is: true,
      then: yup
        .string()
        .transform((v) => v.trim())
        .required('Zipcode is required.')
        .when('billingCountry', (billingCountry: string, schema) => {
          if (!billingCountry) {
            return schema.test(PaymentFormKey.zipcode, 'Please select country.', () => false);
          }
          if (isCanada(billingCountry)) {
            return schema.test(PaymentFormKey.zipcode, 'Zipcode is invalid.', (v: string) => RegCanadaPostalCode.test(v));
          }
          return schema.test(PaymentFormKey.zipcode, 'Zipcode is invalid.', (v: string) => RegUSPostalCode.test(v));
        }),
    }),
  });
};
