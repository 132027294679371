import { navigate, RouteComponentProps } from '@reach/router';
import React, { FC, useEffect } from 'react';

interface Props extends RouteComponentProps {
  link: string;
}

const DefaultRouter: FC<Props> = ({ link }) => {
  useEffect(() => {
    navigate(`${link + window.location.search}`, { replace: true });
  }, []);
  return <div />;
};

export default DefaultRouter;
