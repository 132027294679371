import styled from '@emotion/styled';
import tw from 'twin.macro';

const StyleChangeEmail = styled.div`
  ${tw`border-0 border-greyLight border-b border-solid border-opacity-25`}

  form {
    .form-email,
    .form-password {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      .ant-form-item {
        display: grid;
        grid-template-rows: 30px 1fr;
        .ant-form-item-control {
          grid-row: 2;
        }
      }
    }
    .btn-save {
      display: grid;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 576px) {
    form {
      .form-email,
      .form-password {
        grid-template-columns: 1fr;
        grid-gap: 0;
        .ant-form-item {
          display: flex;
        }
      }
    }
  }
  padding-top: 30px;
  padding-bottom: 40px;
  .ant-form-item {
    width: 100%;
  }
  .action {
    padding-top: 8px;
  }
`;

export { StyleChangeEmail };
