import styled from '@emotion/styled';
import tw from 'twin.macro';

const StyleWeddingPartyList = styled.div`
  .list-groomsmen {
    margin-top: 25px;
    .list-groomsmen-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      .title {
        ${tw`font-Poppins-Semi-Bold`};
        justify-self: start;
        font-size: 14px;
        line-height: 21px;
        margin-top: 25px;
      }
      .remind-all {
        button {
          ${tw`bg-orange text-white`}
          display:grid;
          grid-template-columns: max-content max-content;
          grid-gap: 5px;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
        }
        justify-self: end;
      }
    }

    .list {
      ${tw`border border-solid w-full border-greyBlack`}
      height: 345px;
      margin-top: 10px;
      overflow: auto;
      overflow: overlay;
    }
    .spin {
      ${tw`py-2 text-center`}
    }
  }
  .invite {
    margin-top: 31px;
    .title {
      ${tw`font-Poppins-Semi-Bold text-center`}
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
`;

export { StyleWeddingPartyList };
