import { navigate, useLocation } from '@reach/router';
import { Menu } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { definedGroomsmanRouter } from '../../constants';
import { StyleMenuDashboard } from './StyleDashboard';

interface MenuDashboardProps {
  className: string;
}

const MenuDashboardGroomsman: FC<MenuDashboardProps> = ({ className }) => {
  const [keyActive, setKeyActive] = useState<string[]>(['']);
  const location = useLocation();
  const suitDetail = useSelector((state: RootState) => state.suitReducer.suitDetail);

  const handleClick = (e: any) => {
    navigate(e.key);
    setKeyActive([e.key]);
  };
  useEffect(() => {
    setKeyActive([location.pathname]);
  }, [location.pathname]);

  const MenuList = (
    <Menu onClick={handleClick} selectedKeys={keyActive} mode="inline">
      <Menu.Item key={definedGroomsmanRouter.yourProfile}>Your Profile</Menu.Item>
      {suitDetail && suitDetail.suit_name && <Menu.Item key={definedGroomsmanRouter.yourSuit}>Your Suit</Menu.Item>}
    </Menu>
  );
  return (
    <>
      <StyleMenuDashboard className={className}>{MenuList}</StyleMenuDashboard>
    </>
  );
};

export default MenuDashboardGroomsman;
