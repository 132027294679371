import React, { FC } from 'react';
import { Button } from 'antd';
import { StyleResetPasswordStep, StyleFooterModal } from './StyleResetPassword';

interface SuccessPasswordStep4Props {
  onFinalReset: () => void;
}
const SuccessPasswordStep4: FC<SuccessPasswordStep4Props> = (props) => {
  const { onFinalReset } = props;
  return (
    <>
      <StyleResetPasswordStep>
        <p className="reset">Password Reset Complete</p>
        <p className="title">
          Your password has been
          <br /> reset successfully!
        </p>
      </StyleResetPasswordStep>
      <StyleFooterModal className="footer" step={4}>
        <Button key="submit" type="primary" onClick={() => onFinalReset()}>
          Okay
        </Button>
      </StyleFooterModal>
    </>
  );
};

export default SuccessPasswordStep4;
