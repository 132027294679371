import { IMemberRoles, IWeddingPartyMember } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { Button, Empty } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { UserRole } from '../../../enum';
import { RootState } from '../../../reducers';
import { StyledScrollIndex } from '../../ui/Styled';
import { StyleListWedding } from './StyleListWedding';

interface ListWeddingProps {
  onLogOut(): void;
  onChooseWedding(party: IWeddingPartyMember): void;
}
const ListWedding: FC<ListWeddingProps> = (props) => {
  const { onLogOut, onChooseWedding } = props;
  const listParty = useSelector((state: RootState) => state.userReducer.listParty);

  const addUserRoleText = (role: IMemberRoles) => {
    let article = '';
    switch (true) {
      case role?.key === UserRole.GROOM:
        article = 'the';
        break;
      case /^[aeiou]/i.test(role.key):
        article = 'an';
        break;
      default:
        article = 'a';
        break;
    }
    return `You are ${article} ${role?.value}`;
  };

  return (
    <StyleListWedding visible width={514} centered closable={false} footer={null}>
      <section className="back">
        <Button type="text" onClick={onLogOut}>
          <FaAngleLeft />
          LOG OUT
        </Button>
      </section>
      <section className="header">
        <h3>Choose a Wedding Party</h3>
      </section>

      {listParty && listParty.length > 0 && (
        <StyledScrollIndex className="list">
          {listParty.map((party) => (
            <div key={party.id} className="wedding-item" role="button" tabIndex={0} onClick={() => onChooseWedding(party)}>
              <section className="item">
                <section className="left">
                  <div className="name">{party.party_name}</div>
                  <div className="role">{addUserRoleText(party?.user_role)}</div>
                  <div className="date">
                    {party.wedding_date ? `Wedding Date: ${moment(party.wedding_date).format('MM/DD/YY')}` : 'No Date Selected'}
                  </div>
                </section>
                <section className="arrow">
                  <FiArrowRight size={22} />
                </section>
              </section>
            </div>
          ))}
        </StyledScrollIndex>
      )}
      {(!listParty || listParty.length <= 0) && <Empty />}
    </StyleListWedding>
  );
};

export default ListWedding;
