import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message, Button } from 'antd';
import { api } from '@goldfishcode/modern-groom-api-sdk';
import BaseInputPassword from '../ui/InputPassword';
import { StyleResetPasswordStep3, StyleFooterModal } from './StyleResetPassword';
import { RegPassword } from '../../constants';

interface NewPasswordStep3Props {
  otp: string;
  email: string;
  onGetNewData: (email: string) => void;
}
interface SubmitProps {
  newPassword: string;
  confirmNewPassword: string;
}
interface SubmitProps {
  newPassword: string;
  confirmNewPassword: string;
}
const schema = yup.object().shape({
  newPassword: yup.string().required('Password is required'),
  confirmNewPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword')], 'Password and confirm password do not match'),
});

const NewPasswordStep3: FC<NewPasswordStep3Props> = (props) => {
  const { control, handleSubmit, errors } = useForm<SubmitProps>({
    resolver: yupResolver(schema),
  });
  const { otp, email, onGetNewData } = props;
  const onNewPassword = async (newPassword: string, otpUser: string, emailUser: string) => {
    try {
      await api.Auth.resetPasswordConfirm(newPassword, otpUser, emailUser);
      onGetNewData(newPassword);
    } catch (error) {
      message.error((error as Error).message);
    }
  };
  const onSubmit = handleSubmit((data: SubmitProps) => {
    const { newPassword } = data;
    const check = RegPassword.test(newPassword);
    if (check) {
      onNewPassword(newPassword, otp, email);
    } else {
      message.error('Password minimum eight characters, at least one letter, one number and one special character');
    }
  });
  return (
    <>
      <StyleResetPasswordStep3>
        <p className="reset">Reset Password</p>
        <p className="title">Enter your new password below:</p>
        <Controller
          name="newPassword"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <BaseInputPassword onChange={onChange} type="password" value={value} placeholder="Enter New Password" className="new-email" />
          )}
        />
        <p className="error">{errors?.newPassword?.message}</p>
        <Controller
          name="confirmNewPassword"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <BaseInputPassword
              onChange={onChange}
              type="password"
              value={value}
              placeholder="Confirm New Password"
              className="confirm-email"
            />
          )}
        />
        <p className="error">{errors?.confirmNewPassword?.message}</p>
      </StyleResetPasswordStep3>
      <StyleFooterModal className="footer">
        <Button key="submit" type="primary" onClick={() => onSubmit()}>
          Confirm
        </Button>
      </StyleFooterModal>
    </>
  );
};

export default NewPasswordStep3;
