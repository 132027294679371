import styled from '@emotion/styled';
import { IWeddingPartyMember } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import tw from 'twin.macro';

interface Props {
  activeParty?: IWeddingPartyMember;
}

const StyleYourProfileGroomsman = styled.div<Props>`
  .wedding-info {
    padding: 40px 0px;
    ${(props) => (!props.activeParty?.opt_out_measurement ? tw`border-0 border-greyLight border-b border-solid border-opacity-25` : '')}
  }
  .user-step-3 {
    margin-top: 40px;
    ${tw`border-0 border-greyLight border-t border-solid border-opacity-25`}
  }
`;

export { StyleYourProfileGroomsman };
