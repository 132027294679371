import { api } from '@goldfishcode/modern-groom-api-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, message } from 'antd';
import BaseSelectOption, { DataSelectOption } from 'components/ui/SelectOption';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { RootState } from '../../../../reducers';
import { AppContext } from '../../../contexts/AppContext';
import BaseInput from '../../../ui/Input';
import { StyledRegisterGroomStep3Form } from '../StyleRegisterGroom';

export interface RegisterGroomStep3FormSchema {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface RegisterGroomStep3FormProps {
  onSubmit(data: any): void;
  disableAction?: boolean;
}
const RegisterGroomStep3Form: FC<RegisterGroomStep3FormProps> = ({ onSubmit, disableAction }) => {
  const appContext = useContext(AppContext);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const [listPartyRoles, setListPartyRoles] = useState<DataSelectOption[]>([]);
  const formSchema = yup.object().shape<RegisterGroomStep3FormSchema>({
    email: yup.string().required('This field is required.').email('Email is invalid'),
    firstName: yup.string().strict(false).trim().required('This field is required.'),
    lastName: yup.string().strict(false).trim().required('This field is required.'),
    role: yup.string().required('This field is required.'),
  });

  const { handleSubmit, control, reset, formState } = useForm<RegisterGroomStep3FormSchema>({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
  });

  const submitForm = handleSubmit(async (data) => {
    if (!activeParty) return;
    appContext.setCallStackLoading(true);
    try {
      const isExist = await api.User.existsParty(data.email, activeParty?.wedding_party);
      if (isExist) {
        message.error('Email has been added in the list!');
        return;
      }
      reset({
        email: '',
        firstName: '',
        lastName: '',
        role: '',
      });
      onSubmit(data);
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setCallStackLoading(false);
    }
  });

  useEffect(() => {
    if (activeParty) {
      setListPartyRoles(
        activeParty.party_roles.map((role) => {
          return {
            id: role.key,
            name: role.value,
          };
        }),
      );
    }
  }, [activeParty]);

  return (
    <StyledRegisterGroomStep3Form className="add-member-form">
      <Form layout="vertical" className="form">
        <div className="input-row row-1">
          <p className="label">
            Enter A Party Member’s Name <span className="asterisk">*</span>
          </p>
          <div className="wrapper">
            <Form.Item
              className="first-name"
              validateStatus={formState.errors.firstName ? 'error' : ''}
              help={formState.errors.firstName?.message}
            >
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ value, onChange }) => (
                  <BaseInput
                    value={value}
                    onChange={(e) => onChange(e.target.value.trim())}
                    placeholder="First Name"
                    disabled={disableAction}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              className="last-name"
              validateStatus={formState.errors.lastName ? 'error' : ''}
              help={formState.errors.lastName?.message}
            >
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ value, onChange }) => (
                  <BaseInput
                    value={value}
                    onChange={(e) => onChange(e.target.value.trim())}
                    placeholder="Last Name"
                    disabled={disableAction}
                  />
                )}
              />
            </Form.Item>
          </div>
        </div>
        <div className="input-row row-2">
          <div className="email">
            <p className="label">
              Enter A Party Member’s Email <span className="asterisk">*</span>
            </p>
            <Form.Item className="email" validateStatus={formState.errors.email ? 'error' : ''} help={formState.errors.email?.message}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ value, onChange }) => (
                  <BaseInput
                    value={value}
                    onChange={(e) => onChange(e.target.value.trim())}
                    placeholder="Email Address"
                    disabled={disableAction}
                  />
                )}
              />
            </Form.Item>
          </div>
          <div className="role">
            <p className="label">
              Role <span className="asterisk">*</span>
            </p>
            <Form.Item validateStatus={formState.errors.role ? 'error' : ''} help={formState.errors.role?.message}>
              <Controller
                name="role"
                control={control}
                defaultValue=""
                render={({ value, onChange }) => (
                  <BaseSelectOption data={listPartyRoles} placeholder="Select Role" onChange={onChange} dataSelect={value} />
                )}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      <Button className="circle-add" onClick={() => submitForm()} shape="circle" disabled={disableAction}>
        +
      </Button>
    </StyledRegisterGroomStep3Form>
  );
};

export default RegisterGroomStep3Form;
