import styled from '@emotion/styled';
import { Modal } from 'antd';
import tw from 'twin.macro';

const StyleUploadPhoto = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-body {
      ${tw`text-center`}
      padding-top: 57px;
      padding-bottom: 46px;
      .title {
        font-size: 24px;
        font-weight: 700;
      }
      .upload-img,
      .describe-build {
        display: grid;
        justify-items: center;
        text-align: start;
        .ant-form-item {
          margin: 0;
          .ant-form-item-control-input-content {
            display: grid;
            justify-items: center;
          }
        }
        .ant-form-item-explain-error {
          font-size: 12px;
        }
        .ant-image {
          width: 200px;
          height: 200px;
          margin-bottom: 10px;
          img {
            object-fit: cover;
            width: 200px;
            height: 200px;
          }
          .ant-image-mask {
            background: rgba(0, 0, 0, 0.2);
          }
        }
      }
      .header-row {
        ${tw`font-Poppins-Semi-Bold text-blueDark mb-4`}
        font-size: 11px;
        line-height: 17px;
        span {
          color: #ff0000;
        }
      }
      .footer-row {
        ${tw`font-Poppins-Semi-Bold text-blueDark text-opacity-50 mt-2 block`}
        font-size: 9px;
        line-height: 13px;
      }
      .devider {
        margin-top: 30px;
      }
      .btn-done {
        margin-top: 24px;
      }
    }
  }
`;
export { StyleUploadPhoto };
