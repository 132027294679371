import React, { FC } from 'react';
import { StyleButtonOrange } from '../../../../ui/Styled';
import { StyleUploadPhotoSuccessful } from './StyleUploadPhotoSuccessful';

interface UploadPhotoSuccessfulProps {
  visible: boolean;
  onClose(): void;
}
const UploadPhotoSuccessful: FC<UploadPhotoSuccessfulProps> = (props) => {
  const { visible, onClose } = props;
  return (
    <StyleUploadPhotoSuccessful width={514} visible={visible} centered closable={false} footer={null}>
      <h3>Photos Uploaded</h3>
      <h4>Your photos have been sent to our stylist.</h4>
      <h4>We will contact you if adjustments need to be made.</h4>
      <section className="action">
        <StyleButtonOrange width={135} onClick={() => onClose()}>
          Done
        </StyleButtonOrange>
      </section>
    </StyleUploadPhotoSuccessful>
  );
};

export default UploadPhotoSuccessful;
