/* eslint-disable prefer-template */
/* eslint-disable no-underscore-dangle */
import { api } from '@goldfishcode/modern-groom-api-sdk';
import { ICountry, IState } from '@goldfishcode/modern-groom-api-sdk/libs/api/location/models';
import { Coupon, CouponType, IExtraShippingFee, ISuit } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/models';
import { SuitPayParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/params';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Collapse, Form, Modal, Row, message } from 'antd';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import { Method } from '@goldfishcode/modern-groom-api-sdk/libs/api/payment/models';
import { Controller, useForm } from 'react-hook-form';
import { FaAngleLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'styles/definedStyle';
import cardPayment from '../../assets/images/card-payment.png';
import { CountryValue, Currency } from '../../constants';
import { RootState } from '../../reducers';
import { setUser } from '../../reducers/user/action';
import { formatPhone } from '../../util';
import { AppContext } from '../contexts/AppContext';
import { PaymentCheckbox, ToggleBillingCheckbox } from '../ui/CustomCheckbox';
import BaseInput from '../ui/Input';
import BaseSelectOption from '../ui/SelectOption';
import { StyleButtonGrey, StyleButtonOrange } from '../ui/Styled';
import CreditCard from './CreditCard';
import { StylePayment } from './StylePayment';
import SuitPaymentConfirm from './modal/SuitPaymentConfirm';
import { PaymentForm, PaymentFormKey, useSchema } from './schema';

declare global {
  interface Window {
    Stripe: (arg) => any;
  }
}

interface PaymentProps {
  visible: boolean;
  onClose(): void;
  suit?: ISuit;
  onSuccess(): void;
}

const currencyItems = [
  {
    id: 'USD',
    name: 'USD',
  },
  {
    id: 'CAD',
    name: 'CAD',
  },
];

interface AppliedCoupon extends Coupon {
  discount: number;
}

const Payment: FC<PaymentProps> = (props) => {
  const appContext = useContext(AppContext);
  const { visible, onClose, onSuccess, suit } = props;
  const [isShowPopupConfirmed, setShowPopupConfirmed] = useState(false);
  const profile = useSelector((state: RootState) => state.userReducer.userProfile);
  const paymentInfo = profile?.user_payment;
  const paymentRef = useRef<HTMLElement>(null);
  const btnConfirm = useRef<HTMLButtonElement | null>(null);

  const [suitPrice, setSuitPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [countries, setCountries] = useState<Array<ICountry>>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  // const [selectedBillingCountry, setSelectedBillingCountry] = useState<ICountry>();
  const [states, setState] = useState<Array<IState>>([]);
  const [selectedState, setSelectedState] = useState<IState>();
  // const [selectedBillingState, setSelectedBillingState] = useState<IState>();
  const [shippingFee, setShippingFee] = useState<number>(0);
  const [extraShippingFees, setExtraShippingFees] = useState<Array<IExtraShippingFee>>([]);
  const [stateBilling, setStateBilling] = useState<Array<IState>>([]);
  const [coupon, setCoupon] = useState<AppliedCoupon | undefined>(undefined);
  const [isLoadingDiscount, setLoadingDiscount] = useState<boolean>(false);
  const [isDisabledStripeForm, setStateStripeForm] = useState<boolean>(false);
  const [listMethods, setListMethods] = useState<Method[]>();
  const [selectedMethod, setSelectedMethod] = useState<Method>();
  // const paymentForm = useRef<{ cardNumberElement: any; cardExpiryElement: any; cardCvcElement: any } | null>(null);
  const [paymentForm, setPaymentForm] = useState<{
    cardNumberElement: any;
    cardExpiryElement: any;
    cardCvcElement: any;
  }>();
  const stripObj = useRef<any | null>(null);
  const dispatch = useDispatch();

  const isCountryCanada = (countryId: string): boolean => {
    const found = countries.find((f) => f.id === countryId);
    return !!(found && found.iso_code === CountryValue.CANADA);
  };

  const schema = useSchema(states, isDisabledStripeForm, isCountryCanada);

  const { control, formState, trigger, setError, setValue, getValues, errors, watch, clearErrors, reset } = useForm<PaymentForm>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      currency: Currency.USD.unit,
      activeBilling: true,
    },
  });

  const resetFormFields = (listField: PaymentFormKey[]) => {
    listField.forEach((key) => setValue(key, ''));
  };

  const disableStripeForm = (value: boolean) => {
    setStateStripeForm(value);
    if (value) {
      paymentForm?.cardCvcElement.update({ disabled: true, value: '' });
      paymentForm?.cardExpiryElement.update({ disabled: true, value: '' });
      paymentForm?.cardNumberElement.update({ disabled: true, value: '' });
      clearErrors([PaymentFormKey.cardNumber, PaymentFormKey.cardCVC, PaymentFormKey.cardExpiry]);
    } else {
      paymentForm?.cardCvcElement.update({ disabled: false });
      paymentForm?.cardExpiryElement.update({ disabled: false });
      paymentForm?.cardNumberElement.update({ disabled: false });
    }
  };

  const calculateTotalPrice = () => {
    let totalPrice = suitPrice;
    if (shippingFee) {
      totalPrice += shippingFee;
    }
    if (coupon?.discount) {
      totalPrice -= coupon.discount;
    }
    if (totalPrice < 0) {
      totalPrice = 0;
    }
    disableStripeForm(totalPrice === 0);
    setTotalPrice(totalPrice);
  };

  /* Calculate coupon */
  const calculateCoupon = (coupon: Coupon, amount: number) => {
    let discount = 0;
    switch (coupon.type) {
      case CouponType.PERCENTAGE:
        discount = amount * (coupon.amount / 100);
        break;
      case CouponType.FIXED:
        discount = coupon.amount > amount ? amount : coupon.amount;
        break;
      default:
        break;
    }
    return discount;
  };

  const validateCoupon = async () => {
    const couponCode = getValues(PaymentFormKey.couponCode);
    if (suit && suit.id && couponCode) {
      try {
        setLoadingDiscount(true);
        const data = await api.Suit.validateCoupon(couponCode);
        setCoupon({
          ...data,
          discount: calculateCoupon(data, suitPrice),
        });
        clearErrors(PaymentFormKey.couponCode);
        return true;
      } catch (err) {
        setError(PaymentFormKey.couponCode, {
          type: 'manual',
          message: (err as Error).message,
        });
      } finally {
        setLoadingDiscount(false);
      }
    }
    return false;
  };
  const createStripeElm = () => {
    appContext.setCallStackLoading(true);
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
    stripObj.current = stripe;
    const elements = stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap',
        },
      ],
    });
    const style = {
      base: {
        color: '#000',
        lineHeight: '36px',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: 'Source Sans Pro',
        '::placeholder': {
          color: 'rgba(0,0,0,0.6)',
        },
        ':disabled': {
          color: '#979494',
        },
      },
      invalid: {
        color: '#000',
        iconColor: 'blue',
        backgroundColor: '#fff',
      },
    };
    const cardNumberElement = elements.create('cardNumber', {
      style,
      placeholder: 'XXXX XXXX XXXX XXXX',
    });
    cardNumberElement.mount('#card-number-element');

    const cardExpiryElement = elements.create('cardExpiry', {
      style,
      placeholder: 'MM/YY',
    });
    cardExpiryElement.mount('#card-expiry-element');

    const cardCvcElement = elements.create('cardCvc', {
      style,
      placeholder: 'Security Code',
    });
    cardCvcElement.mount('#card-cvc-element');
    cardNumberElement.on('change', (event) => {
      if (event.error) {
        setError(PaymentFormKey.cardNumber, {
          type: 'manual',
          message: event.error.message,
        });
      } else {
        setValue(PaymentFormKey.cardNumber, cardNumberElement, { shouldValidate: true });
      }
    });

    cardNumberElement.on('blur', () => {
      if (!formState.errors.cardNumber) trigger(PaymentFormKey.cardNumber);
    });

    cardExpiryElement.on('change', (event) => {
      if (event.error) {
        setError(PaymentFormKey.cardExpiry, {
          type: 'manual',
          message: event.error.message,
        });
      } else {
        setValue(PaymentFormKey.cardExpiry, cardExpiryElement, { shouldValidate: true });
      }
    });
    cardExpiryElement.on('blur', () => {
      if (!formState.errors.cardExpiry) trigger(PaymentFormKey.cardExpiry);
    });
    cardCvcElement.on('change', (event) => {
      if (event.error) {
        setError(PaymentFormKey.cardCVC, {
          type: 'manual',
          message: event.error.message,
        });
      } else {
        setValue(PaymentFormKey.cardCVC, cardCvcElement, { shouldValidate: true });
      }
    });
    cardCvcElement.on('blur', () => {
      if (!formState.errors.cardCVC) trigger(PaymentFormKey.cardCVC);
    });
    cardCvcElement.on('ready', () => {
      appContext.setCallStackLoading(false);
      setPaymentForm({ cardNumberElement, cardExpiryElement, cardCvcElement });
    });
  };

  const getListPaymentMethod = async () => {
    try {
      const res = await api.Payment.getMethods();
      if (res?.results && res.results.length > 0) {
        setListMethods(res.results);
        setSelectedMethod(res.results.find((method) => !!method.default));
      }
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  const initStripe = () => {
    if (!stripObj.current) {
      appContext.setCallStackLoading(true);
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        createStripeElm();
        appContext.setCallStackLoading(false);
      });
    }
  };

  const onSubmit = async () => {
    const isValidForm = await trigger();
    if (errors.cardNumber || errors.cardExpiry || errors.cardCVC) return;
    if (isValidForm) {
      try {
        appContext.setCallStackLoading(true);
        const form = getValues();
        const params: SuitPayParams = {
          ...(selectedMethod ? { payment_method: selectedMethod.id } : {}),
          suit_id: suit?.id as string,
          ...(!formState.errors?.couponCode && form.couponCode ? { coupon: form.couponCode } : {}),
          shipping_address: {
            line1: form.addressLine1,
            line2: form.addressLine2,
            city: form.city as string,
            state: form.state,
            postal_code: form.zipcode,
            country: form.country,
            first_name: form.shippingFirstName,
            last_name: form.shippingLastName,
            phone: form.shippingPhone.replace(/([^0-9])/g, ''),
          },
        };
        if (form.activeBilling) {
          params.billing_address = {
            line1: form?.billingAddress as string,
            city: form?.billingCity as string,
            state: form?.billingState as string,
            postal_code: form?.billingZipcode as string,
            country: form?.billingCountry as string,
            first_name: form.billingFirstName,
            last_name: form.billingLastName,
          };
        } else {
          params.billing_address = params.shipping_address;
          delete params.billing_address.line2;
        }
        if (!selectedMethod && !isDisabledStripeForm) {
          const resp = await stripObj.current.createToken(paymentForm?.cardNumberElement);
          if (resp.error) {
            throw resp.error;
          } else {
            params.token = resp.token.id;
          }
        }
        const transaction = await api.Suit.pay(params);
        if (transaction.client_secret) {
          const stripeRes = await stripObj.current.confirmCardPayment(transaction.client_secret, {
            payment_method: selectedMethod
              ? selectedMethod?.stripe_payment_method_id
              : {
                  card: {},
                  ...(form.activeBilling
                    ? {
                        billing_details: {
                          name: `${form.billingFirstName} ${form.billingLastName}`,
                          address: {
                            line1: form?.billingAddress as string,
                            city: form?.billingCity as string,
                            state: states.find((state) => state.id === form?.billingState)?.name as string,
                            postal_code: form?.billingZipcode as string,
                            country: countries.find((country) => country.id === form?.billingCountry)?.iso_code as string,
                          },
                        },
                      }
                    : {}),
                },
          });
          if (stripeRes.error) {
            throw stripeRes.error;
          }
        }
        const userInfo = await api.User.me();
        await getListPaymentMethod();
        dispatch(setUser(userInfo));
        setShowPopupConfirmed(true);
      } catch (error) {
        message.error((error as Error).message);
      } finally {
        appContext.setCallStackLoading(false);
      }
    }
  };

  const getCountry = async () => {
    try {
      appContext.setCallStackLoading(true);
      const response = await api.Location.getCountries();
      setCountries(response.results);
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setCallStackLoading(false);
    }
  };

  const getState = async (countrySelected: string): Promise<Array<IState>> => {
    try {
      appContext.setCallStackLoading(true);
      return await api.Location.getStates(countrySelected);
    } catch (error) {
      message.error((error as Error).message);
    } finally {
      appContext.setCallStackLoading(false);
    }
    return [];
  };

  const getListExtraShippingFee = async () => {
    const res = await api.Suit.listExtraShippingFee({ page: 1, limit: 100 });
    if (res?.results && res.results.length > 0) {
      setExtraShippingFees(res.results);
    }
  };

  const deletePaymentMethod = async (id: string) => {
    Modal.confirm({
      content: 'Are you sure you want to remove this method?',
      okText: 'Remove',
      cancelText: 'Cancel',
      centered: true,
      icon: null,
      className: 'confirm-yes-no',
      bodyStyle: {
        textAlign: 'center',
      },
      cancelButtonProps: {
        style: {
          width: 90,
        },
      },
      okButtonProps: {
        style: {
          background: colors.blueDark,
          borderColor: colors.blueDark,
          width: 90,
          marginLeft: 15,
        },
      },
      onOk: async () => {
        try {
          await api.Payment.deleteMethod(id);
          setListMethods((prev) => {
            return prev?.filter((method) => method.id !== id);
          });
          await getListPaymentMethod();
          message.success('Delete method successful.');
        } catch (error) {
          message.error((error as Error).message);
        }
      },
    });
  };

  const setDefaultMethod = async (id: string) => {
    Modal.confirm({
      content: 'Would you like to make this method as default?',
      okText: 'OK',
      cancelText: 'Cancel',
      centered: true,
      icon: null,
      className: 'confirm-yes-no',
      bodyStyle: {
        textAlign: 'center',
      },
      cancelButtonProps: {
        style: {
          width: 90,
        },
      },
      okButtonProps: {
        style: {
          background: colors.blueDark,
          borderColor: colors.blueDark,
          width: 90,
          marginLeft: 15,
        },
      },
      onOk: async () => {
        try {
          await api.Payment.setDefault(id);
          setListMethods((prev) => {
            return prev?.map((method) => {
              return { ...method, default: method.id === id };
            });
          });
          message.success('Set this method as default successfully.');
        } catch (error) {
          message.error((error as Error).message);
        }
      },
    });
  };

  useEffect(() => {
    if (!visible) {
      setCoupon(undefined);
      clearErrors();
      reset();
      disableStripeForm(false);
      return;
    }
    if (countries.length === 0) {
      (async () => getCountry())();
    }
  }, [visible, stripObj.current]);

  useEffect(() => {
    if (!stripObj.current && visible) {
      initStripe();
    }
  }, [stripObj.current, visible]);

  useEffect(() => {
    if (suit) {
      setSuitPrice(parseFloat(suit.price));
    }
  }, [suit]);

  useEffect(() => {
    const countrySelected = getValues(PaymentFormKey.country);
    if (!countrySelected) {
      setState([]);
      setValue(PaymentFormKey.state, undefined);
    } else {
      getState(countrySelected).then((response) => {
        setState(response);
      });
    }
  }, [watch(PaymentFormKey.country)]);

  useEffect(() => {
    const countrySelected = getValues(PaymentFormKey.billingCountry);
    if (!countrySelected) {
      setStateBilling([]);
      setValue(PaymentFormKey.billingState, undefined);
    } else {
      getState(countrySelected).then((response) => {
        const billingState = getValues(PaymentFormKey.billingState);
        const found = response.find((f) => f.id === billingState);
        if (found) {
          setValue(PaymentFormKey.billingState, found.id);
        } else {
          setValue(PaymentFormKey.billingState, '');
        }
        setStateBilling(response);
      });
    }
  }, [watch(PaymentFormKey.billingCountry)]);

  useEffect(() => {
    const countrySelected = getValues(PaymentFormKey.country);
    if (!countrySelected || countries.length === 0) return;
    const country = countries.find((item) => item.id === countrySelected);
    if (country) {
      setSelectedCountry((prev) => {
        if (prev && prev.id && prev.id !== countrySelected) {
          resetFormFields([PaymentFormKey.city, PaymentFormKey.zipcode, PaymentFormKey.state]);
        }
        return country;
      });
    } else {
      resetFormFields([PaymentFormKey.country, PaymentFormKey.city, PaymentFormKey.zipcode, PaymentFormKey.state]);
    }
  }, [watch(PaymentFormKey.country), countries]);

  useEffect(() => {
    if (!getValues(PaymentFormKey.state) || states.length === 0) return;
    const state = states.find((item) => item.id === getValues(PaymentFormKey.state));
    setSelectedState(state);
  }, [watch(PaymentFormKey.state), states]);

  useEffect(() => {
    let shippingFee = 0;
    shippingFee += suit?.shipping_type.fee || 0;
    shippingFee +=
      extraShippingFees.find((item) => {
        if (item.state_code) {
          return item.country_code === selectedCountry?.iso_code && item.state_code === selectedState?.postal_code;
        }
        return item.country_code === selectedCountry?.iso_code;
      })?.fee || 0;

    if (shippingFee) {
      setShippingFee(Math.round(shippingFee * 100) / 100);
    }

    setShippingFee(shippingFee || 0);
  }, [selectedState, watch(PaymentFormKey.currency), extraShippingFees]);

  useEffect(() => {
    if (!paymentForm) return;
    calculateTotalPrice();
  }, [shippingFee, suitPrice, coupon, watch(PaymentFormKey.currency), paymentForm]);

  useEffect(() => {
    if (profile) {
      setValue(PaymentFormKey.shippingFirstName, profile.first_name);
      setValue(PaymentFormKey.shippingPhone, formatPhone(profile.phone));
      setValue(PaymentFormKey.shippingLastName, profile.last_name);
    }
    if (paymentInfo && visible) {
      setValue(PaymentFormKey.currency, paymentInfo.currency.toUpperCase() || Currency.USD.unit);
      setValue(PaymentFormKey.addressLine1, paymentInfo.address_line1);
      setValue(PaymentFormKey.addressLine2, paymentInfo.address_line2);
      setValue(PaymentFormKey.country, paymentInfo.address_country);
      setValue(PaymentFormKey.city, paymentInfo.address_city);
      setValue(PaymentFormKey.state, paymentInfo.address_state);
      setValue(PaymentFormKey.zipcode, paymentInfo.address_zip);
      setValue(PaymentFormKey.isAgree, true);
    }
  }, [profile, visible]);

  useEffect(() => {
    getListExtraShippingFee();
    getListPaymentMethod();
  }, []);

  useEffect(() => {
    disableStripeForm(!!selectedMethod && !!paymentForm);
  }, [paymentForm, selectedMethod, visible]);

  useEffect(() => {
    if (selectedMethod && !!getValues(PaymentFormKey.activeBilling)) {
      setTimeout(() => {
        setValue(PaymentFormKey.billingFirstName, selectedMethod.billing_details?.first_name || '');
        setValue(PaymentFormKey.billingLastName, selectedMethod.billing_details?.last_name || '');
        setValue(PaymentFormKey.billingAddress, selectedMethod.billing_details?.line_1 || '');
        setValue(
          PaymentFormKey.billingCountry,
          countries.find((country) => country.name === selectedMethod.billing_details?.country)?.id || '',
        );
        setValue(PaymentFormKey.billingCity, selectedMethod.billing_details?.city || '');
        setValue(PaymentFormKey.billingState, stateBilling.find((state) => state.name === selectedMethod.billing_details?.state)?.id || '');
        setValue(PaymentFormKey.billingZipcode, selectedMethod.billing_details?.postal_code || '');
      }, 300);
    }
  }, [selectedMethod, countries, stateBilling, watch(PaymentFormKey.activeBilling)]);

  return (
    <>
      <StylePayment width={550} visible={visible} centered closable={false} footer={null}>
        <section className="back">
          <Button type="text" onClick={() => onClose()}>
            <FaAngleLeft />
            GO BACK
          </Button>
        </section>
        <section className="modal-head" ref={paymentRef}>
          <div className="title">Pay for Your Suit</div>
          <div className="info-row info">
            <div className="item">
              <span className="title-small">Selected Suit:</span>
              <span className="text">{suit?.name || 'None'}</span>
            </div>
            <div className="item cost">
              <div className="cost-row cost-of-suit">
                <span className="title-small">Cost Of Suit:</span>
                <span className="text">{Currency[watch(PaymentFormKey.currency)]?.symbol + suitPrice.toFixed(2)}</span>
              </div>
              {suit?.shipping_type?.name && (
                <div className="cost-row shipping-type">
                  <span className="title-small">Shipping Type:</span>
                  <span className="text">{suit && suit.shipping_type?.name}</span>
                </div>
              )}
              {!!shippingFee && (
                <div className="cost-row shipping-fee">
                  <span className="title-small">Shipping Fee:</span>
                  <span className="text">{Currency[watch(PaymentFormKey.currency)]?.symbol + shippingFee.toFixed(2)}</span>
                </div>
              )}
              {!formState.errors.couponCode && coupon?.discount ? (
                <>
                  <div className="cost-row discount">
                    <span className="title-small">
                      Discount:
                      <br /> (Suit only)
                    </span>
                    {!isLoadingDiscount ? (
                      <span className="text">
                        {Currency[watch(PaymentFormKey.currency)]?.symbol}
                        {coupon?.discount.toFixed(2)}
                      </span>
                    ) : (
                      <span className="text loading">...</span>
                    )}
                  </div>
                  <div className="cost-row total">
                    <span className="title-small">Total:</span>
                    {!isLoadingDiscount && coupon.amount ? (
                      <span className="text">{Currency[watch(PaymentFormKey.currency)]?.symbol + totalPrice.toFixed(2)}</span>
                    ) : (
                      <span className="text loading">...</span>
                    )}
                  </div>
                </>
              ) : (
                <div className="cost-row total">
                  <span className="title-small">Total: </span>
                  <span className="text">{Currency[watch(PaymentFormKey.currency)]?.symbol + totalPrice.toFixed(2)}</span>
                </div>
              )}
            </div>
          </div>
        </section>
        <Form autoComplete="off" className="card-info" layout="vertical" requiredMark={false}>
          <div className="currency-payment-method-box">
            <div className="currency-cover">
              <p className="info-title">Currency</p>
              <div>
                <Form.Item validateStatus={formState.errors.currency ? 'error' : ''} help={formState.errors.currency?.message}>
                  <Controller
                    name={PaymentFormKey.currency}
                    control={control}
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={currencyItems}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        dataSelect={value}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="payment-method-cover">
              <p className="info-title hidden">&nbsp;</p>
              <div className="list-card-img">
                <img src={cardPayment} alt="card" />
              </div>
            </div>
          </div>
          <div className="info-row coupon">
            <p className="info-title">Coupon Code</p>
            <div className="coupon-box">
              <Form.Item validateStatus={formState.errors.couponCode ? 'error' : ''}>
                <Controller
                  name={PaymentFormKey.couponCode}
                  control={control}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <BaseInput
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value.trim());
                        if (!e.target.value) {
                          setCoupon(undefined);
                          clearErrors(PaymentFormKey.couponCode);
                        }
                      }}
                      onBlur={() => {
                        if (coupon?.coupon_code !== watch(PaymentFormKey.couponCode) || formState.errors.couponCode) {
                          validateCoupon();
                        }
                      }}
                      placeholder="Coupon Code"
                    />
                  )}
                />
                {formState.errors.couponCode?.message && (
                  <p className="ant-form-item-explain ant-form-item-explain-error">{formState.errors.couponCode?.message}</p>
                )}
              </Form.Item>
              <Button className="apply-coupon-btn" onClick={validateCoupon} disabled={!watch(PaymentFormKey.couponCode)}>
                Apply
              </Button>
            </div>
            <div className="discount">
              {isLoadingDiscount ? (
                <p>...</p>
              ) : (
                !formState.errors.couponCode &&
                coupon?.discount && (
                  <p>{`${coupon?.name}: ${Currency[watch(PaymentFormKey.currency)]?.symbol}${coupon?.discount.toFixed(2)}`}</p>
                )
              )}
            </div>
          </div>

          {listMethods && listMethods.length !== 0 && (
            <div className="info-row payment-methods">
              <h3 className="info-title">Saved Credit Cards</h3>
              <div className="wrapper">
                {listMethods.map((method) => (
                  <CreditCard
                    key={method.id}
                    checked={selectedMethod?.id === method.id}
                    method={method}
                    onDeletePaymentMethod={deletePaymentMethod}
                    onSetDefaultMethod={(id) => {
                      setDefaultMethod(id);
                    }}
                    onSelectMethod={(id) => {
                      setSelectedMethod((prev) => (prev?.id === id ? undefined : method));
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="info-row">
            <p className="info-title">
              Card Number <span className="asterisk">*</span>
            </p>
            <Form.Item
              validateStatus={formState.errors.cardNumber && formState.errors.cardNumber ? 'error' : ''}
              help={!isDisabledStripeForm && formState.errors.cardNumber?.message}
            >
              <Controller
                name={PaymentFormKey.cardNumber}
                control={control}
                defaultValue=""
                label="Card Number"
                render={() => (
                  <div
                    id="card-number-element"
                    className={
                      'field-stripe ant-input ' +
                      (formState.errors.cardNumber ? 'invalid-field' : '') +
                      (isDisabledStripeForm ? ' disabled-input' : '')
                    }
                  />
                )}
              />
            </Form.Item>
          </div>

          <div className="info-row expDate-Cvc">
            <div>
              <Form.Item
                validateStatus={formState.errors.cardExpiry ? 'error' : ''}
                help={!isDisabledStripeForm && formState.errors.cardExpiry?.message}
              >
                <Controller
                  name={PaymentFormKey.cardExpiry}
                  control={control}
                  defaultValue=""
                  render={() => (
                    <div className="card-expiry">
                      <p className="info-title">
                        Exp.date <span className="asterisk">*</span>
                      </p>
                      <div
                        id="card-expiry-element"
                        className={
                          'field-stripe ant-input ' +
                          (formState.errors.cardExpiry ? 'invalid-field' : '') +
                          (isDisabledStripeForm ? ' disabled-input' : '')
                        }
                      />
                    </div>
                  )}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                validateStatus={formState.errors.cardCVC ? 'error' : ''}
                help={!isDisabledStripeForm && formState.errors.cardCVC?.message}
              >
                <Controller
                  name={PaymentFormKey.cardCVC}
                  control={control}
                  defaultValue=""
                  render={() => (
                    <div className="card-cvc">
                      <p className="info-title">
                        Security Code <span>(on back of card) </span> <span className="asterisk">*</span>
                      </p>
                      <div
                        id="card-cvc-element"
                        className={
                          'field-stripe ant-input ' +
                          (formState.errors.cardCVC ? 'invalid-field' : '') +
                          (isDisabledStripeForm ? ' disabled-input' : '')
                        }
                      />
                    </div>
                  )}
                />
              </Form.Item>
            </div>
          </div>
          <div className="info-row shipping-address">
            <p className="info-title">
              Shipping Address <span className="asterisk">*</span>
            </p>
            <div className="shipping-name">
              <div className="first-name">
                <Form.Item
                  validateStatus={formState.errors.shippingFirstName ? 'error' : ''}
                  help={formState.errors.shippingFirstName?.message}
                >
                  <Controller
                    name={PaymentFormKey.shippingFirstName}
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => (
                      <BaseInput
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          trigger(PaymentFormKey.shippingFirstName);
                        }}
                        onBlur={() => {
                          trigger(PaymentFormKey.shippingFirstName);
                        }}
                        placeholder="First Name"
                      />
                    )}
                  />
                </Form.Item>
              </div>
              <div className="last-name">
                <Form.Item
                  validateStatus={formState.errors.shippingLastName ? 'error' : ''}
                  help={formState.errors.shippingLastName?.message}
                >
                  <Controller
                    name={PaymentFormKey.shippingLastName}
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => (
                      <BaseInput
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          trigger(PaymentFormKey.shippingLastName);
                        }}
                        onBlur={() => {
                          trigger(PaymentFormKey.shippingLastName);
                        }}
                        placeholder="Last Name"
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="shipping-phone">
              <Form.Item validateStatus={formState.errors.shippingPhone ? 'error' : ''} help={formState.errors.shippingPhone?.message}>
                <Controller
                  name={PaymentFormKey.shippingPhone}
                  control={control}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <BaseInput
                      value={value}
                      onChange={(e) => {
                        const formattedPhone = formatPhone(e.target.value);
                        if (formattedPhone !== null) {
                          onChange(formattedPhone);
                          trigger(PaymentFormKey.shippingPhone);
                        }
                      }}
                      onBlur={() => {
                        trigger(PaymentFormKey.shippingPhone);
                      }}
                      placeholder="Phone Number"
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="address-line1">
              <Form.Item validateStatus={formState.errors.addressLine1 ? 'error' : ''} help={formState.errors.addressLine1?.message}>
                <Controller
                  name={PaymentFormKey.addressLine1}
                  control={control}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <BaseInput
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                        trigger(PaymentFormKey.addressLine1);
                      }}
                      onBlur={() => {
                        trigger(PaymentFormKey.addressLine1);
                      }}
                      placeholder="Address Line 1"
                    />
                  )}
                />
              </Form.Item>
            </div>
            <div className="address-line2">
              <Form.Item validateStatus={formState.errors.addressLine2 ? 'error' : ''} help={formState.errors.addressLine2?.message}>
                <Controller
                  name={PaymentFormKey.addressLine2}
                  control={control}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <BaseInput
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                        trigger(PaymentFormKey.addressLine2);
                      }}
                      onBlur={() => {
                        trigger(PaymentFormKey.addressLine2);
                      }}
                      placeholder="Address Line 2"
                    />
                  )}
                />
              </Form.Item>
            </div>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item validateStatus={formState.errors.country ? 'error' : ''} help={formState.errors.country?.message}>
                  <Controller
                    name={PaymentFormKey.country}
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={countries}
                        placeholder="Country"
                        onChange={async (v) => {
                          onChange(v);
                          if (formState.errors.zipcode) {
                            trigger(PaymentFormKey.zipcode);
                          }
                        }}
                        dataSelect={value}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item validateStatus={formState.errors.city ? 'error' : ''} help={formState.errors.city?.message}>
                  <Controller
                    name={PaymentFormKey.city}
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => {
                      const objectState = states.find((item) => item.id === watch(PaymentFormKey.state));
                      const isDisableCityInput = objectState?.postal_code === 'DC';
                      return (
                        <BaseInput
                          value={isDisableCityInput ? '' : value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            trigger(PaymentFormKey.city);
                          }}
                          onBlur={() => {
                            trigger(PaymentFormKey.city);
                          }}
                          placeholder="City"
                          disabled={isDisableCityInput}
                        />
                      );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item validateStatus={formState.errors.state ? 'error' : ''} help={formState.errors.state?.message}>
                  <Controller
                    name={PaymentFormKey.state}
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => (
                      <BaseSelectOption
                        data={states}
                        placeholder="State"
                        onChange={async (v) => {
                          onChange(v);
                          await trigger(PaymentFormKey.state);
                          if (formState.errors.city) {
                            await trigger(PaymentFormKey.city);
                          }
                        }}
                        dataSelect={value}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item validateStatus={formState.errors.zipcode ? 'error' : ''} help={formState.errors.zipcode?.message}>
                  <Controller
                    name={PaymentFormKey.zipcode}
                    control={control}
                    defaultValue=""
                    render={({ value, onChange }) => (
                      <>
                        <BaseInput
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            trigger(PaymentFormKey.zipcode);
                          }}
                          onBlur={() => {
                            trigger(PaymentFormKey.zipcode);
                          }}
                          placeholder="Zipcode"
                        />
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              {selectedState && selectedCountry && !!shippingFee && watch(PaymentFormKey.currency) && (
                <p className="shipping-fee">
                  <b>Shipping: {Currency[watch(PaymentFormKey.currency)]?.symbol + shippingFee.toFixed(2)}</b>
                  <br />
                  to {selectedState?.name} {selectedCountry?.name}
                </p>
              )}
            </Row>
          </div>

          <div className="btn-toggle-billing">
            <ToggleBillingCheckbox
              checked={watch(PaymentFormKey.activeBilling)}
              onChange={() => {
                setValue(PaymentFormKey.activeBilling, !getValues(PaymentFormKey.activeBilling));
              }}
            >
              <span className="text" />
            </ToggleBillingCheckbox>
          </div>
          <Controller
            name={PaymentFormKey.activeBilling}
            control={control}
            render={() => {
              return <input hidden name={PaymentFormKey.activeBilling} />;
            }}
          />
          <Collapse activeKey={watch(PaymentFormKey.activeBilling) ? '1' : '0'} bordered={false} ghost>
            <Collapse.Panel key="1" header="">
              <p className="billing-title">
                Billing Address <span className="asterisk">*</span>
              </p>

              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item
                    validateStatus={formState.errors.billingFirstName ? 'error' : ''}
                    help={formState.errors.billingFirstName?.message}
                  >
                    <Controller
                      name={PaymentFormKey.billingFirstName}
                      control={control}
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <BaseInput
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          onBlur={() => {
                            trigger(PaymentFormKey.billingFirstName);
                          }}
                          placeholder="First Name"
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    validateStatus={formState.errors.billingLastName ? 'error' : ''}
                    help={formState.errors.billingLastName?.message}
                  >
                    <Controller
                      name={PaymentFormKey.billingLastName}
                      control={control}
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <BaseInput
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                          onBlur={() => {
                            trigger(PaymentFormKey.billingLastName);
                          }}
                          placeholder="Last Name"
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item validateStatus={formState.errors.billingAddress ? 'error' : ''} help={formState.errors.billingAddress?.message}>
                <Controller
                  name={PaymentFormKey.billingAddress}
                  control={control}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <BaseInput
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={() => {
                        trigger(PaymentFormKey.billingAddress);
                      }}
                      placeholder="Address"
                    />
                  )}
                />
              </Form.Item>
              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item
                    validateStatus={formState.errors.billingCountry ? 'error' : ''}
                    help={formState.errors.billingCountry?.message}
                  >
                    <Controller
                      name={PaymentFormKey.billingCountry}
                      control={control}
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <BaseSelectOption
                          data={countries}
                          placeholder="Country"
                          onChange={(v) => {
                            onChange(v);
                            if (formState.errors.billingZipcode) {
                              trigger(PaymentFormKey.billingZipcode);
                            }
                          }}
                          dataSelect={value}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item validateStatus={formState.errors.billingCity ? 'error' : ''} help={formState.errors.billingCity?.message}>
                    <Controller
                      name={PaymentFormKey.billingCity}
                      control={control}
                      defaultValue=""
                      render={({ value, onChange }) => {
                        const objectState = states.find((item) => item.id === watch(PaymentFormKey.billingState));
                        const isDisableCityInput = objectState?.postal_code === 'DC';
                        return (
                          <BaseInput
                            value={isDisableCityInput ? '' : value}
                            onChange={(e) => onChange(e.target.value)}
                            onBlur={() => {
                              trigger(PaymentFormKey.billingCity);
                            }}
                            placeholder="City"
                            disabled={isDisableCityInput}
                          />
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item validateStatus={formState.errors.billingState ? 'error' : ''} help={formState.errors.billingState?.message}>
                    <Controller
                      name={PaymentFormKey.billingState}
                      control={control}
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <BaseSelectOption
                          data={stateBilling}
                          placeholder="State"
                          onChange={(e) => {
                            onChange(e);
                            if (formState.errors.billingCity) {
                              trigger(PaymentFormKey.billingCity);
                            }
                          }}
                          dataSelect={value}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    validateStatus={formState.errors.billingZipcode ? 'error' : ''}
                    help={formState.errors.billingZipcode?.message}
                  >
                    <Controller
                      name={PaymentFormKey.billingZipcode}
                      control={control}
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <BaseInput
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            trigger(PaymentFormKey.billingZipcode);
                          }}
                          onBlur={() => {
                            trigger(PaymentFormKey.billingZipcode);
                          }}
                          placeholder="Zipcode"
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>

          <Row className="btn-save-payment">
            <Form.Item>
              <Controller
                name={PaymentFormKey.isAgree}
                control={control}
                defaultValue={false}
                render={({ value, onChange }) => (
                  <PaymentCheckbox
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                  >
                    <p>
                      Check here to agree to{' '}
                      <a href="https://themoderngroom.com/terms-and-conditions/" target="_blank" rel="noreferrer">
                        Purchase Terms and Conditions
                      </a>
                    </p>
                  </PaymentCheckbox>
                )}
              />
            </Form.Item>
          </Row>

          <Row className="action" gutter={20} justify="center">
            <Col>
              <StyleButtonGrey width={135} type="button" onClick={() => onClose()}>
                Cancel
              </StyleButtonGrey>
            </Col>
            <Col>
              <StyleButtonOrange width={135} ref={btnConfirm} onClick={onSubmit} disabled={!watch(PaymentFormKey.isAgree)}>
                Confirm
              </StyleButtonOrange>
            </Col>
          </Row>
        </Form>
      </StylePayment>
      <SuitPaymentConfirm
        visible={isShowPopupConfirmed && visible}
        onClose={() => {
          setShowPopupConfirmed(false);
          onSuccess();
        }}
      />
    </>
  );
};

export default Payment;
