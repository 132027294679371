import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors, fontFamily } from '../styles/definedStyle';

interface StyleLayoutMainProps {
  backgroundImg: string;
}
const StyleLayoutMain = styled.div<StyleLayoutMainProps>`
  background: url(${(props) => props.backgroundImg});
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  .desktop-contact-us {
    background-color: ${colors.orange};
    border: none;
    color: #fff;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    font-weight: 700;
    padding: 7px 23px;
    height: 36px;
    position: fixed;
    z-index: 1001;
    bottom: 10px;
    right: 10px;
    ${tw`font-Poppins-Semi-Bold`} svg {
      position: relative;
      width: 23px;
      color: #fff;
      height: auto;
    }
    p {
      margin: 0;
    }
  }
  #warning-box {
    height: 50px;
    width: 50px;
    position: fixed;
    z-index: 1001;
    top: 320px;
    right: 10px;
    color: red;
    svg {
      position: relative;
      top: 3px;
      width: 30px;
      color: orange;
      height: auto;
    }
  }
  .mobile-contact-us {
    right: 10px;
    height: 50px;
    width: 50px;
    position: fixed;
    z-index: 1001;
    background-color: #304251;
    border-color: #304251;
    &:hover,
    &:active,
    &:focus {
      background-color: #304251;
      border-color: #304251;
    }
    svg {
      position: relative;
      top: 3px;
      width: 30px;
      color: #fff;
      height: auto;
    }
  }
  .warning {
    top: 320px;
  }
  .mobile-contact-us {
    display: none;
    top: 260px;
  }
  @media only screen and (max-width: 1200px) {
    .mobile-contact-us {
      display: block;
    }
    .desktop-contact-us {
      display: none;
    }
  }
`;

const StyleHeader = styled.div`
  p {
    margin: 0;
  }
  ${tw`shadow`}
  .steps {
    display: flex;
    flex-direction: row;
  }
  .hr-tag {
    display: none;
    width: calc(100% - 4px);
  }
  z-index: 999;
  width: 100%;
  min-height: 105px;
  background: ${colors.blueDark};
  display: grid;
  grid-template-columns: 33% 33% auto min-content;
  align-items: center;
  padding: 0px 35px;
  position: sticky;
  grid-template-areas: 'logo children wedding-party btn-logout';
  top: 0px;
  .hr-tag {
    display: none;
  }
  .logo {
    grid-area: logo;
    width: fit-content;
    img {
      height: 45px;
    }
  }
  .children {
    grid-area: children;
    display: grid;
    width: fit-content;
    justify-self: center;
    &.force-show {
      display: grid;
    }
  }
  .user {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .wedding-party {
    grid-area: wedding-party;
    width: 100%;
    justify-content: flex-end;
    display: grid;
    grid-template-columns: auto 150px;
    grid-gap: 15px;
    align-items: center;
    padding-right: 35px;
    p {
      color: white;
    }
    .main-site {
      font-family: ${fontFamily.PoppinsSemiBold};
      font-size: 16px;
      color: ${colors.white};
      display: grid;
      grid-template-columns: min-content auto;
      align-items: center;
    }
  }
  .btn-logout {
    grid-area: btn-logout;
  }
  &.register {
    .wedding-party {
      display: none;
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 0px;
    grid-template-columns: 1fr 1fr 1fr;
    &.register {
      overflow: hidden;
      min-height: 161px;
      .children {
        display: grid;
        grid-column: 1/4;
        grid-row: 3;
      }
      .hr-tag {
        display: block;
        grid-row: 2;
        grid-column: 1/4;
      }
      .logo {
        grid-column: 2;
        grid-row: 1;
        justify-self: center;
      }
    }
    .children {
      display: none;
      padding: 16px;
    }
    .wedding-party {
      justify-self: end;
      grid-column: 3;
      grid-row: 1;
    }
    .logo {
      padding: 13px 35px;
      justify-self: start;
      grid-column: 1;
      grid-row: 1;
    }
    .btn-logout {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    &.register > .logo,
    .logo {
      grid-column: 1/3;
      justify-self: center;
    }
  }
  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    min-height: unset;
    .logo {
      grid-row: 1;
      grid-column: 1/2;
    }
    .wedding-party {
      justify-content: center;
      grid-row: 3;
      grid-column: 1/2;
      padding: 16px;
    }
    .hr-tag {
      grid-row: 2;
      display: block;
    }
  }
`;

const StylePageNotFound = styled.div`
  ${tw`flex flex-auto justify-center items-center h-full bg-greyBlack`}
  .ant-empty-description {
    ${tw`text-white font-Poppins-Semi-Bold`}
    font-size: 16px;
  }
`;

export { StyleHeader, StyleLayoutMain, StylePageNotFound };
