import styled from '@emotion/styled';
import { ISuit } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/models';
import React from 'react';
import Head from '../../../assets/img/suits/head.png';
import { TieType } from '../../../constants';

interface SuitBuilderPropType {
  suit: ISuit;
}
const SuitBuilderStyle = styled.div`
  width: 100%;
  height: 100%;
  .full-suit-comp {
    width: 100%;
    height: 100%;
    position: relative;

    &__base-image {
      position: relative !important;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &.shoes {
        top: -2px;
      }
    }

    svg {
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .tie-component {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  img,
  svg {
    width: auto;
    height: 100%;
  }
`;
const SuitBuilder: React.FC<SuitBuilderPropType> = React.memo((props) => {
  const { suit } = props;
  const compareSuitObject = (prevSuit, nextSuit) => {
    return prevSuit.id === nextSuit.id;
  };
  const renderImg = (props) => {
    return <img {...props} alt={props.alt} />;
  };
  const Image = React.memo(renderImg, compareSuitObject);
  return (
    <SuitBuilderStyle>
      <div className="full-suit-comp">
        {suit?.shirt_color_obj?.part && <Image className="full-suit-comp__base-image" src={suit?.shirt_color_obj?.part} alt="shirt" />}
        {suit?.tie_type_obj?.name && !(suit.tie_type_obj.name === TieType.OPT_OUT_OF_TIE) && (
          <div
            className="tie-component"
            style={{
              backgroundColor: suit?.tie_color_obj?.hex,
              mask: `url(${suit?.tie_type_obj?.part}) no-repeat center / contain`,
              WebkitMask: `url(${suit?.tie_type_obj?.part}) no-repeat center / contain`,
            }}
          />
        )}
        {suit.suit_color_obj?.part && <Image src={suit.suit_color_obj.part} alt="suit" className="suit-img" />}
        {Head && <Image src={Head} alt="head" className="head" />}
        {suit.more_item_obj?.part && <Image src={suit.more_item_obj.part} alt="shoes" className="shoes" />}
      </div>
    </SuitBuilderStyle>
  );
});

export default SuitBuilder;
