import React from 'react';

interface AppContextModel {
  isLoading: boolean;
  setLoading(value: boolean): void;
  setCallStackLoading(value: boolean): void;
  backGroundImg: string;
  setBackground(value: string): void;
}

export const AppContextDefault: AppContextModel = {
  isLoading: false,
  backGroundImg: '',
  setLoading() {
    // init loading
  },
  setCallStackLoading() {
    // init loading
  },
  setBackground() {
    // init Loading
  },
};

export const AppContext = React.createContext<AppContextModel>(AppContextDefault);
