import { api } from '@goldfishcode/modern-groom-api-sdk';
import { updateSuitParams } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit';
import { IPhysicals } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import { message } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setSuitDetail } from 'reducers/suit/action';
import { uploadMultiImage } from '../../../../controllers/file';
import { setActiveParty } from '../../../../reducers/user/action';
import { AppContext } from '../../../contexts/AppContext';
import MeasuringForm, { DataSubmit } from '../../../wedding-party/register-groomsman/step3/MeasuringForm';

interface UserInfoStep2Props {
  onChangeSuccess?(): void;

  disableChange?: boolean;
  isSkipped?: boolean;
}

const UpdateUserInfoDetail: FC<UserInfoStep2Props> = ({ onChangeSuccess, disableChange, isSkipped = false }) => {
  const appContext = useContext(AppContext);
  const [physicalSizes, setPhysicalSizes] = useState<IPhysicals | null>(null);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const dispatch = useDispatch();

  const submitForm = async (dataSubmit: DataSubmit) => {
    const { data, id } = dataSubmit;
    appContext.setLoading(true);
    try {
      const faceImageIsFileType = data.facing_image && typeof data.facing_image === 'object';
      const userSuitImageIsFileType = data.user_suit_image && typeof data.user_suit_image === 'object';
      const files: File[] = [
        ...(faceImageIsFileType ? [data.facing_image] : []),
        ...(userSuitImageIsFileType ? [data.user_suit_image] : []),
      ];
      if (files.length > 0) {
        const res = await uploadMultiImage(files);
        if (res && res.length > 0) {
          if (faceImageIsFileType) {
            data.facing_image = res[0].url;
            if (userSuitImageIsFileType) {
              data.facing_image = res[1].url;
            }
          }
          if (userSuitImageIsFileType) {
            data.user_suit_image = res[0].url;
          }
        }
      }
      const result = await api.Suit.updateUserSuit(id, data as updateSuitParams);
      if (result) {
        dispatch(setSuitDetail(result));
      }

      if (isSkipped) {
        await api.User.updateProfile({ profile_completed: true });
        if (activeParty) {
          const newPartyInformation = { ...activeParty, profile_completed: true };
          dispatch(setActiveParty(newPartyInformation));
        }
      }
      appContext.setLoading(false);
      if (typeof onChangeSuccess === 'function') {
        onChangeSuccess();
      }
    } catch (error) {
      message.error((error as Error).message);
      appContext.setLoading(false);
    }
  };

  const getPhysicalSizes = async () => {
    appContext.setLoading(true);
    try {
      const response = await api.User.getPhysicalSizes();
      setPhysicalSizes(response);
    } catch (error) {
      message.error((error as Error).message);
    }
    appContext.setLoading(false);
  };

  useEffect(() => {
    getPhysicalSizes();
  }, []);

  return (
    physicalSizes && (
      <MeasuringForm
        age={physicalSizes.AGE}
        chest={physicalSizes.CHEST_SIZE}
        shoeSize={physicalSizes.SHOE_SIZE}
        jeanWaistSize={physicalSizes.JEAN_WAIST_SIZE}
        neckSize={physicalSizes.NECK_SIZE}
        sleeve={physicalSizes.SLEEVE_SIZE}
        tShirtSize={physicalSizes.T_SHIRT_SIZE}
        waist={physicalSizes.WAIST}
        height={physicalSizes.HEIGHT}
        inches={physicalSizes.INCHES}
        suitSize={physicalSizes.SUIT_SIZE}
        dressShirtSize={physicalSizes.DRESS_SHIRT_SIZE}
        seatSize={physicalSizes && physicalSizes.SEAT}
        jacketWaistSize={physicalSizes && physicalSizes.JACKET_WAIST}
        onSubmitForm={submitForm}
        disableChange={disableChange}
        actionName="Save Changes"
        isRegisterStep={false}
        isSkipped={isSkipped}
      />
    )
  );
};

export default UpdateUserInfoDetail;
