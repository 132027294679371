import { navigate, RouteComponentProps, Router } from '@reach/router';
import { Steps } from 'antd';
import React, { FC, useState } from 'react';
import Header from '../../Header';
import DotStep from '../../ui/DotStep';
import RegisterGroomsmanStep2 from './step2/RegisterGroomsmanStep2';
import RegisterGroomsmanStep3 from './step3/RegisterGroomsmanStep3';
import { StyleWeddingPartyRegister } from './StyleRegisterGroomsman';
import RegisterGroomsmanStep1 from './step1/RegisterGroomsmanStep1';

const RegisterGroomsman: FC<RouteComponentProps> = () => {
  const [activeStep, setActiveStep] = useState(0);

  const onContinue = (step) => {
    navigate(`/wedding-party/register/step${step}`);
  };

  const onBack = () => {
    navigate('/wedding-party/register/step1');
  };

  return (
    <StyleWeddingPartyRegister>
      <Header className="register">
        <div className="header-step">
          <span className="text">Your Progress</span>
          <Steps className="steps">
            <Steps.Step title="&ensp;" icon={<DotStep active={activeStep === 1} />} />
            <Steps.Step title="&ensp;" icon={<DotStep active={activeStep === 2} />} />
            <Steps.Step title="&ensp;" icon={<DotStep active={activeStep === 3} />} />
          </Steps>
        </div>
      </Header>
      <div className="container">
        <Router basepath="/wedding-party/register">
          <RegisterGroomsmanStep1 activeStep={setActiveStep} path="/step1" onContinue={(step) => onContinue(step)} />
          <RegisterGroomsmanStep2 activeStep={setActiveStep} path="/step2" onContinue={(step) => onContinue(step)} />
          <RegisterGroomsmanStep3 activeStep={setActiveStep} path="/step3" onBack={onBack} />
        </Router>
      </div>
    </StyleWeddingPartyRegister>
  );
};

export default RegisterGroomsman;
