import { navigate } from '@reach/router';
import { Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { getParamsUrl } from '../../util';
import ConfirmEmailStep2 from './ConfirmEmailStep2';
import NewPasswordStep3 from './NewPasswordStep3';
import ResetPasswordContent from './ResetPasswordContent';
import ResetPasswordTitle from './ResetPasswordTitle';
import { StyleModalReset, StyleResetPassword } from './StyleResetPassword';
import SuccessPasswordStep4 from './SuccessPasswordStep4';

interface QueryParams {
  otp: string;
  email: string;
  resetPassword: string;
}
const ResetPassword = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const query = useMemo(() => getParamsUrl<QueryParams>(), []);

  useEffect(() => {
    if (query && query.otp && query.email && query.resetPassword === '1') {
      setIsModalVisible(true);
      setStep(3);
      setEmail(query.email);
      setOtp(query.otp);
    }
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    if (step === 3 || step === 4) {
      // clear all query params
      navigate(window.location.origin + window.location.pathname);
    }
    setStep(1);
  };
  const getData = (data: string) => {
    setEmail(data);
    setStep((prve) => prve + 1);
  };
  const getDataConfirm = () => {
    setStep((prve) => prve + 1);
  };
  return (
    <StyleResetPassword>
      <Button className="forgot" onClick={showModal}>
        Forgot Password?
      </Button>
      <StyleModalReset
        centered
        closable
        onCancel={handleCancel}
        visible={isModalVisible}
        title={step === 1 ? <ResetPasswordTitle /> : ''}
        footer={null}
      >
        {step === 1 && <ResetPasswordContent onGetData={getData} handleCancel={handleCancel} />}
        {step === 2 && <ConfirmEmailStep2 emailUser={email} handleCancel={handleCancel} />}
        {step === 3 && <NewPasswordStep3 onGetNewData={getDataConfirm} otp={otp} email={email} />}
        {step === 4 && <SuccessPasswordStep4 onFinalReset={handleCancel} />}
      </StyleModalReset>
    </StyleResetPassword>
  );
};

export default ResetPassword;
