import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../../../styles/definedStyle';

const StyleUserInfoStep1 = styled.div`
  .info {
    display: grid;
    .info-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 15px 62px;
      grid-gap: 15px 23px;
      .ant-form-item-control {
        display: grid;
      }
      &.full-name-party {
        grid-template-columns: 100%;
        .name {
          grid-column: 1;
          grid-row: 2;
        }
      }
      &.phone-number {
        .phone {
          grid-column: 1/3;
          grid-row: 2;
        }
      }
      .ant-form-item {
        margin: 0;
      }
      .first-name {
        grid-column: 1;
        grid-row: 2;
      }
      .last-name {
        grid-column: 2;
        grid-row: 2;
      }
      .row-title {
        margin: 0;
        font-family: 'Poppins Semi Bold';
        color: rgb(48, 66, 81);
      }
    }
    .calendar-content {
      h3 {
        ${tw`text-center font-Poppins-Semi-Bold font-bold text-blueDark`}
        font-size: 14px;
      }
      .ant-picker-calendar {
        ${tw`m-auto`}
        margin-top: 28px;
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .info {
      .info-row {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        .first-name {
          grid-column: 1;
          grid-row: 2;
        }
        .last-name {
          grid-column: 1;
          grid-row: 3;
        }
      }
    }
  }

  @media only screen and (max-width: 481px) {
    &.profile {
      .info {
        padding: 0;
      }
    }
    .info {
      padding: 35px;
    }
  }

  .action {
    ${tw`text-center`}
  }
  .error {
    color: ${colors.error};
  }
  .enter {
    label {
      ${tw`opacity-0`}
    }
  }
`;

export { StyleUserInfoStep1 };
