import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../../styles/definedStyle';

const StyledActivity = styled.div`
  .header {
    ${tw`font-Poppins-Semi-Bold text-blueDark block`}
    font-size: 14px;
    margin-top: 25px;
  }
  .activity-suit {
    height: 546px;
    border: 1px solid ${colors.greyback};
    .wrapper {
      padding-left: 16px;
      padding-bottom: 17px;
      padding-top: 20px;
      ${tw`border-0 border-b border-solid border-greyBlack border-opacity-50`}
      .contain {
        ${tw`flex flex-col border-b`}
        .name {
          ${tw`font-Poppins-Regular`}
          line-height: 21px;
        }
        .title {
          ${tw`text-darkLight`}
          line-height: 18px;
        }
        .time {
          ${tw`font-Source-Sans-Pro-Regular text-darkLight text-opacity-50`}
          line-height: 18px;
        }
      }
    }
    .spin {
      ${tw`py-2 text-center`}
    }
  }
`;

export { StyledActivity };
