import queryString from 'query-string';

export const getParamsUrl = <T>(): T | null => {
  if (typeof window !== 'undefined') {
    const { search } = window.location;
    const parse = queryString.parse(search);
    return (parse as unknown) as T;
  }
  return null;
};

export function formatPhone(phoneNumber): string | null {
  // Remove all non-digit characters from the phone number
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned number is a valid length for US or Canada
  if (cleaned.length > 11) {
    return null;
  }

  // Extract the country code if present
  const countryCode = cleaned.length === 11 ? cleaned.charAt(0) : '';

  // Extract the area code
  const areaCode = cleaned.substr(countryCode.length, 3);

  // Extract the next three digits
  const nextThree = cleaned.substr(countryCode.length + 3, 3);

  // Extract the last four digits
  const lastFour = cleaned.substr(countryCode.length + 6, 4);
  if (!areaCode) {
    return '';
  }

  // Format the phone number based on the presence of a country code
  if (countryCode) {
    return `+${countryCode} ${areaCode ? `(${areaCode}` : ''}${nextThree ? `) ${nextThree}` : ''}${lastFour ? `-${lastFour}` : ''}`;
  }
  return `${areaCode ? `(${areaCode}` : ''}${nextThree ? `) ${nextThree}` : ''}${lastFour ? `-${lastFour}` : ''}`;
}
