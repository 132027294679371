import styled from '@emotion/styled';
import tw from 'twin.macro';
import { colors } from '../../../../styles/definedStyle';

const StyleGroomsmanInformation = styled.div`
  .info {
    margin-top: 40px;
    .info-row {
      .row-title {
        margin: 0;
        font-family: 'Poppins Semi Bold';
        color: rgb(48, 66, 81);
        padding-bottom: 8px;
      }
      grid-gap: 0 20px;
      display: grid;
      &.your-name {
        grid-template-columns: 1fr 1fr;
        .first-name,
        .last-name {
          grid-row: 2;
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .info {
      .info-row {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        grid-gap: 0;
        &.your-name {
          grid-template-columns: 1fr;
          .first-name {
            grid-row: 2;
          }
          .last-name {
            grid-row: 3;
          }
        }
      }
    }
  }

  .action {
    ${tw`text-center`}
  }
  .error {
    color: ${colors.error};
  }
  .enter {
    label {
      ${tw`opacity-0`}
    }
  }
`;

export { StyleGroomsmanInformation };
