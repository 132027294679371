import styled from '@emotion/styled';
import { IWeddingPartyMember } from '@goldfishcode/modern-groom-api-sdk/libs/api/user/models';
import tw from 'twin.macro';

interface Props {
  activeParty?: IWeddingPartyMember;
}
const StyleYourProfileGroom = styled.div<Props>`
  .user-step-1 {
    margin-top: 30px;
    padding-bottom: 40px;
    ${(props) => (!props.activeParty?.opt_out_measurement ? tw`border-0 border-greyLight border-b border-solid border-opacity-25` : '')};
  }
  .user-step-3 {
    margin-top: 40px;
  }
`;

export { StyleYourProfileGroom };
