import styled from '@emotion/styled';
import { Modal } from 'antd';
import tw from 'twin.macro';

const StyleSuitPaymentConfirm = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    .title {
      ${tw`font-Poppins-Semi-Bold text-center text-blueDark mb-0`}
      font-size: 24px;
      margin-top: 30px;
    }
    .description {
      ${tw`flex flex-col text-center`}
      margin-top: 30px;
      .arrive {
        ${tw`font-Poppins-Semi-Bold`}
        line-height: 21px;
      }
      .text {
        ${tw`font-Poppins-Regular`}
      }
    }
    .action {
      margin-top: 30px;
      ${tw`text-center`}
      margin-bottom: 22px;
    }
  }
`;

export { StyleSuitPaymentConfirm };
