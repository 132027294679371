import styled from '@emotion/styled';
import { Modal } from 'antd';
import tw from 'twin.macro';

const StyleUploadPhotoSuccessful = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-body {
      ${tw`text-center`}
      padding-top: 57px;
      padding-bottom: 46px;
      h3 {
        ${tw`font-Poppins-Semi-Bold mb-0 text-blueDark text-center`}
        font-size: 24px;
      }
      h4 {
        ${tw`mb-0 font-Poppins-Regular`}
        line-height: 21px;
        &:first-of-type {
          ${tw`font-Poppins-Semi-Bold`}
          margin-top: 30px;
        }
      }
      .action {
        margin-top: 30px;
      }
    }
  }
`;
export { StyleUploadPhotoSuccessful };
