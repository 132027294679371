import { api } from '@goldfishcode/modern-groom-api-sdk';
import { ISuit } from '@goldfishcode/modern-groom-api-sdk/libs/api/suit/models';
import { Col, message, Row } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useRef, useState } from 'react';
import { uploadMultiImage } from '../../../controllers/file';
import { StatusSuit } from '../../../enum';
import { AppContext } from '../../contexts/AppContext';
import { StyleButtonOrange } from '../../ui/Styled';
import UploadPhotoSuccessfulModal from './modal/UploadImageSuccessful/UploadPhoto';
import { SuitInformationStyles } from './StyleSuitList';
import SuitBuilder from './SuitBuilder';

interface SuitInformationProps {
  payment(suitId): void;
  suitDetail?: ISuit;
  viewYourSuit(yourSuit): void;
  updateSuitDetail(fieldName, value): void;
  disableAction?: boolean;
}

const SuitInformation: FC<SuitInformationProps> = (props) => {
  const { suitDetail, updateSuitDetail, disableAction } = props;
  const [isUploadPhotoSuccess, setUploadPhoto] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const appContext = useContext(AppContext);

  const onSelectFile = async (files: FileList | null) => {
    if (suitDetail && files !== null) {
      if (files.length < 1) return;
      try {
        appContext.setLoading(true);
        const filesList: File[] = [];
        for (let index = 0; index < files.length; index++) {
          filesList.push(files[index]);
        }
        const responseFileUpload = await uploadMultiImage(filesList);
        const links = responseFileUpload.map((f) => f.url || f.file_path);

        const res = await api.Suit.createUserSuitSwearing({
          image_urls: links,
          suit: suitDetail.id,
        });
        if (res) {
          updateSuitDetail('total_images_uploaded', res.total_images_uploaded);
        }

        setUploadPhoto(true);
      } catch (error) {
        message.error((error as Error).message);
      } finally {
        if (fileRef.current && fileRef.current.value) {
          fileRef.current.value = '';
          appContext.setLoading(false);
        }
      }
    }
  };

  const getFormatDay = (day: string, defaultValue = '') => {
    if (moment(day).isValid()) {
      return moment(day).format('MM/DD/YY');
    }
    return defaultValue;
  };
  return (
    (suitDetail && (
      <SuitInformationStyles>
        <div className="title">{suitDetail.suit_user_name}</div>
        <div className="suit-information">
          {suitDetail.suit_color_obj?.name ? (
            <>
              <div className="your-title">
                <div className="title-content">
                  <span>Color: </span>
                  <p>{suitDetail.suit_color_obj?.name}</p>
                </div>
                <div className="title-content">
                  <span>Tie:</span>
                  <p>
                    {suitDetail?.tie_type_obj?.price === 0
                      ? 'Opted out'
                      : `${suitDetail.tie_type_obj?.name} (${suitDetail.tie_color_obj?.name})`}
                  </p>
                </div>
                <div className="title-content">
                  <span>Shirt: </span>
                  <p>{suitDetail.shirt_color_obj?.price === 0 ? 'Opted out' : suitDetail.shirt_color_obj?.name}</p>
                </div>
                <div className="title-content">
                  <span>Shoes: </span>
                  <p>{suitDetail.more_item_obj?.price === 0 ? 'Opted out' : suitDetail.more_item_obj?.name}</p>
                </div>
              </div>
              <div className="you-image">
                <SuitBuilder suit={suitDetail} />
              </div>
              {suitDetail.status === StatusSuit.NEED_TO_PAY || suitDetail.status === StatusSuit.PAYMENT_FAILED ? (
                <>
                  <div className="you-footer">
                    <span className="paid">The suit has not been paid.</span>
                  </div>
                  {/*
                  <div className="you-complete">
                    <StyleButtonOrange disabled={!suitDetail.status} width={179} onClick={() => payment(suitDetail.id)}>
                      Complete Payment
                    </StyleButtonOrange>
                  </div> */}
                </>
              ) : (
                <>
                  <Row gutter={12} align="middle" className="sent-payment" justify="center">
                    <Col span={12} className="payment-sent">
                      <span className="title">Payment sent on</span>
                      <span className="text">{getFormatDay(suitDetail?.payment_sent || '', '-')}</span>
                    </Col>
                    {suitDetail.shipped_date && (
                      <Col span={12} className="suit-shipped">
                        <span className="title">Suit shipped on</span>
                        <span className="text">{getFormatDay(suitDetail?.shipped_date || '', '-')}</span>
                      </Col>
                    )}
                  </Row>
                  {suitDetail.shipped_date && (
                    <>
                      <section className="action">
                        <StyleButtonOrange width={179} onClick={() => fileRef.current && fileRef.current.click()} disabled={disableAction}>
                          Upload Photos
                        </StyleButtonOrange>
                        <span className="total-file-select">
                          {suitDetail?.total_images_uploaded || 0}
                          {suitDetail?.total_images_uploaded > 1 ? ' files' : ' file'} selected
                        </span>
                        <input
                          ref={fileRef}
                          multiple
                          name="fileCheck"
                          accept="image/*"
                          onChange={(e) => onSelectFile(e.target.files)}
                          type="file"
                          hidden
                          disabled={disableAction}
                        />
                      </section>
                      <UploadPhotoSuccessfulModal visible={isUploadPhotoSuccess} onClose={() => setUploadPhoto(false)} />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="suit-empty">Suit has not been selected yet.</div>
          )}
        </div>
      </SuitInformationStyles>
    )) ||
    null
  );
};

export default SuitInformation;
