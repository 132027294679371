import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { AuthState } from './auth/model';
import authReducer from './auth/reducer';
import authSaga from './auth/saga';
import { RegisterGroomState } from './register-groom/model';
import { UserState } from './user/model';
import userReducer from './user/reducer';
import registerGroomReducer from './register-groom/reducer';
import { SuitState } from './suit/model';
import suitReducer from './suit/reducer';
import assetReducer from './asset/reducer';
import { AssetState } from './asset/model';

export interface IListPagination<T> {
  results: T[];
  page: number;
  count: number;
}

export interface IGetList {
  page: number;
  limit: number;
}

export interface ActionBase {
  type: string;
  payload: any;
}

export interface ActionBaseType<T> {
  type: string;
  payload: T;
}

export interface ResponseBase<T> {
  data?: T;
  error?: ErrorBase;
}

export interface ErrorBase {
  message?: string;
  code?: number;
}
// Using at the component when mapStateToProps
export interface RootState {
  authReducer: AuthState;
  userReducer: UserState;
  registerGroomReducer: RegisterGroomState;
  suitReducer: SuitState;
  assetReducer: AssetState;
  // ....
}

const appReducer = combineReducers<RootState, ActionBase>({
  authReducer,
  userReducer,
  registerGroomReducer,
  suitReducer,
  assetReducer,
});
const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    window.location.reload();
  }
  return appReducer(state, action);
};

function* rootSaga() {
  yield all([
    fork(authSaga),
    // ....
  ]);
}

export { rootReducer, rootSaga };
