import { RouteComponentProps } from '@reach/router';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BackgroundImageCreateWeddingStep1 from '../../../../assets/images/wedding-party-create-background.jpg';
import { RootState } from '../../../../reducers';
import { AppContext } from '../../../contexts/AppContext';
import HeaderContainer from '../../../ui/HeaderContainer';
import { StyleWeddingPartyRegisterStep1 } from '../StyleRegisterGroomsman';
import GroomsmanInformation from './GroomsmanInformation';

interface WeddingPartyRegisterStep1Props extends RouteComponentProps {
  onContinue(step: number): void;
  activeStep(step: number): void;
}
const RegisterGroomsmanStep1: React.FC<WeddingPartyRegisterStep1Props> = (props) => {
  const { activeStep } = props;
  const appContext = useContext(AppContext);
  const activeParty = useSelector((state: RootState) => state.userReducer.activeParty);
  const disableChange = !activeParty?.active;

  useEffect(() => {
    appContext.setBackground(BackgroundImageCreateWeddingStep1);
    activeStep(1);
  }, []);
  return (
    <StyleWeddingPartyRegisterStep1>
      <HeaderContainer title="Register Your Info" />
      <GroomsmanInformation disableChange={disableChange} />
    </StyleWeddingPartyRegisterStep1>
  );
};

export default RegisterGroomsmanStep1;
