import { ApiClient } from '@goldfishcode/modern-groom-api-sdk';
import { ApiConfiguration, ISession } from '@goldfishcode/modern-groom-api-sdk/libs/http/config';

class SessionStorage implements ISession {
  // eslint-disable-next-line class-methods-use-this
  async set(key: string, value: string): Promise<void> {
    localStorage.setItem(key, value);
  }

  // eslint-disable-next-line class-methods-use-this
  async get(key: string): Promise<string> {
    const store = localStorage.getItem(key);
    return store || '';
  }

  // eslint-disable-next-line class-methods-use-this
  async remove(key: string): Promise<void> {
    localStorage.removeItem(key);
  }
}

export const setApiConfig = () => {
  const config: ApiConfiguration = {
    baseUrl: process.env.REACT_APP_URL || '',
    socketUrl: process.env.REACT_APP_SOCKET_URL,
    AUTH_SESSION_KEY: 'jwt',
    session: new SessionStorage(),
  };

  try {
    ApiClient.setApiConfig(config);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
