import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Background from '../../assets/images/home-login.jpg';
import { RootState } from '../../reducers';
import { AppContext } from '../contexts/AppContext';
import Header from '../Header';
import NeedHelp from '../need-help/NeedHelp';
import OnRouteChangeWorker from '../OnRouteChangeWorker';
import MenuDashboardGroomsman from './MenuDashboardGroomsman';
import { StyleDashBoard } from './StyleDashboard';

const DashBoardGroomsmanContainer: FC<RouteComponentProps> = ({ children }) => {
  const userProfile = useSelector((state: RootState) => state.userReducer.userProfile);
  const appContext = useContext(AppContext);
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  const userName = useMemo(() => {
    return userProfile?.first_name && userProfile.last_name
      ? `${userProfile.first_name}${` `}${userProfile.last_name}`
      : `${userProfile?.username}`;
  }, [userProfile]);

  useEffect(() => {
    appContext.setBackground(Background);
    if (location.pathname === '/groomsman') {
      navigate('/groomsman/suits');
    }
  }, []);
  return (
    userProfile && (
      <StyleDashBoard>
        <Header>
          <div className="user-info">
            <span className="welcome">Welcome,</span>
            <span>
              <span className="username">{userName}</span>!
            </span>
          </div>
        </Header>
        <div className="layout" ref={ref}>
          <div className="content">{children}</div>
        </div>
        <NeedHelp />
        <div className="menu-dashboard">
          {location.pathname !== '/groomsman' && <MenuDashboardGroomsman className="menu-dashboard-layout" />}
        </div>
        <OnRouteChangeWorker
          action={() => {
            ref.current?.scrollTo({ top: 0 });
          }}
        />
      </StyleDashBoard>
    )
  );
};

export default DashBoardGroomsmanContainer;
