import { ApiClient } from '@goldfishcode/modern-groom-api-sdk';
import { useEffect } from 'react';
import UseConfigAlert from './useConfigAlert';

export const useInterceptor = () => {
  const { showModalForceLogout } = UseConfigAlert();
  const handleResponseReject = async (error: any): Promise<any> => {
    if (error.response.status === 401) {
      await showModalForceLogout('Error', error.response.data.message, 'Logout');
    }
    return Promise.reject(error);
  };

  useEffect(() => {
    const axiosIntance = ApiClient.getInstance();
    const interceptorNumber = axiosIntance.interceptors.response.use((response) => response, handleResponseReject);
    return () => {
      axiosIntance.interceptors.response.eject(interceptorNumber);
    };
  }, []);
};
