import styled from '@emotion/styled';
import tw from 'twin.macro';
import { Modal } from 'antd';
import { colors } from '../../styles/definedStyle';

const StyleTitleResetPassword = styled.div`
  padding-top: 20px;
  span {
    ${tw`flex flex-col text-center`}
  }
  .reset {
    ${tw`font-bold text-blueDark`}
    font-size: 24px;
    margin-bottom: 25px;
  }
  .title {
    ${tw`font-bold text-blueDark`}
    font-size: 14px;
  }
  .go-back {
    ${tw`flex items-center font-Poppins-Semi-Bold`}
    button {
      border: none;
      box-shadow: none;
      :hover {
        color: inherit;
      }
      span {
        ${tw`font-Poppins-Semi-Bold font-bold  text-blueDark`}
        font-size: 16px;
      }
    }
    .ant-btn {
      padding: 0px;
    }
  }
`;
const StyleResetPassword = styled.div`
  .forgot {
    ${tw`bg-transparent hover:bg-transparent m-auto focus:bg-transparent`}
    border: none;
    outline: none;
  }
  span {
    ${tw`font-bold`};
    color: #ca6d4f;
  }
  .ant-modal-confirm-body-wrapper {
    ${tw`flex flex-col`}
    div {
      ${tw`flex justify-center`};
    }
  }
`;

const StyleModalReset = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-footer {
      ${tw`flex justify-center border-t-0`}
      .ant-btn {
        background-color: ${colors.gray};
      }
      .ant-btn-primary {
        background-color: ${colors.orange};
      }
      .ant-btn {
        margin-right: 8px;
      }
    }
    .ant-modal-body {
      .reset {
        font-size: 24px;
        font-family: 'Poppins Semi Bold';
      }
      .title {
        font-size: 14px;
        font-family: 'Poppins Semi Bold';
        margin-bottom: 20p;
      }
    }
    .ant-modal-header {
      ${tw`border-b-0`}
      border-radius: 15px;
    }
  }
`;

const StyleResetPasswordStep = styled.div`
  ${tw`text-center`};
  margin-top: 15px;
  p {
    margin-bottom: 20px;
  }
`;

const StyleResetPasswordStep3 = styled.form`
  ${tw`text-center`};
  margin-top: 15px;
  .new-email {
    margin-top: 30px;
    width: 380px;
  }
  .confirm-email {
    width: 380px;
  }
  @media only screen and (max-width: 576px) {
    .new-email,
    .confirm-email {
      width: 280px;
    }
  }
  .error {
    margin: 0 auto;
    height: 22px;
    width: 380px;
    text-align: start;
    color: ${colors.error};
  }
`;

const StyleResetPasswordStep2 = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  .email-address {
    ${tw`font-bold`};
  }
  .ant-form-item {
    width: 380px;
    justify-self: center;
    @media only screen and (max-width: 576px) {
      width: 280px;
    }
  }
  .error {
    color: ${colors.error};
  }
`;

const StyleFooterModal = styled.div<{ step?: number }>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.step === 4 || props.step === 2 ? '3rem' : '1rem')};
  margin-bottom: 1rem;
  button {
    width: 135px;
    height: 38px;
    border: none;
    background-color: ${colors.orange};
    color: ${colors.white};
    &:hover,
    &:focus,
    &:active {
      background-color: ${colors.orange};
    }
  }
  .cancel {
    margin-right: 10px;
    background-color: ${colors.gray};
  }
  .cancel:hover {
    background-color: ${colors.gray};
  }
  .conrfirm {
    margin-left: 10px;
  }
`;
export {
  StyleTitleResetPassword,
  StyleResetPassword,
  StyleModalReset,
  StyleResetPasswordStep,
  StyleResetPasswordStep3,
  StyleResetPasswordStep2,
  StyleFooterModal,
};
