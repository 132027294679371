import { Button } from 'antd';
import React, { FC, useEffect, useRef } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { StylePlayer } from './Styled';

interface BasePlayerProps {
  videoUrl: string;
  visible: boolean;
  onClose(): void;
}
const BasePlayer: FC<BasePlayerProps> = ({ videoUrl, visible, onClose }) => {
  const player = useRef<HTMLDivElement | null>(null);
  const resizeIframe = () => {
    if (window.innerWidth > window.innerHeight) {
      player.current?.classList.remove('vertical');
      player.current?.classList.add('horizontal');
    } else {
      player.current?.classList.remove('horizontal');
      player.current?.classList.add('vertical');
    }
  };
  useEffect(() => {
    resizeIframe();
    window.addEventListener('resize', resizeIframe, true);
    return () => {
      window.removeEventListener('resize', resizeIframe, true);
    };
  }, [visible]);
  return visible ? (
    <StylePlayer title="Basic Modal" closable={false} centered visible={visible} footer={null} width="1152px">
      <section className="back">
        <Button type="text" onClick={() => onClose()}>
          <FaAngleLeft />
          GO BACK
        </Button>
      </section>
      <div className="player" ref={player}>
        <video src={videoUrl} width="100%" height="400" controls muted autoPlay>
          <track default kind="captions" srcLang="en" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </StylePlayer>
  ) : null;
};

export default BasePlayer;
