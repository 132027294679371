import React from 'react';
import { StyleSavePaymentCheckbox, StyleToggleBillingCheckbox } from './Styled';

export const PaymentCheckbox = (props) => {
  return <StyleSavePaymentCheckbox {...props} />;
};

export const ToggleBillingCheckbox = (props) => {
  return <StyleToggleBillingCheckbox {...props} />;
};
