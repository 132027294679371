import { api } from '@goldfishcode/modern-groom-api-sdk';
import { navigate } from '@reach/router';
import { message } from 'antd';
import { UserRole } from 'enum';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setActiveParty } from 'reducers/user/action';
import { getParamsUrl } from '../../util';
import UseConfigAlert from './useConfigAlert';

const UseHandleEmailAction = () => {
  const userProfile = useSelector((state: RootState) => state.userReducer.userProfile);
  const listParty = useSelector((state: RootState) => state.userReducer.listParty);

  const dispatch = useDispatch();
  const queryParams = getParamsUrl<any>();
  const { showModalForceLogout } = UseConfigAlert();
  const activateParty = async (id: string) => {
    try {
      await api.User.activeParty(id);
    } catch (error) {
      message.error((error as Error).message);
    }
  };

  useEffect(() => {
    if (queryParams?.user_id && userProfile && listParty.length > 0) {
      const inappropriateInformation = queryParams && queryParams.user_id && userProfile.id !== queryParams.user_id;
      const appropricateInformation = queryParams && queryParams.user_id && userProfile.id === queryParams.user_id;
      if (inappropriateInformation) {
        showModalForceLogout('Mismatched Information', "Your information doesn't match what we have on record for this account");
        return;
      }
      if (appropricateInformation) {
        if (!queryParams.party_id || !queryParams.action) {
          showModalForceLogout();
          return;
        }
        const partyIndex = listParty.findIndex((party) => party.wedding_party === queryParams.party_id);
        if (partyIndex >= 0) {
          activateParty(listParty[partyIndex].wedding_party);
          dispatch(setActiveParty(listParty[partyIndex]));
        } else {
          showModalForceLogout('Error', 'The wedding party does not exist, please contact the administrator');
          return;
        }
        switch (queryParams.action) {
          case 'measurement':
            setTimeout(() => {
              navigate('/wedding-party/register/step3', { replace: true });
            }, 200);
            break;
          case 'payment':
            setTimeout(() => {
              navigate(
                `/${
                  listParty[partyIndex].user_role?.key === UserRole.GROOM ? UserRole.GROOM.toLowerCase() : UserRole.GROOMSMAN.toLowerCase()
                }/your-suit?action=${queryParams.action}`,
                { replace: true },
              );
            }, 200);
            break;
          case 'upload-photo':
            setTimeout(() => {
              navigate(
                `/${
                  listParty[partyIndex].user_role?.key === UserRole.GROOM ? UserRole.GROOM.toLowerCase() : UserRole.GROOMSMAN.toLowerCase()
                }/your-suit?action=${queryParams.action}`,
                { replace: true },
              );
            }, 200);
            break;
          default:
            break;
        }
      }
    }
  }, [userProfile, listParty]);
};

export default UseHandleEmailAction;
