import { ActionBase } from '..';
import { initialListPage } from '../../constants';
import { SET_LIST_SUIT, SET_SUIT_DETAIL } from './action';
import { SuitState } from './model';

const initialReducer: SuitState = {
  listSuit: initialListPage(),
  suitDetail: undefined,
};

const suitReducer = (state = initialReducer, action: ActionBase): SuitState => {
  switch (action.type) {
    case SET_LIST_SUIT:
      return {
        ...state,
        listSuit: action.payload,
      };
    case SET_SUIT_DETAIL:
      return {
        ...state,
        suitDetail: action.payload,
      };
    default:
      return state;
  }
};

export default suitReducer;
